import {createAction} from '@reduxjs/toolkit';


const updateJSONRequest = createAction('model/updateJSONRequest')
const updateJSONSuccess = createAction('model/updateJSONSuccess')
const updateJSONError = createAction('model/updateJSONError')


export default{
    updateJSONRequest,
    updateJSONSuccess,
    updateJSONError,
}