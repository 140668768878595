import { Base64 } from "js-base64"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import Button from "../../components/Button/Button"
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'
import userPrivateOperations from "../../redux/userPrivate/user-private-operations"

const CompontenCart = ({
    ID_ITEM,
    NAME,
    IMAGE,
    QTY,
    AMOUNT,
    PRICE,
    DISCOUNT,
    PRODUCT_PRICE,
    DISCOUNT_PERCENT,
    rate,
    onGetUpdateProductQtyInCart,
    onGetRemoveProductFromCart,
    lang,
    orderId:ID_ORDER,
    VENDOR_IS_MY,
    VENDOR_NAME,
    LOYALTY_MAX_PERCENT,
    onApplyDiscountProduct,
    applyDiscountProduct,
    LOYALTY_DISCOUNT,
    errCode,
    loylatyBalance,
    loyaltyDiscount:getloyaltyDiscount
}) => {
    const [price, setPrice] = useState(0)
    const [amount, setAmount] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [count, setCount] = useState(1)
    const [count2, setCount2] = useState(1)

    const [loyaltyDiscount, setLoyaltyDiscount] = useState(0)

    useEffect(()=>{
        setCount(QTY)
    }, [QTY])

    useEffect(()=>{
        setPrice(PRODUCT_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE)
    }, [PRODUCT_PRICE, rate])

    useEffect(()=>{
        setAmount(AMOUNT.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE)
    }, [AMOUNT, rate])

    useEffect(()=>{
        setDiscount(DISCOUNT.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE)
    }, [DISCOUNT, rate])

    useEffect(()=>{
        setLoyaltyDiscount(LOYALTY_DISCOUNT.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).VALUE)
        // setCount2(Number(LOYALTY_DISCOUNT.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({VALUE})=>(VALUE.toFixed(2)))))
    }, [LOYALTY_DISCOUNT])

    // useEffect(()=>{
    //     console.log({errCode, ID_ORDER});
    // }, [errCode])

    const decrease = () =>{
        if(count > 1){ 
            // setCount(count - 1)
            onGetUpdateProductQtyInCart({
                DOMAIN,
                DEFAULT_COLUMN:lang,
                ID_ITEM,
                QTY:count - 1,
                ID_ORDER
            })
        }
    }

    const increase = () =>{
        // setCount(count + 1)
        onGetUpdateProductQtyInCart({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            ID_ITEM,
            QTY:count + 1,
            ID_ORDER
        })
    }

    const decrease2 = () =>{
        if(count2 > 1){ 
            setCount2(Number((count2 - 1).toFixed(2)))

        }
    }

    const increase2 = () =>{
        setCount2(Number((count2 + 1).toFixed(2)))

    }

    const remove = () => {
        onGetRemoveProductFromCart({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            ID_ITEM,
            ID_ORDER
        })
    }

    const apply = () => {
        onApplyDiscountProduct({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            ID_ORDER,				
            ID_ITEM,
            LOYALTY_DISCOUNT: Number((loylatyBalance-getloyaltyDiscount.point).toFixed(3))
        })
    }

    const applyDelete = () => {
        onApplyDiscountProduct({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            ID_ORDER,			
            ID_ITEM,
            LOYALTY_DISCOUNT:0
        })
    }


    return (
        <tr>
            <td>
                <span className="pr-4 pointer" style={{color: '#989898', fontSize: 25, lineHeight: 0, position: 'relative',top: 5}} onClick={()=>remove()}>&times;</span>
                <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/200`} alt="" className="img-class"  />
            </td>
            <td>
                {Base64.decode(NAME)}
               {VENDOR_IS_MY === 0 && <> <br/> <span style={{color:'green'}}>Vendor: {VENDOR_NAME}</span></>}
            </td>
            <td>{rate} {Number(price.toFixed(2))}</td>
            <td>{rate} {Number(discount.toFixed(2))}</td>
            <td >
                <div className="d-flex cart-plus-div " style={{justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <i className="fa fa-minus " aria-hidden="true" onClick={() => decrease()}></i>
                    <div className="px-3 py-2 text-dark font-weight-bolder">{count}</div>
                    <i className="fa fa-plus  " aria-hidden="true" onClick={() => increase()}></i>
                </div>
            </td>
            <td>
                <div style={{display:'flex', alignItems:'center'}}>
                    <div>
                        <div style={{width:90}}>{rate} { Number(loyaltyDiscount.toFixed(3))}</div>
                        <small> max {LOYALTY_MAX_PERCENT}% </small><br/>
                        {errCode === 300 &&  applyDiscountProduct.credential.ID_ITEM === ID_ITEM && <small style={{color:'red'}}>cannot apply discount</small>}
                    </div>
                    {Number(loyaltyDiscount.toFixed(3)) <= 0 && <Button onClick={()=>apply()} style={{marginLeft: 12, width: 80, padding: '4px 8px', height: 'auto'}} >Apply </Button>}
                    {Number(loyaltyDiscount.toFixed(3)) > 0 && <Button onClick={()=>applyDelete()} style={{marginLeft: 12, width: 80, padding: '4px 8px', height: 'auto', backgroundColor: '#dc3545', borderColor:'#dc3545'}} >Remove </Button>}
                </div>
                
            </td>
            <td className="text-right">{rate} {Number(amount.toFixed(2))}</td>
        </tr>
    ) 
}
const mapStateToProps = (state) => ({
    rate:state.auth.rate,
    lang:state.lang.LANG,
    errCode:state.userPrivate.err_code,
    orderId:state.userPrivate.orderId,
    applyDiscountProduct:state.userPrivate.applyDiscountProduct,
})

const mapDispatchToProps = {
    onGetUpdateProductQtyInCart:userPrivateOperations.getUpdateProductQtyInCart,
    onGetRemoveProductFromCart:userPrivateOperations.getRemoveProductFromCart,
    onApplyDiscountProduct:userPrivateOperations.applyDiscountProduct,
}
export default connect(mapStateToProps, mapDispatchToProps)(CompontenCart);