import {useEffect, useRef} from "react";
import {useState} from "react";
import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Button from "../../components/Button/Button";
const INITIAL_Contact = {
    FIRST_NAME: "",
    LAST_NAME:"",
    EMAIL:""
}
const Contact = () => {
    const [, forceUpdate] = useState()

    const saveContact = () => {
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }
    }
    const [contact, setContact] = useState(INITIAL_Contact)
    const handleContact = ({target: {name, value}}) => {

        setContact(state => ({...state, [name]: value}))
    }
    const simpleValidator = useRef(new SimpleReactValidator())
    return (
        <div class="container">
            <div class="text-center py-5">
                <h1>Contact Us</h1>
            </div>
            <div>
                <div class="row">
                    <div class="col-md-8">
                        <div>
                            <h4 class="border-bottom pb-4">Leave us a Message</h4>
                            <p class="pt-4">Get in touch with your questions, suggestions, request and get feedback from our
                                support team as
                                soon as possible.</p>
                            <p class="py-2">Get in touch with your questions, suggestions, request and get feedback from our
                                support team as
                                soon as possible. We are here because of you, at Pain Weed Dispensary our clients
                                suggestions
                                are very important to improve our service. We are waiting to hear from you anytime</p>
                            <p class="py-2">For Bulk orders, Leave us a messaage</p>
                        </div>
                        <div class="contact-us-form">
                            <h4>Contact Form</h4>
                            <div class="d-flex display-block py-3">
                                <div class="pr-3 w-50">
                                    <span>Name *</span>

                                    <input
                                        type="text"
                                        onChange={handleContact}
                                        className="w-100 form-control px-4 checkout-input-small"
                                        name="FIRST_NAME"
                                        value={contact.FIRST_NAME}
                                        onClick={()=>simpleValidator.current.showMessageFor('Firstname')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('Firstname', contact.FIRST_NAME, 'required|alpha')}
                                    </div>
                                </div>
                                <div class=" w-50">
                                    <br></br>
                                    <input
                                        type="text"
                                        onChange={handleContact}
                                        className="w-100 form-control px-4 checkout-input-small"
                                        name="LAST_NAME"
                                        value={contact.LAST_NAME}
                                        onClick={()=>simpleValidator.current.showMessageFor('Lastname')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('Lastname', contact.LAST_NAME, 'required|alpha')}
                                    </div>
                                </div>
                            </div>
                            <div class="py-2">
                                <span>Email *</span><br></br>
                                <input
                                    type="email"
                                    className="w-100 form-control px-4 checkout-input-small"
                                    name="Forgot_Email"
                                    onChange={handleContact}
                                    value={contact.EMAIL}
                                    onBlur={() => simpleValidator.current.showMessageFor('Email')}/>
                                <div className="text-danger">
                                    {simpleValidator.current.message('Email', contact.EMAIL, 'required|email')}
                                </div>
                            </div>
                            <div class="py-3">
                                <span>Comment or Message *</span><br></br>
                                <textarea class="checkout-message pt-2 form-control" v-validate="'required|alpha'"  name="Message">
                            </textarea>
                                <span v-show="errors.has('Message')"
                                      class="help text-danger"></span>
                            </div>
                            <div class="py-3">
                            <Button onClick={() => saveContact()}>SEND MESSAGE</Button>
                                {/* <button type="submit" class="contact-btn" onClick={() => saveContact()}>
                                    SEND MESSAGE
                                </button> */}
                            </div>
                        </div>

                </div>
                <div class="col-md-4">

                    <div>
                        <img class="cntct-map-img" src="assets/images/map.png"/>
                    </div>
                    <div class="pt-5">
                        <h4 class="pb-3 border-bottom">Our Store</h4>
                        <small>Santee St</small><br></br>
                        <small> Los Angeles, CA</small><br></br>
                        <small>USA.</small><br></br>
                    </div>
                    <div class="pt-4">
                        <strong>Contact</strong>
                        <ul class="pl-4">
                            <li><small>Phone: +1(424) 226-1234</small></li>
                            <li><small>Sales Email: sales@whellli.com</small></li>
                        </ul>
                    </div>
                    <div class="pt-4">
                        <strong>Hours of Operation</strong>
                        <div class="row pt-2">
                            <div class="col-md-7 col-sm-6 col-7">
                                <div>
                                    <small>Monday:</small>
                                </div>
                                <div>
                                    <small>Tuesday:</small>
                                </div>
                                <div>
                                    <small>Wednesday:</small>
                                </div>
                                <div>
                                    <small>Thursday:</small>
                                </div>
                                <div>
                                    <small>Friday:</small>
                                </div>
                                <div>
                                    <small>Saturday:</small>
                                </div>
                                <div>
                                    <small>Sunday:</small>
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-6 col-5">
                                <div>
                                    <small>7AM-9 PM</small>
                                </div>
                                <div>
                                    <small>7AM-9 PM</small>
                                </div>
                                <div>
                                    <small>7AM-9 PM</small>
                                </div>
                                <div>
                                    <small>7AM-9 PM</small>
                                </div>
                                <div>
                                    <small>12-6 PM</small>
                                </div>
                                <div>
                                    <small>7AM-9 PM</small>
                                </div>
                                <div>
                                    <small> 12PM-9 PM</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pt-4">
                        <div class="pb-2">
                            <strong>Careers</strong>
                        </div>
                        <small>
                            If you’re interested in employment opportunities at Premium Cannabis Shop, please email us:
                        </small><br></br>
                        <small>
                            contact@whellli.com
                        </small>
                    </div>

                </div>
            </div>
            <div>
                <div class="pt-5 pb-5">
                    <div class="row pt-5 pb-5">
                        <div class="col-md-4">
                            <h2 class="cart-last-sctn-hdng-brdr pb-3">Featured Products</h2>
                            <div class="d-flex py-3">
                                <div class="px-3">


                                </div>
                                <div class="px-3 pt-2">
                                    <div>
                                        <span class="text-success">Cannabis Chocolate Tuffles THC 150mg</span>
                                    </div>
                                    <div>
                                        <span>$130.00 - $1,065.00</span>
                                    </div>

                                </div>

                            </div>
                            <div class="d-flex py-3">
                                <div class="px-3">



                                </div>
                                <div class="px-3 pt-2">
                                    <div>
                                        <span class="text-success">Cannabis Chocolate Tuffles THC 150mg</span>
                                    </div>
                                    <div>
                                        <span>$130.00 - $1,065.00</span>
                                    </div>

                                </div>

                            </div>
                            <div class="d-flex py-3">
                                <div class="px-3">



                                </div>
                                <div class="px-3 pt-2">
                                    <div>
                                        <span class="text-success">Cannabis Chocolate Tuffles THC 150mg</span>
                                    </div>
                                    <div>
                                        <span>$130.00 - $1,065.00</span>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-md-4">
                            <h2 class="cart-last-sctn-hdng-brdr pb-3">Top Selling Products</h2>
                            <div class="d-flex py-3">
                                <div class="px-3">



                                </div>
                                <div class="px-3 pt-2">
                                    <div>
                                        <span class="text-success">Cannabis Chocolate Tuffles THC 150mg</span>
                                    </div>
                                    <div>
                                        <span>$130.00 - $1,065.00</span>
                                    </div>

                                </div>

                            </div>
                            <div class="d-flex py-3">
                                <div class="px-3">



                                </div>
                                <div class="px-3 pt-2">
                                    <div>
                                        <span class="text-success">Cannabis Chocolate Tuffles THC 150mg</span>
                                    </div>
                                    <div>
                                        <span>$130.00 - $1,065.00</span>
                                    </div>

                                </div>

                            </div>
                            <div class="d-flex py-3">
                                <div class="px-3">



                                </div>
                                <div class="px-3 pt-2">
                                    <div>
                                        <span class="text-success">Cannabis Chocolate Tuffles THC 150mg</span>
                                    </div>
                                    <div>
                                        <span>$130.00 - $1,065.00</span>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-md-4">
                            <h2 class="cart-last-sctn-hdng-brdr pb-3">On-Sale Products</h2>
                            <div class="d-flex py-3">
                                <div class="px-3">



                                </div>
                                <div class="px-3 pt-2">
                                    <div>
                                        <span class="text-success">Cannabis Chocolate Tuffles THC 150mg</span>
                                    </div>
                                    <div>
                                        <span>$130.00 - $1,065.00</span>
                                    </div>

                                </div>

                            </div>
                            <div class="d-flex py-3">
                                <div class="px-3">



                                </div>
                                <div class="px-3 pt-2">
                                    <div>
                                        <span class="text-success">Cannabis Chocolate Tuffles THC 150mg</span>
                                    </div>
                                    <div>
                                        <span>$130.00 - $1,065.00</span>
                                    </div>

                                </div>

                            </div>
                            <div class="d-flex py-3">
                                <div class="px-3">



                                </div>
                                <div class="px-3 pt-2">
                                    <div>
                                        <span class="text-success">Cannabis Chocolate Tuffles THC 150mg</span>
                                    </div>
                                    <div>
                                        <span>$130.00 - $1,065.00</span>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
    )
}

export default Contact