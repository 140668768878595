import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import langActions from './lang-actions'

import jsonLang from './../../json/lang.json';

const LANG = createReducer(jsonLang[0].type, {
    [langActions.updateLang]:(_, {payload}) => payload,

})

export default combineReducers({LANG})