import {useEffect, useRef} from "react";
import {useState} from "react";
import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';

const INITIAL_Forgot = {
    Forgot_Email: "",
}

const Forgot = ({}) => {
    const [, forceUpdate] = useState()
    const sendReset = () => {
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }
    }
    const [forgot, setForgot] = useState(INITIAL_Forgot)
    const handleForgot = ({target: {name, value}}) => {

        setForgot(state => ({...state, [name]: value}))
    }
    const simpleValidator = useRef(new SimpleReactValidator())
    return (
        <div className="container">
            <div className="text-center py-5">
                <h1>Forgot password</h1>
            </div>
            <div className="text-center pb-2">
                <p>Forgot your password? Please enter your username or email address. You will receive a link to create
                    a new password via email.</p>
            </div>

            <div className="field py-2 forget-sctn">
                <input
                    type="email"
                    className="input-rounded-fields form-control"
                    name="Forgot_Email"
                    onChange={handleForgot}
                    value={forgot.Forgot_Email}
                    onBlur={() => simpleValidator.current.showMessageFor('email')}/>
                <div className="text-danger">
                    {simpleValidator.current.message('email', forgot.Forgot_Email, 'required|email')}
                </div>
            </div>
            <div className="text-center">
                <button type="submit" className="forget-btn my-3 " onClick={() => sendReset()}>Reset Password</button>
            </div>
        </div>
    )
}

export default (Forgot);
