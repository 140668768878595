import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { authSelectors } from '../auth';
import userPrivateActions from './user-private-actions';

import {PRIVATE_USER} from '../../config/Url'
import { authActions } from '../auth';

import resetToken from './../resetToken';


const instance = axios.create({
    baseURL: PRIVATE_USER,
    headers: { "Access-Control-Allow-Origin": "*", }
});


// const resetToken = (state, dispatch) => {
//     if(Boolean(state().auth.token)){
//         // instance.defaults.headers.common.Authorization = `Bearer ${state().auth.token}`;

//         instance.interceptors.request.use(config => {
//             // dispatch(userActions.loader(true))
//             config.headers.Authorization = `Bearer ${state().auth.token}`;
//             return config;
        
//         }, error => {
//             // dispatch(userActions.loader(false))
//             return Promise.reject(error);
//         });
        
//         instance.interceptors.response.use(response => {
//             // dispatch(userActions.loader(false))
//             return response;
//         }, error => {
//             // dispatch(userActions.loader(false))
//             return Promise.reject(error);
//         });
//     }
// }


const createFeedback = credential => async (dispatch, state) => {


    resetToken(instance, state, dispatch)
    dispatch(userPrivateActions.createFeedbackRequest())

    try{
        const res = await instance.post('/', {
            name:"create_feedback",
            parameters:credential
        });
        dispatch(userPrivateActions.createFeedbackSuccess(res.data))

    }catch (error){
        dispatch(userPrivateActions.createFeedbackError())
    }
}

const createFeedbackLike = credential => async (dispatch, state) => {


    resetToken(instance, state, dispatch)
    dispatch(userPrivateActions.createFeedbackLikeRequest())

    try{
        const res = await instance.post('/', {
            name:"create_feedback_like",
            parameters:credential
        });
        dispatch(userPrivateActions.createFeedbackLikeSuccess(res.data))

    }catch (error){
        dispatch(userPrivateActions.createFeedbackLikeError())
    }
}

const createFeedbackDislike = credential => async (dispatch, state) => {


    resetToken(instance, state, dispatch)
    dispatch(userPrivateActions.createFeedbackDislikeRequest())

    try{
        const res = await instance.post('/', {
            name:"create_feedback_dislike",
            parameters:credential
        });
        dispatch(userPrivateActions.createFeedbackDislikeSuccess(res.data))

    }catch (error){
        dispatch(userPrivateActions.createFeedbackDislikeError())
    }
}



export default {
    getUserProfile: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserProfileRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_profile",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserProfileSuccess(res.data))
    
        }catch (error){
            dispatch(userPrivateActions.getUserProfileError())
        }
    },

    updateUserProfile: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.updateUserProfileRequest())
    
        try{
            const res = await instance.post('/', {
                name:"update_user_profile",
                parameters:credential
            });
            dispatch(userPrivateActions.updateUserProfileSuccess(res.data))
    
        }catch (error){
            dispatch(userPrivateActions.updateUserProfileError())
        }
    },

    updateUserPassword: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.updateUserPasswordRequest())
    
        try{
            const res = await instance.post('/', {
                name:"update_user_password",
                parameters:credential
            });
            dispatch(userPrivateActions.updateUserPasswordSuccess(res.data))
    
        }catch (error){
            dispatch(userPrivateActions.updateUserPasswordError())
        }
    },

    getUserAddress: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserAddressRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_address",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserAddressSuccess(res.data))
    
        }catch (error){
            dispatch(userPrivateActions.getUserAddressError())
        }
    },

    updateUserAddress: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.updateUserAddressRequest())
    
        try{
            const res = await instance.post('/', {
                name:"update_user_address",
                parameters:credential
            });
            dispatch(userPrivateActions.updateUserAddressSuccess(res.data))
    
        }catch (error){
            dispatch(userPrivateActions.updateUserAddressError())
        }
    },
    getAddProductToCart: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getAddProductToCartRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_add_product_to_cart",
                parameters:credential
            });
            dispatch(userPrivateActions.getAddProductToCartSuccess(res.data))
    
        }catch (error){
            dispatch(userPrivateActions.getAddProductToCartError())
        }
    },
    getCart: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getCartRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_cart",
                parameters:credential
            });
            dispatch(userPrivateActions.getCartSuccess(res.data))
    
        }catch (error){
            dispatch(userPrivateActions.getCartError())
        }
    },

    getUpdateProductQtyInCart: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUpdateProductQtyInCartRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_update_product_qty_in_cart",
                parameters:credential
            });
            dispatch(userPrivateActions.getUpdateProductQtyInCartSuccess(res.data))
    
        }catch (error){
            dispatch(userPrivateActions.getUpdateProductQtyInCartError())
        }
    },

    getRemoveProductFromCart: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getRemoveProductFromCartRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_remove_product_from_cart",
                parameters:credential
            });
            dispatch(userPrivateActions.getRemoveProductFromCartSuccess(res.data))
    
        }catch (error){
            dispatch(userPrivateActions.getRemoveProductFromCartError())
        }
    },

    proceedToCheckout: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.proceedToCheckoutRequest())
    
        try{
            const res = await instance.post('/', {
                name:"proceed_to_checkout",
                parameters:credential
            });
            dispatch(userPrivateActions.proceedToCheckoutSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.proceedToCheckoutError())
        }
    },

    saveProceedToCheckout: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.saveProceedToCheckoutRequest())
    
        try{
            const res = await instance.post('/', {
                name:"save_proceed_to_checkout",
                parameters:credential
            });
            dispatch(userPrivateActions.saveProceedToCheckoutSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.saveProceedToCheckoutError())
        }
    },

    checkoutCryptoOrder: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.checkoutCryptoOrderRequest())
    
        try{
            const res = await instance.post('/', {
                name:"checkout_crypto_order",
                parameters:credential
            });
            dispatch(userPrivateActions.checkoutCryptoOrderSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.checkoutCryptoOrderError())
        }
    },

    getUserOrdersHistory: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserOrdersHistoryRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_orders_history",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserOrdersHistorySuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.getUserOrdersHistoryError())
        }
    },

    getUserOrderById: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserOrderByIdRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_order_by_id",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserOrderByIdSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.getUserOrderByIdError())
        }
    },

    getUserDeliveryInfoById: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserDeliveryInfoByIdRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_delivery_info_by_id",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserDeliveryInfoByIdSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.getUserDeliveryInfoByIdError())
        }
    },

    getUserBraintreeToken: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserBraintreeTokenRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_braintree_token",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserBraintreeTokenSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.getUserBraintreeTokenError())
        }
    },
    userTransactionSale: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.userTransactionSaleRequest())
    
        try{
            const res = await instance.post('/', {
                name:"user_transaction_sale",
                parameters:credential
            });
            dispatch(userPrivateActions.userTransactionSaleSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.userTransactionSaleError())
        }
    },

    logoutUser: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.logoutUserRequest())
    
        try{
            const res = await instance.post('/', {
                name:"logout_user",
                parameters:credential
            });
            dispatch(userPrivateActions.logoutUserSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.logoutUserError())
        }
    },

    userTransactionPaypal: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.userTransactionPaypalRequest())
    
        try{
            const res = await instance.post('/', {
                name:"user_transaction_paypal",
                parameters:credential
            });
            dispatch(userPrivateActions.userTransactionPaypalSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.userTransactionPaypalError())
        }
    },

    getUserPaypalClientId: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserPaypalClientIdRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_paypal_client_id",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserPaypalClientIdSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.getUserPaypalClientIdError())
        }
    },

    getUserSquareupSettings: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserSquareupSettingsRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_squareup_settings",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserSquareupSettingsSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.getUserSquareupSettingsError())
        }
    },
    userTransactionSquareup: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.userTransactionSquareupRequest())
    
        try{
            const res = await instance.post('/', {
                name:"user_transaction_squareup",
                parameters:credential
            });
            dispatch(userPrivateActions.userTransactionSquareupSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.userTransactionSquareupError())
        }
    },
    getUserStripToken: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserStripTokenRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_strip_token",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserStripTokenSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.getUserStripTokenError())
        }
    },

    getUserStripClientSecret: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.getUserStripClientSecretRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_strip_client_secret",
                parameters:credential
            });
            dispatch(userPrivateActions.getUserStripClientSecretSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.getUserStripClientSecretError())
        }
    },

    userTransactionStrip: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.userTransactionStripRequest())
    
        try{
            const res = await instance.post('/', {
                name:"user_transaction_strip",
                parameters:credential
            });
            dispatch(userPrivateActions.userTransactionStripSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.userTransactionStripError())
        }
    },

    applyDiscountProduct: credential => async (dispatch, state) => {
        resetToken(instance, state, dispatch)
        dispatch(userPrivateActions.applyDiscountProductRequest())
    
        try{
            const res = await instance.post('/', {
                name:"apply_discount_product",
                parameters:credential
            });
            dispatch(userPrivateActions.applyDiscountProductSuccess({credential, data:res.data}))
    
        }catch (error){
            // console.log(error);
            dispatch(userPrivateActions.applyDiscountProductError())
        }
    },

    createFeedbackLike,
    createFeedbackDislike,
    createFeedback,
}
