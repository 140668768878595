import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { userPrivateOperations } from "../../redux/userPrivate"
import True from './../../assets/svg/true.svg'
import {DOMAIN} from '../../config/Url'
import queryString from 'query-string';
import { Base64 } from "js-base64"

const Thanks = ({
    onGetCart,
    initWebSait,
    location
}) => {

   

    // const [search] = useState(queryString.parse(match?.search))

    const [html, setHtml] = useState('')
    useEffect(()=>{
        onGetCart({DOMAIN})
    }, [])

    useEffect(()=>{
        const search = queryString.parse(location?.search)
        console.log(search);
        if(search?.record && initWebSait?.PAYMENT_INFO){
            initWebSait?.PAYMENT_INFO['#result-set-1']?.filter(({ID_RECORD})=>ID_RECORD=== Number(search?.record)).map(({THANK_PAGE_TEXT})=>setHtml(Base64.decode(THANK_PAGE_TEXT)))
        }
    }, [location, initWebSait])

    // ID_RECORD
    return (
        <div className="text-center mx-5 my-5" dangerouslySetInnerHTML={{__html: html}}>

        {/* <h1 className="thanks-h1">Thanks You!</h1>
        <img src={True} className="my-5" />
        <p className="thanks-p">
        Thank you very much for filling out our form.<br/>
        Your eBook is waiting for you in your inbox, with one secret and special bonus:)
        </p>
        <Link to="/">Get back to our homepage</Link> */}
        </div>
    ) 
}
const mapStateToProps = (state) => ({
    initWebSait:state.user.initWebSait,
})

const mapDispatchToProps = {
    onGetCart:userPrivateOperations.getCart,
}
export default connect(mapStateToProps, mapDispatchToProps)(Thanks);
// export default Thanks