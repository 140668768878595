import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { authActions, authSelectors } from "../../redux/auth"
import { userOperations } from "../../redux/user"
import Popup from "../Popup/Popup"
import SimpleReactValidator from 'simple-react-validator';
import {DOMAIN} from '../../config/Url'

import Button from "../Button/Button"
const INITIAL_LOGIN = {
    EMAIL: "",
    PASSWORD: ""
}

const INITIAL_FORGOT = {
    EMAIL: "",
}

const INITIAL_REGISTER = {
    FIRST_NAME: "",
    LAST_NAME: "",
    NICK_NAME: "",
    GENDER: 0,
    BIRTHDAY: "",
    EMAIL: '',
    PHONE: "",
    PHONE_COUNTRY_CODE: "",
    COUNTRY_CODE: "",
    PASSWD: '',
    PASSWD2: '',
}

const INITIAL_SHOW_PASSWORD = {
    'pass1':true,
    'pass2':true,
    'pass3':true,
}

const PopupLoginRegister = ({
    closePopup,
    getIsAuthenticated,
    onUserRegistration,
    onUserAutorization,
    history,
    errCode,
    resetLoginErr,
    onForgotPasswordEmail,
    setErrCode
}) => {
    const [login, setLogin] = useState(INITIAL_LOGIN)
    const [register, setRegister] = useState(INITIAL_REGISTER)
    const [showPass, setShowPass] = useState(INITIAL_SHOW_PASSWORD);

    const [page, setPage] = useState(0)

    const [rel, setRel] = useState(-1)
    const simpleValidator = useRef(new SimpleReactValidator())
    const simpleValidator2 = useRef(new SimpleReactValidator())
    const simpleValidator3 = useRef(new SimpleReactValidator())
    

    useEffect(() => {
        console.log({getIsAuthenticated});
        if (getIsAuthenticated) {
            // setLogin(INITIAL_LOGIN)
            // setRegister(INITIAL_REGISTER)
            // setRel(-1)
            // // history.goBack()
            // history.push(`/`)

            // closePopup({status:1})

            window.location.reload()
        }
    }, [getIsAuthenticated])

    const handleLogin = ({target: {name, value}}) => {
        resetLoginErr()
        setLogin(state => ({...state, [name]: value}))
    }

    const handleRegister = ({target: {name, value}}) => {
        resetLoginErr()
        setRegister(state => ({...state, [name]: value}))


    }
    const [, forceUpdate] = useState()
    const sendLogin = async () => {
        setRel(1)
        resetLoginErr()
        
        const formValid = simpleValidator.current.allValid()

        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
            return
        }

        onUserAutorization(login)

    }


    const sendRegister = () => {
        setRel(2)
        resetLoginErr()
       
        const formValid = simpleValidator2.current.allValid()
        console.log(formValid);
        if (!formValid) {
            simpleValidator2.current.showMessages()
            forceUpdate(1)
            return;
        }

        const copyRegister = register
        delete copyRegister.PASSWD2

        onUserRegistration({...copyRegister});

        

    }

    const sendReset = () => {
        setRel(3)
        const formValid = simpleValidator3.current.allValid()
        if (!formValid) {
            simpleValidator3.current.showMessages()
            forceUpdate(1)

            return
        }

        onForgotPasswordEmail({DOMAIN, ...forgot})
    }
    const [forgot, setForgot] = useState(INITIAL_FORGOT)
    const handleForgot = ({target: {name, value}}) => {

        setForgot(state => ({...state, [name]: value}))
    }
    
    const showPassEye = key =>{
        setShowPass(state => ({...state, [key]:!showPass[key]}))
    }

    return (
        <Popup closePopup={()=>closePopup({status:0})} width={page === 1 ? '600px' : '1220px'}>
            <div className="modal-body">
                
        {page === 0 && 
        <div className="container">

            <div className="d-flex display-block">
                <div className="w-50 p-3 full-with">
                    <div className="pb-3">
                        <h2 className="text-btm-border">Login</h2>
                    </div>
                    <div className="pb-5">
                        <p>Welcome back! Sign in to your account.</p>
                    </div>
                    <div>
                        <label>Username or email address*</label>
                    </div>
                    <div className="pb-3">
                        <input type="email" 
                        className="input-rounded-fields form-control" 
                        name="EMAIL" 
                        value={login.EMAIL} 
                        onChange={handleLogin} 
                        // style={{borderColor:simpleValidator.current.fieldValid('Email') ? '' : 'red'}}
                        onBlur={() => simpleValidator.current.showMessageFor('Email')}
                        />
                       
                        <div className="text-danger">
                            {simpleValidator.current.message('Email', login.EMAIL, 'required|email')}
                        </div>
                    </div>
                    
                    <div className="control pb-3">
                        <label>Password*</label>
                        <input type={showPass.pass1 ? 'password' : 'text'} 
                            className="input-rounded-fields form-control" 
                            name="PASSWORD"
                            value={login.PASSWORD} 
                            onChange={handleLogin}
                            // style={{borderColor:simpleValidator.current.fieldValid('Password') ? '' : 'red'}}
                            onBlur={() => simpleValidator.current.showMessageFor('Password')}
                         />
                         <i className="fa fa-eye" onClick={()=>showPassEye('pass1')} />
                        <div className="text-danger">
                            {simpleValidator.current.message('Password', login.PASSWORD, 'required|alpha_num')}
                        </div>
                    </div>
                    <div className="pb-4">
                        <input type="checkbox" className="px-2"/>
                        <span className="pl-2">Remember me</span>
                    </div>
                    {/* {errCode > 1 && rel === 1 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>Error code: {errCode}</div>
                        <br/></>} */}

                    { [0, -1, 1, 14].indexOf(errCode) === -1 && rel === 1 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>Error code: {errCode}</div>
                        <br/></>}

                    {errCode === 14 && rel === 1 && <> <div style={{color: 'red', textAlign: 'center'}} >User not found / Wrong login or password</div><br/></>}

                    <div className="pb-3">
                    <Button onClick={() => sendLogin()}>LOG IN</Button>
                        {/* <input className="login-buttons" type="submit" value="LOG IN" onClick={() => sendLogin()}/> */}
                    </div>
                    <div>
                        {/* <Link to="/forgot"> Lost your passsword?</Link> */}
                        <a style={{color: '#007bff', cursor:'pointer'}} onClick={()=>{setErrCode(-1); setPage(1)}}> Lost your passsword?</a>
                    </div>
                </div>
                <div className="w-50  p-3 full-with">
                    <div className="pb-3">
                        <h2 className="text-btm-border">Register</h2>
                    </div>
                    <div className="pb-3">
                        <p>Create new account today to reap the benefits of a personalized shopping experience.</p>
                    </div>

                    <div className="control pb-3 has-icons-left">
                                <label>Firstname</label>
                                <input
                                    type="text"
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="FIRST_NAME"
                                    value={register.FIRST_NAME}
                                    // style={{borderColor:simpleValidator2.current.fieldValid('firstname') ? '' : 'red'}}
                                    onBlur={() => simpleValidator2.current.showMessageFor('firstname')}/>
                                <div className="text-danger">
                                    {simpleValidator2.current.message('firstname', register.FIRST_NAME, 'required|alpha')}
                                </div>
                            </div>
                            <div className="control pb-3 has-icons-left">
                                <label>Lastname</label>
                                <input
                                    type="text"
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="LAST_NAME"
                                    value={register.LAST_NAME}
                                    // style={{borderColor:simpleValidator2.current.fieldValid('lastname') ? '' : 'red'}}
                                    onBlur={() => simpleValidator2.current.showMessageFor('lastname')}/>
                                <div className="text-danger">
                                    {simpleValidator2.current.message('lastname', register.LAST_NAME, 'required|alpha')}
                                </div>
                            </div>

                    <div className="control pb-3 has-icons-left">
                                <label>Phonenumber</label><br/>
                                <div className="d-flex">
                                    <select className=" md-form pl-2  selecting-custom-styling" defaultValue={0} >
                                        <option value="0" className="text-success">+91</option>
                                        <option value="1">+92</option>
                                        <option value="2">123</option>
                                        <option value="3">2323</option>
                                        <option value="3">3456</option>
                                        <option value="3">111</option>
                                    </select>
                                    <div>
                                        <input
                                            style={{width: '463px !important'}}
                                            type="phone"
                                            onChange={handleRegister}
                                            className="adjust-width input-rounded-fields form-control mx-2 float-right"
                                            name="PHONE"
                                            minLength={7}
                                            maxLength={10}
                                            value={register.PHONE}
                                            // style={{borderColor:simpleValidator2.current.fieldValid('phonenumber') ? '' : 'red'}}
                                            onBlur={() => simpleValidator2.current.showMessageFor('phone_number')}/>
                                    </div>
                                </div>
                                <div className="text-danger" style={{marginLeft:75}}>
                                    {simpleValidator2.current.message('phone_number', register.PHONE, 'required|min:7|max:10')}
                                </div>
                            </div>
                  

                    <div className="pb-3">
                        <label>Email address*</label>
                        {/*<Input type="email" className="input-rounded-fields form-control" name="EMAIL" validations={[required, email]} value={register.EMAIL} onChange={handleRegister} />*/}
                        <input
                            type="email"
                            onChange={handleRegister}
                            className="input-rounded-fields form-control"
                            name="EMAIL"
                            value={register.EMAIL}
                            // style={{borderColor:simpleValidator2.current.fieldValid('email') ? '' : 'red'}}
                            onBlur={() => simpleValidator2.current.showMessageFor('email')}/>
                        <div className="text-danger">
                            {simpleValidator2.current.message('email', register.EMAIL, 'required|email')}
                        </div>
                    </div>
                    <div className="control pb-3 has-icons-left">
                                <label>Password</label>
                                <input
                                    type={showPass.pass2 ? 'password' : 'text'} 
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="PASSWD"
                                    value={register.PASSWD}
                                    // style={{borderColor:simpleValidator2.current.fieldValid('password') ? '' : 'red'}}
                                    onBlur={() => simpleValidator2.current.showMessageFor('password')}/>
                                    <i className="fa fa-eye" onClick={()=>showPassEye('pass2')} />
                                <div className="text-danger">
                                    {simpleValidator2.current.message('password', register.PASSWD, 'required|alpha_num')}
                                </div>
                            </div>
                            <div className="control pb-3 has-icons-left">
                                <label>Confirm Password</label>
                                <input
                                    type={showPass.pass3 ? 'password' : 'text'} 
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="PASSWD2"
                                    value={register.PASSWD2}
                                    
                                    // style={{borderColor:simpleValidator2.current.fieldValid('confirm_password') ? '' : 'red'}}
                                    onBlur={() => simpleValidator2.current.showMessageFor('confirm_password')}/>

                                    <i className="fa fa-eye" onClick={()=>showPassEye('pass3')} />
                                <div className="text-danger">
                                    {simpleValidator2.current.message('confirm_password', register.PASSWD2, `required|in:${register.PASSWD}`, {messages: {in: 'Passwords need to match!'}})}
                                </div>
                            </div>
                            <div className="pt-5">
                                <p>Your personal data will be used to support your experience thoughtout this website,
                                    to
                                    manage accesss
                                    to
                                    your account, and for other purposes described in our <a href="#">privacy policy</a>.
                                </p>
                            </div>

                    { [0, -1, 1, 11, 12].indexOf(errCode) === -1 && rel === 2 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>Error code: {errCode}</div>
                        <br/></>}

                    {errCode === 11 && rel === 2 && <> <div style={{color: 'red', textAlign: 'center'}} >User with such a email already registered</div><br/></>}
                    {errCode === 12 && rel === 2 && <> <div style={{color: 'red', textAlign: 'center'}} >User with such a phone number already registered</div><br/></>}
                    <div>
                    <Button onClick={() => sendRegister()}>REGISTER</Button>
                        {/* <input type="button" value="REGISTER" className="login-buttons" onClick={() => sendRegister()}/> */}
                    </div>
                </div>
            </div>
        </div>
        }

        {page === 1 && 
            <div className="container">
                <div className="text-center py-5">
                    <h1>Forgot password</h1>
                </div>

                {[0, -1, 14].indexOf(errCode) !== -1 &&
                    <>

                    <div className="text-center pb-2">
                        <p>Forgot your password? Please enter your username or email address. You will receive a link to create
                            a new password via email.</p>
                    </div>

                    <div className="field py-2">
                    <label>Email address*</label>
                        <input
                            type="email"
                            className="input-rounded-fields form-control"
                            name="EMAIL"
                            onChange={handleForgot}
                            value={forgot.EMAIL}
                            onBlur={() => simpleValidator3.current.showMessageFor('email')}/>
                        <div className="text-danger">
                            {simpleValidator3.current.message('email', forgot.EMAIL, 'required|email')}
                        </div>
                    </div>
                    <div className="text-center">
                    <Button onClick={() => sendReset()}>Reset Password</Button>
                        {/* <button type="submit" className="forget-btn my-3 " onClick={() => sendReset()}>Reset Password</button> */}
                    </div>
                    {/* {rel === 3 && <div style={{color:'red'}}>Error code {errCode}</div>} */}

                    {errCode === 14 && rel === 3 && <> <div style={{color: 'red', textAlign: 'center'}} >User not found</div><br/></>}
                    
                    </>
                }

                {errCode === 1 &&
                    <p style={{textAlign:'center'}}>
                        Password has been sent to your mailbox.<br/> 
                        You will be able to set new password in Personal cabinet
                    </p>
                }

               
            </div>
        }

        </div>
            <div className="modal-footer">
            <Button line  onClick={()=>{closePopup({status:0})}}>Close</Button>
                {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{closePopup({status:0})}}>Close</button> */}
            </div>
        </Popup>

    )
}
const mapStateToProps = (state) => ({
    lang: state.lang.LANG,
    errCode: state.auth.errCode,
    getIsAuthenticated: authSelectors.getIsAuthenticated(state),
})

const mapDispatchToProps = {
    onUserRegistration: userOperations.userRegistration,
    onUserAutorization: userOperations.userAutorization,
    onForgotPasswordEmail: userOperations.forgotPasswordEmail,
    resetLoginErr: authActions.logoutSuccess,
    setErrCode: authActions.setErrCode,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupLoginRegister);
