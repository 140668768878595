import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

// import './../node_modules/jquery/dist/jquery.min.js'
// import './assets/js/bootstrap.js'
// import './../node_modules/bootstrap/dist/js/bootstrap.min.js';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'react-image-lightbox/style.css';
import './../node_modules/js-image-zoom/js-image-zoom.js'

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

