
import styles from './Stars.module.css';

const Stars = ({
    value=0,
    onChange,
    count=5,
    style
}) => {

    return (
        <div className={styles.Stars}>

            {
                Array.from({length:count}).map((i , k)=>{
                    return(<i key={k} className={['fa', value >= k+1 ? 'fa-star' : 'fa-star-o'].join(' ')} style={style} onClick={()=>onChange(k+1)} ></i>)
                })
            }
                                    
        </div>
    ) 
}

export default Stars