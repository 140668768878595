
import Header from './components/Header'
import Footer from './components/Footer'
import PopupAlert from './components/PopupAlert/PopupAlert'
import { useSelector } from 'react-redux'
import { authActions, authSelectors } from './redux/auth'
import PopupLoginRegister from './components/PopupLoginRegister/PopupLoginRegister'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { configSelectors } from './redux/config'
import Loading from './components/Loading/Loading'
const Layouts = ({children, redirectAuth=false}) => {
    const history = useHistory()
    const getIsAuthenticated = useSelector(authSelectors.getIsAuthenticated)
    const loading = useSelector(configSelectors.getLoading)

    const yearsAge18 = useSelector(authSelectors.yearsAge18)

    console.log({yearsAge18});
    const [show, setShow] = useState(false)

    useEffect(()=>{
        if(redirectAuth && !getIsAuthenticated)
        setShow(true)
    }, [redirectAuth])

    const closeAuth = ({status}) =>{
        if(status===0){
            history.goBack()
        }  
        setShow(false);
    }

    return(
        <>
        {loading && <Loading/>}
            <div className="container-fluid">
                <Header />
                    <div className="container pb-5 mb-5">
                        {children}
                    </div>
                <Footer />
            </div>

           {[-1, 2].indexOf(yearsAge18) !== -1 &&  <PopupAlert />}
 
           {show && <PopupLoginRegister closePopup={state=>closeAuth(state)} /> }
        </>
    
    );
}

export default Layouts