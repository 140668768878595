import {createAction} from '@reduxjs/toolkit';


// // const getItemsListRequest = createAction('model/getItemsListRequest')
// const loader = createAction('model/loader')

// const getAppPagesListRequest = createAction('model/getAppPagesListRequest')
// const getAppPagesListSuccess = createAction('model/getAppPagesListSuccess')
// const getAppPagesListError = createAction('model/getAppPagesListError')

const loader = createAction('model/loader')
const getProductFromLuceneRequest = createAction('model/getProductFromLuceneRequest')
const getProductFromLuceneSuccess = createAction('model/getProductFromLuceneSuccess')
const getProductFromLuceneError = createAction('model/getProductFromLuceneError')




export default{

    loader,
    getProductFromLuceneRequest,
    getProductFromLuceneSuccess,
    getProductFromLuceneError,

}