import { useEffect, useState } from "react"
import Popup from "../../components/Popup/Popup"
import { PayPalButton } from "react-paypal-button-v2";
import { connect } from "react-redux";
import { userPrivateActions, userPrivateOperations } from "../../redux/userPrivate";
import {DOMAIN} from './../../config/Url'
import { useHistory } from "react-router-dom";

// const CLIENT_ID = 'AT3UZX4bt49tIMRK-gKxRwlX7JGFVWgJoaXphxB2FlctR1U50LaGCGHL9BQPn7flhxl7Icxx8y8KaE59'

const PopupPayPal = ({
    closePopup,
    total,
    currency,
    orderId,
    onUserTransactionPaypal,
    userTransactionPaypal,
    onGetUserPaypalClientId,
    getUserPaypalClientId,
    payment,
    setErrorCode
}) => {

    /*
    Card Type: Visa

    Card Number: 4032030600165808

    Expiration Date: 09/2024

    CVV: 852
    */

    const [rel, setRel] = useState(0);
    const history = useHistory();
    useEffect(()=>{
        setRel(0)
        onGetUserPaypalClientId({DOMAIN})
    }, [])


    useEffect(()=>{
        if(userTransactionPaypal === 1){
            // history.push('/thanks') 
            history.push({
                pathname: '/thanks',
                search: `?record=${payment?.ID_RECORD}`,
            });
        }else if(userTransactionPaypal === -1){
            setRel(-1)
        }
    }, [userTransactionPaypal])


    return (
        <Popup closePopup={()=>{closePopup(); setErrorCode(0)}}>
            <div className="mx-3 my-2">
                {rel === -1 && 
                    <h2 className="text-center my-5" style={{color:'red'}} >Something went wrong. We`ll take you back to checkout so you can try again.</h2>
                }
                {rel === 1 && 
                    <h2 className="text-center my-5" style={{color:'red'}} >Declined</h2>
                }

                {rel === 0 && getUserPaypalClientId === '' && 
                    <h2 className="text-center my-5"  >Loading...</h2>
                }
                {rel === 0 && getUserPaypalClientId !== '' && 
                <PayPalButton

                    createOrder={(data, actions) => {
                        return actions.order.create({
                        purchase_units: [{
                            reference_id:orderId,
                            amount: {
                                currency_code: currency,
                                value: total
                            }
                        }],

                        });
                    }}
    
                    onSuccess={(details, data) => {

                        console.log({details, data});
                    // alert("Transaction completed by " + details.payer.name.given_name);

                    // OPTIONAL: Call your server to save the transaction
                    // return fetch("/paypal-transaction-complete", {
                    //     method: "post",
                    //     body: JSON.stringify({
                    //     orderId: data.orderID
                    //     })
                    // });

                        onUserTransactionPaypal({DOMAIN, PAYPAL_ORDER_ID:data.orderID})
                    }}
                    options={{
                        clientId: getUserPaypalClientId,
                        currency
                    }}
                />
                }
                
 
            </div>
        </Popup>
    ) 
}

const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    orderId:state.userPrivate.orderId,
    userTransactionPaypal:state.userPrivate.userTransactionPaypal,
    getUserPaypalClientId:state.userPrivate.getUserPaypalClientId,
})

const mapDispatchToProps = {
    onUserTransactionPaypal:userPrivateOperations.userTransactionPaypal,
    onGetUserPaypalClientId:userPrivateOperations.getUserPaypalClientId,
    setErrorCode:userPrivateActions.setErrorCode,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupPayPal);
