import { Base64 } from "js-base64";


// const isBase64 = (str) => {

//     if(!str.match('/^[a-zA-Z0-9\/\r\n+]*={0,2}$/')) return false

//     const decode = Base64.decode(str);
//     if(decode === false) return false;

//     if(Base64.encode(decode) !== str) return false;

//     return true
// }


export const Base64Decode = (title) => {

    if(title === '' || title === null){
        return('');
    }

    try{
        // console.log(isBase64(title));
        // if(isBase64(title))
            return(Base64.decode(title))
        // else return(title);

    }catch(e){
        return(title)
    }

}

