import axios from 'axios';
import jsonActions from './json-actions';

// axios.defaults.headers.common.Authorization = ''

const instance = axios.create({
    baseURL: 'https://whellli.com/context/applications/widgets/'
});

delete instance.defaults.headers.common['Authorization'];



const updateJSON = file => async dispatch => {

    dispatch(jsonActions.updateJSONRequest());

    try{
        const res = await instance.get(`${file}.json`)
        console.log(res)
        dispatch(jsonActions.updateJSONSuccess({json:file, data:res.data}));

    }catch (error){
        dispatch(jsonActions.updateJSONError(error));
    }
}


export default {
    updateJSON
}
