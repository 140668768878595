
export const WEB_PICTURES_FILE= "https://whellli.com:8444/files/file/WEB_PICTURES/"
export const WEB_PICTURES_UPLOAD="https://whellli.com:8444/files/upload/WEB_PICTURES"
export const WEB_PICTURES_FILE_RESIZER= "https://whellli.com:8444/files/fileresizer/WEB_PICTURES/"

export const PUBLIC_USER = "https://whellli.com:8444/api/public/user/wpa" //- без авторизации
export const PRIVATE_USER = "https://whellli.com:8444/api/private/user/wpa" //- с авторизацией  
export const DOMAIN = process.env.NODE_ENV === 'production' ? `${window.location.origin}` : "https://whellli.com" 
// export const DOMAIN = process.env.NODE_ENV === 'production' ? `${window.location.origin}` : "https://mybcbudstore.com" 
// export const DOMAIN = process.env.NODE_ENV === 'production' ? `${window.location.origin}` : "https://cartune.com.ua"
// export const DOMAIN =  `${window.location.origin}/whellli`

export const ADMIN_URL = process.env.NODE_ENV === 'production' ? "https://admin.hiiroad.com/" : "http://localhost:3000/"
export const BRAINTREE_CLIENT_TOKEN = '';