import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import jsonActions from './json-actions';

const result = createReducer([], {
    [jsonActions.updateJSONSuccess]:(state, {payload}) => {
        console.log(state)

        const arrJSON = state.filter(({json}) => json !== payload.json)

        return [
            ...arrJSON,
            payload
        ]
    }
    // [jsonActions.updateJSONSuccess]:(state, {payload}) => ([
    //     ...state,
    //     payload
    // ])
});

export default combineReducers({
    result
})