import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import userPrivateActions from './user-private-actions';
import { authActions } from '../auth';



// const currencyList = createReducer([], {
//     [userPrivateActions.currencyListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
// });

// const getProductsByGroup = createReducer({}, {
//     [userPrivateActions.getProductsByGroupSuccess]:(_, {payload}) => payload.result,
// });



const err_code = createReducer(0, {
    [userPrivateActions.createFeedbackRequest]:() => -1,
    [userPrivateActions.createFeedbackLikeRequest]:() => -1,
    [userPrivateActions.createFeedbackDislikeRequest]:() => -1,
    [userPrivateActions.updateUserProfileRequest]:() => -1,
    [userPrivateActions.updateUserPasswordRequest]:() => -1,
    [userPrivateActions.updateUserAddressRequest]:() => -1,
    [userPrivateActions.getUserAddressRequest]:() => -1,
    [userPrivateActions.getUserAddressSuccess]:() => -1,
    [userPrivateActions.getUpdateProductQtyInCartRequest]:() => -1,
    [userPrivateActions.getRemoveProductFromCartRequest]:() => -1,
    [userPrivateActions.saveProceedToCheckoutRequest]:() => -1,
    [userPrivateActions.applyDiscountProductRequest]:() => -1,
   
    [userPrivateActions.applyDiscountProductSuccess]:(_, {payload}) => payload.data.err_code,
    [userPrivateActions.saveProceedToCheckoutSuccess]:(_, {payload}) => payload.err_code,
    [userPrivateActions.getRemoveProductFromCartSuccess]:(_, {payload}) => payload.err_code,
    [userPrivateActions.getUpdateProductQtyInCartSuccess]:(_, {payload}) => payload.err_code,
    [userPrivateActions.updateUserAddressSuccess]:(_, {payload}) => payload.err_code,
    [userPrivateActions.updateUserPasswordSuccess]:(_, {payload}) => payload.err_code,
    [userPrivateActions.updateUserProfileSuccess]:(_, {payload}) => payload.err_code,
    [userPrivateActions.createFeedbackDislikeSuccess]:(_, {payload}) => payload.err_code,
    [userPrivateActions.createFeedbackLikeSuccess]:(_, {payload}) => payload.err_code,
    [userPrivateActions.createFeedbackSuccess]:(_, {payload}) => payload.err_code,

})
const err_t = createReducer('', {
    // [userActions.updatePageSuccess]:(_, {payload}) => payload.err_t,
    // [userActions.getItemsListSuccess]:(_, {payload}) => payload.err_t,
    // [userActions.getItemsByIdSuccess]:(_, {payload}) => payload.err_t
})

const loading = createReducer(false, {
    // [userActions.getAppPagesListRequest]:() => true,
    // [userActions.getAppPagesListSuccess]:() => false,
    // [userActions.getAppPagesListError]:() => false,

})

const loader = createReducer(false, {
    // [userActions.loader]:(_, {payload}) => payload
})

export default combineReducers({
    err_code,
    err_t,
    loading,
    loader,
    getUserProfile:createReducer({}, {
        [userPrivateActions.getUserProfileSuccess]:(_, {payload}) => payload.result,
    }),

    getUserAddress:createReducer([], {
        [userPrivateActions.getUserAddressSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),
    getCart:createReducer({}, {
        [authActions.logoutSuccess]:() => {},
        [userPrivateActions.getCartClear]:(_, {payload}) => payload,
        [userPrivateActions.getCartSuccess]:(_, {payload}) => payload.result,
    }),
    getAddProductToCart:createReducer({}, {
        [userPrivateActions.getAddProductToCartSuccess]:(_, {payload}) => payload.result,
    }),
    orderId:createReducer(0, {
        [userPrivateActions.getCartSuccess]:(_, {payload}) => payload.result.ID_ORDER,
    }),
    proceedToCheckout:createReducer({}, {
        [userPrivateActions.proceedToCheckoutSuccess]:(_, {payload}) => payload.result,
    }),
    checkoutCryptoOrder:createReducer(-1, {
        [userPrivateActions.saveProceedToCheckoutRequest]:() => -1,
        [userPrivateActions.checkoutCryptoOrderSuccess]:(_, {payload}) => payload.err_code,
    }),

    getUserOrdersHistory:createReducer([], {
        [userPrivateActions.getUserOrdersHistorySuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),
    getUserOrderById:createReducer({}, {
        [userPrivateActions.getUserOrderByIdSuccess]:(_, {payload}) => payload.result,
    }),
    getUserDeliveryInfoById:createReducer({}, {
        [userPrivateActions.getUserDeliveryInfoByIdSuccess]:(_, {payload}) => payload.result,
    }),
    getUserBraintreeToken:createReducer({}, {
        [userPrivateActions.getUserBraintreeTokenSuccess]:(_, {payload}) => payload.result,
    }),
    userTransactionSale:createReducer(0, {
        [userPrivateActions.setErrorCode]:(_, {payload}) => payload,
        [userPrivateActions.getUserBraintreeTokenRequest]:() => 0,
        [userPrivateActions.getUserBraintreeTokenSuccess]:() => 0,
        [userPrivateActions.getCartRequest]:() => 0,
        [userPrivateActions.getCartSuccess]:() => 0,

        [userPrivateActions.userTransactionSaleSuccess]:(_, {payload}) => payload.err_code,
    }),
    userTransactionPaypal:createReducer(0, {
        [userPrivateActions.setErrorCode]:(_, {payload}) => payload,
        [userPrivateActions.getUserPaypalClientIdRequest]:() => 0,
        [userPrivateActions.getUserPaypalClientIdSuccess]:() => 0,
        [userPrivateActions.getCartRequest]:() => 0,
        [userPrivateActions.getCartSuccess]:() => 0,
        [userPrivateActions.userTransactionPaypalSuccess]:(_, {payload}) => payload.err_code,
        // [userPrivateActions.userTransactionPaypalSuccess]:(_, {payload}) => payload.result,
    }),

    getUserPaypalClientId:createReducer('', {
        [userPrivateActions.getUserPaypalClientIdSuccess]:(_, {payload}) => payload.result,
    }),


    logoutUser:createReducer(-1, {
        [authActions.registerSuccess]:() => -1,
        [authActions.loginSuccess]:() => -1,
        [userPrivateActions.logoutUserRequest]:() => -1,

        [userPrivateActions.logoutUserSuccess]:(_, {payload}) => payload.err_code,
    }),

    getUserSquareupSettings:createReducer({}, {
        [userPrivateActions.getUserSquareupSettingsSuccess]:(_, {payload}) => payload.result,
    }),

    userTransactionSquareup:createReducer(0, {
        [userPrivateActions.setErrorCode]:(_, {payload}) => payload,
        [userPrivateActions.getCartRequest]:() => 0,
        [userPrivateActions.getCartSuccess]:() => 0,
        [userPrivateActions.getUserSquareupSettingsRequest]:() => 0,
        [userPrivateActions.getUserSquareupSettingsSuccess]:() => 0,
        [userPrivateActions.userTransactionSquareupSuccess]:(_, {payload}) => payload.err_code,
        // [userPrivateActions.userTransactionPaypalSuccess]:(_, {payload}) => payload.result,
    }),

    getUserStripToken:createReducer('', {
        [userPrivateActions.getUserStripTokenSuccess]:(_, {payload}) => payload.result,
    }),

    getUserStripClientSecret:createReducer('', {
        [userPrivateActions.getUserStripClientSecretSuccess]:(_, {payload}) => payload.result,
    }),

    userTransactionStrip:createReducer(0, {
        [userPrivateActions.setErrorCode]:(_, {payload}) => payload,
        [userPrivateActions.getCartRequest]:() => 0,
        [userPrivateActions.getCartSuccess]:() => 0,
        // [userPrivateActions.getUserSquareupSettingsRequest]:() => 0,
        // [userPrivateActions.getUserSquareupSettingsSuccess]:() => 0,
        [userPrivateActions.userTransactionStripSuccess]:(_, {payload}) => payload.err_code,
        // [userPrivateActions.userTransactionPaypalSuccess]:(_, {payload}) => payload.result,
    }),

    applyDiscountProduct:createReducer({}, {
        [userPrivateActions.applyDiscountProductSuccess]:(_, {payload}) => payload,
    }),
})