import { connect } from "react-redux";
import { userPrivateOperations } from "../../redux/userPrivate";
import Sidebar from "../Sidebar";
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'
import { useEffect, useState } from "react";
import moment from "moment";
import PopupOrder from "./PopupOrder";
import PopupInfoDelivery from "./PopupInfoDelivery";


const Order = ({
    lang,
    onGetUserOrdersHistory,
    getUserOrdersHistory
}) => {

    const [popupOrder, setPopupOrder] = useState(false);
    const [popupInfoDelivery, setPopupInfoDelivery] = useState(false);
    const [orderId, setOrderId] = useState(0)
    const [currentRate, setCurrentRate] = useState('')

    useEffect(()=>{
        onGetUserOrdersHistory({DOMAIN, DEFAULT_COLUMN:lang})
    }, [])

    return (
        <>
        <div className="container">

            <div className="text-center py-5">
                <h1>Order</h1>
            </div>

            <div className="row mobile-view">
                <Sidebar/>
                <div className="pl-3 col-md-10 form-parent">

                    {
                        getUserOrdersHistory.map(({
                            ID_ORDER,
                            CREATE_DATE,
                            ID_PAYMENT_SYSTEM,
                            PAYMENT_SYSTEMS,
                            AMOUNT,
                            DISCOUNT,
                            TOTAL_TO_PAY,
                            PRODUCTS_QTY,
                            DELIVERY_STATUS,
                            ORDER_STATUS,
                            LOYALTY_CASHBACK,
                            LOYALTY_DISCOUNT
                        }, k)=>{
                            const getPayment = PAYMENT_SYSTEMS.find(({ID_RECORD})=> ID_RECORD===ID_PAYMENT_SYSTEM)
                            const CURRENCY_SHORT_NAME = getPayment?.CURRENCY_SHORT_NAME
                            const paymentName =getPayment?.NAME

                            const amount = AMOUNT.find(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).PRICE
                            const discount = DISCOUNT.find(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).PRICE
                            const totalToPay = TOTAL_TO_PAY.find(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).PRICE
                            const loyaltyDiscountValue = LOYALTY_DISCOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === CURRENCY_SHORT_NAME)?.VALUE
                            const loyaltyDiscountPoint = LOYALTY_DISCOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN')?.VALUE

                            const pointsCashbackValue = LOYALTY_CASHBACK?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === CURRENCY_SHORT_NAME)?.VALUE
                            const pointsCashbackPoint = LOYALTY_CASHBACK?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN')?.VALUE

                            return(<div key={k} className="order mb-3">
                            <div className="order-header d-flex" style={{justifyContent:'space-between'}}>
                                <div className="d-flex" >
                                    <div className="mr-3">
                                        <div className="f-weight-5">Create Date</div>
                                        <div>{moment.unix(CREATE_DATE).format('DD MMM YYYY HH:mm')}</div>
                                    </div>
                                    <div className="mr-3">
                                        <div className="f-weight-5">Order amount</div>
                                        <div> {CURRENCY_SHORT_NAME} {Number(amount.toFixed(2))}</div>
                                    </div>
                                    <div className="mr-3">
                                        <div className="f-weight-5">Discount</div>
                                        <div> {CURRENCY_SHORT_NAME} { Number(discount.toFixed(2))}</div>
                                    </div>
                                    <div className="mr-3">
                                        <div className="f-weight-5">Total to pay</div>
                                        <div> {CURRENCY_SHORT_NAME} { Number(totalToPay.toFixed(2))}</div>
                                    </div>

                                    <div className="mr-3">
                                        <div className="f-weight-5">Loyalty discount</div>
                                        <div> {CURRENCY_SHORT_NAME} { Number(loyaltyDiscountValue.toFixed(2))} (Points {Number(loyaltyDiscountPoint.toFixed(2))})</div>
                                    </div>
                                    <div className="mr-3">
                                        <div className="f-weight-5">Cashback</div>
                                        <div> {CURRENCY_SHORT_NAME} { Number(pointsCashbackValue.toFixed(2))} (Points {Number(pointsCashbackPoint.toFixed(2))})</div>
                                    </div>
                                </div>
                                <div  className="f-weight-5">Order#{ID_ORDER}</div>
                            </div>
                            <div className="order-body">
                                <div className="d-flex" style={{justifyContent:'space-between'}}>
                                        <div className="d-flex" >
                                            <div className="mr-3 infoStatus">Products QTY: <span>{PRODUCTS_QTY}</span> <span className="pointer" onClick={()=>{setOrderId(ID_ORDER); setCurrentRate(CURRENCY_SHORT_NAME); setPopupOrder(true)}}>(see products)</span></div>
                                        </div>
                                        <div>
                                        {ORDER_STATUS === 0 &&  <span className="statusOrder">In progress</span>}
                                        {ORDER_STATUS === 1 &&  <span className="statusOrder">Not paid</span>}
                                        {ORDER_STATUS === 2 &&  <span className="statusOrder" style={{backgroundColor:'green'}}>Paid</span>}
                                        {ORDER_STATUS === 6 &&  <span className="statusOrder">Need confirmation</span>}
                                        {ORDER_STATUS === 7 &&  <span className="statusOrder">Canceled by user</span>}
                                        {ORDER_STATUS === 8 &&  <span className="statusOrder">Expired</span>}
                                        {ORDER_STATUS === 9 &&  <span className="statusOrder">Refunded</span>}
                                            
                                        </div>
                                </div>
                                <div className="d-flex pt-2">
                                    <div className="mr-3 infoStatus">Payment method: <span>{paymentName}</span></div>
                                    <div className="mr-3 infoStatus">Delivery status: {' '}
                                        
                                        {DELIVERY_STATUS === 0 && <span >New order</span>}
                                        {DELIVERY_STATUS === 1 && <span >Packing</span>}
                                        {DELIVERY_STATUS === 2 && <span className="pointer" onClick={()=>{setOrderId(ID_ORDER); setCurrentRate(CURRENCY_SHORT_NAME); setPopupInfoDelivery(true)}}>On delivery</span>}
                                        {DELIVERY_STATUS === 3 && <span className="pointer" onClick={()=>{setOrderId(ID_ORDER); setCurrentRate(CURRENCY_SHORT_NAME); setPopupInfoDelivery(true)}}>Delivered</span>}

                                        
                                    </div>
                                    <div className="mr-3 infoStatus">Order status: {' '}
                                    <span>
                                        {ORDER_STATUS === 0 && 'In progress'}
                                        {ORDER_STATUS === 1 && 'Not paid'}
                                        {ORDER_STATUS === 2 && 'Paid'}
                                        {ORDER_STATUS === 6 && 'Need confirmation'}
                                        {ORDER_STATUS === 7 && 'Canceled by user'}
                                        {ORDER_STATUS === 8 && 'Expired'}
                                        {ORDER_STATUS === 9 && 'Refunded'}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        })
                    }
                
            
                </div>
            </div>
        </div>
        {popupOrder && <PopupOrder orderId={orderId} currentRate={currentRate} closePopup={()=>setPopupOrder(false)} />}
        {popupInfoDelivery && <PopupInfoDelivery orderId={orderId} currentRate={currentRate} closePopup={()=>setPopupInfoDelivery(false)}  /> }
        </>
    )
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    // orderId:state.userPrivate.orderId,
    getUserOrdersHistory:state.userPrivate.getUserOrdersHistory,

})

const mapDispatchToProps = {
    // onProceedToCheckout:userPrivateOperations.proceedToCheckout,
    onGetUserOrdersHistory:userPrivateOperations.getUserOrdersHistory,
}
export default connect(mapStateToProps, mapDispatchToProps)(Order);