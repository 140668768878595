import { connect, useDispatch } from 'react-redux'
import {authActions} from "../../redux/auth";
import alertify from "alertifyjs";
import { userPrivateActions, userPrivateOperations } from '../../redux/userPrivate';
// import {DOMAIN} from './../../config/Url'


const Logout = ({
                    history,
                    onLogoutSuccess,
                    onGetCartClear
                }) => {

    // const dispatch = useDispatch()
    const sendLogout = async () => {
        onGetCartClear({})
        onLogoutSuccess()
        
        // dispatch(authActions.logoutSuccess());
        history.push(`/`)

    }
    return (
        <div className="text-center">
            <button onClick={() => sendLogout()} className="accnt-btn mt-5">Logout</button>
        </div>

    )
}
const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    onGetCart:userPrivateOperations.getCart,
    onLogoutSuccess:authActions.logoutSuccess,
    onGetCartClear:userPrivateActions.getCartClear
}
export default connect(mapStateToProps, mapDispatchToProps)(Logout);