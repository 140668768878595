import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {authActions, authSelectors} from "../../redux/auth";
import {userOperations} from "../../redux/user";

const ProtectedRoute = ({
                            path,
                            component: Component,
                            render,
                            Login,
                            getIsAuthenticated,
                            ...rest
                        }) => {
    return (
        <Route
            path={path}
            {...rest}
            render={(props) => {
                if (getIsAuthenticated ) {
                    console.log('here is prop==========')
                    return Component ? <Component {...props} /> : render(props);
                }
                return (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                );
            }}
        />
    );
};

const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    currencyList:state.user.currencyList,
    getCatalogsList:state.user.getCatalogsList,
    rate:state.auth.rate,
    groupName:state.auth.groupName,
    getIsAuthenticated:authSelectors.getIsAuthenticated(state),
})

const mapDispatchToProps = {
    onCurrencyList:userOperations.currencyList,
    onGetCatalogsList:userOperations.getCatalogsList,
    setRate:authActions.rate,
    onGetProducts:userOperations.getProducts,
    setGroupName:authActions.groupName
}

export default connect(mapStateToProps,mapDispatchToProps)(ProtectedRoute)