import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { authActions } from "../../redux/auth";
import { userPrivateActions, userPrivateOperations } from "../../redux/userPrivate";
import {DOMAIN} from './../../config/Url'

const Sidebar = ({
    onLogoutUser,
    errCode,
    lang,
    onLogoutSuccess,
    logoutUser,
    onGetCartClear
}) => {

    const history = useHistory();

    const logout = () => {
        onLogoutUser({DOMAIN, DEFAULT_COLUMN:lang})
    }

    useEffect(()=>{
        if(logoutUser === 1){
            onGetCartClear({})
            onLogoutSuccess()
            history.push(`/`)
        }
    }, [logoutUser])

    return (
        <div className="col-md-2">
            {/* <div className="border-top">

                  <Link to="/dashboard">  <button type="button" className="bg-transparent border-0 py-2">Dashboard</button></Link>

                <i className="fa fa-tachometer float-right mt-2"></i>
            </div> */}

            <div className="border-top">
             <Link to="/order">   <button type="button" className="bg-transparent border-0 py-2">Orders</button></Link>
                <i className="fa fa-shopping-bag float-right mt-2"></i>
            </div>


            <div className="border-top">
                <Link to="/address">  <button type="button" className="bg-transparent border-0 py-2">Addresses</button></Link>
                <i className="fa fa-map-marker float-right mt-2"></i>
            </div>

            <div className="border-top">
                 <Link to="/account">   <button type="button" className="bg-transparent border-0 py-2">Account details</button></Link>
                <i className="fa fa-user float-right mt-2"></i>

                </div>

            <div className="border-top border-bottom" onClick={()=>logout()}>

                <button type="button" className="bg-transparent border-0 py-2">Logout</button>
                <i className="fa fa-sign-out float-right mt-2"></i>

            </div>


        </div>
    )
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    errCode:state.userPrivate.err_code,
    logoutUser:state.userPrivate.logoutUser,
})

const mapDispatchToProps = {
    onLogoutUser:userPrivateOperations.logoutUser,
    onGetCart:userPrivateOperations.getCart,
    onLogoutSuccess:authActions.logoutSuccess,
    onGetCartClear:userPrivateActions.getCartClear
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
