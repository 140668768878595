import { Base64 } from "js-base64";
import { useCallback, useState } from "react";
import { useEffect } from "react"
import { connect } from "react-redux";
import { useRouteMatch } from "react-router";
import { Link} from "react-router-dom";
import update from 'immutability-helper'

import {DOMAIN, WEB_PICTURES_FILE_RESIZER, WEB_PICTURES_FILE, ADMIN_URL} from '../../config/Url'
import noImage from './../../assets/img/no_image.png' 
import { userOperations } from "../../redux/user";
import RecordsOption from "../../components/RecordsOption/RecordsOption";
// import ImageZoom from "../../components/ImageZoom/ImageZoom";
import ReactImageZoom from 'react-image-zoom';
import Stars from "../../components/Stars/Stars";
import PopupLoginRegister from "../../components/PopupLoginRegister/PopupLoginRegister";
import { authSelectors } from "../../redux/auth";
import userPrivateOperations from "../../redux/userPrivate/user-private-operations";
import { mockComponent } from "react-dom/test-utils";
import moment from "moment";
// import Modal from './../../components/Modal' ;

import Lightbox from 'react-image-lightbox';
// const intial_product_count = {
//     count:0
// }

import Button from "../../components/Button/Button";

const INITIAL_STATE_COUNT = 1;


const ProductView = ({
    lang,
    onGetProductById,
    getProductById,
    rate,
    history,
    onGetProductByPricingParameters,
    getProductByPricingParameters,
    getIsAuthenticated,
    onCreateFeedback,
    onGetFeedbackList,
    getFeedbackList,
    errCode,
    onCreateFeedbackLike,
    onCreateFeedbackDislike,
    onGetAddProductToCart,
    getAddProductToCart,
    orderId:ID_ORDER,
    onGetCart,
    getCart,
    initWebSait
}) => {

    const [productCount, setProductCount] = useState(INITIAL_STATE_COUNT)
    
    const [showZoomImage, setShowZoomImage] = useState(false);

    const match = useRouteMatch()

    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [group1, setGroup1] = useState({})
    const [group2, setGroup2] = useState({})
    const [group3, setGroup3] = useState({})
    const [group4, setGroup4] = useState({})
    // const [group5, setGroup5] = useState({})
    const [categories, setCategories] = useState([])
    const [images, setImages] = useState([])
    const [images1, setImages1] = useState([])
    const [imageId, setImageId] = useState(0)
    const [totalPrice, setTotalPrice] = useState([])
    const [measure, setMeasure] = useState({});
    const [priceOptions, setPriceOptions] = useState([])
    const [price, setPrice] = useState([]);
    const [procentOff, setProcentOff] = useState(0)

    const [view, setView] = useState(0)
    const [middelaRate, setMiddelaRate] = useState(0)

    const [selectPricingOptions, setSelectPricingOptions] = useState([])

    const [arrCards, setArrCards] = useState([])
    const [itemId, setItemId] = useState(0)

    const [stars, setStars] = useState(0)
    const [showWritePreview, setShowWritePreview] = useState(false);
    const [show, setShow] = useState(false)
    const [text, setText] = useState('')
    const [flag, setFlag] = useState(0)

    const [srcImages, setSrcImages] = useState([]);
    const [modalImg, setModalImg] = useState(false);
    const [imgId, setImgId] = useState(0);

    const [isProductCart, setIsProductCart] = useState(false);
    const [productCart, setProductCart] = useState({});

 
    useEffect(()=>{
        // console.log(match);
        const id = Number(match?.params?.id)
        setItemId(id)

        // imageZoom("myimage", "myresult");

        if(id){
            onGetProductById({DOMAIN, ID_ITEM:id, DEFAULT_COLUMN:lang})
            resetFeedBack()
        }

    }, [])

    useEffect(()=>{
        if(errCode === 1){
            resetFeedBack()
            setShowWritePreview(false)
        }
    }, [errCode])

    useEffect(()=>{
        if(Object.keys(getCart).length !== 0){
            const id = Number(match?.params?.id)
            const { PRODUCTS } = getCart
            const product = PRODUCTS.filter(({ID_ITEM}) => ID_ITEM=== id)

            setIsProductCart(product.length === 0 ? false : true)
            setProductCart(product.length === 0 ? {} : product[0])
            setProductCount(product.length === 0 ? INITIAL_STATE_COUNT : product[0]?.QTY)
        }
    }, [getCart])


    useEffect(()=>{
        console.log(getProductById);
        if(Object.keys(getProductById).length !== 0){
            const {
                NAME,
                USE_DESCRIPTION,
                ATTRIBUTES_GROUP_1,
                ATTRIBUTES_GROUP_2,
                ATTRIBUTES_GROUP_3,
                ATTRIBUTES_GROUP_4,
                ATTRIBUTES_GROUP_5,
                ATTRIBUTES_GROUP_6,
                ATTRIBUTES_GROUP_7,
                CATEGORIES,
                IMAGES:{GALLERY_MAIN, GALLERY_1},
                TOTAL_PRICE,
                MEASURE,
                PRICING_OPTIONS,
                MIDDLE_RATE, 
                REVIEWS_QTY,
                PERCENT_OFF,
                PRICE,
            } = getProductById

            setProcentOff(PERCENT_OFF)
            setPrice(PRICE)

            setTitle(Base64.decode(NAME))
            setDesc(Base64.decode(USE_DESCRIPTION))
            setGroup1(ATTRIBUTES_GROUP_1)
            setGroup2(ATTRIBUTES_GROUP_2)
            setGroup3(ATTRIBUTES_GROUP_3)
            setGroup4(ATTRIBUTES_GROUP_4)
            // setGroup5(ATTRIBUTES_GROUP_5)
            setCategories(CATEGORIES)
            setImages(GALLERY_MAIN)
            setImages1(GALLERY_1)

            

            setSrcImages([...GALLERY_1.map(({IMAGE}) => (`${WEB_PICTURES_FILE}${IMAGE}`))])

            setTotalPrice(TOTAL_PRICE)
            setMeasure(MEASURE)
            setPriceOptions(PRICING_OPTIONS)

            const arrPO = PRICING_OPTIONS.map(({ID_WIDGET, RECORDS_WIDGET})=>{
                const ID_RECORD = RECORDS_WIDGET[0]?.ID_RECORD ? RECORDS_WIDGET[0]?.ID_RECORD : 0;
                return({ID_WIDGET, ID_RECORD})
            })
            setSelectPricingOptions(arrPO)

            setImageId(!GALLERY_MAIN[0]?.ID_IMAGE ? 0 : GALLERY_MAIN[0].ID_IMAGE)

            setView(REVIEWS_QTY)
            setMiddelaRate(MIDDLE_RATE)

            let arrGroup = []
            const ATTRIBUTES_GROUP = [{...ATTRIBUTES_GROUP_1}, 
                {...ATTRIBUTES_GROUP_2}, 
                {...ATTRIBUTES_GROUP_3}, 
                {...ATTRIBUTES_GROUP_4}, 
                {...ATTRIBUTES_GROUP_5}, 
                {...ATTRIBUTES_GROUP_6}, 
                {...ATTRIBUTES_GROUP_7}]

            ATTRIBUTES_GROUP.filter(({IS_GROUPED}) => IS_GROUPED === 1).forEach(({NAME, WIDGETS}) => {
                    arrGroup.push({
                        nameColumn:Base64.decode(NAME),
                        WIDGETS
                    })
                });

                setArrCards(arrGroup)

        }
    }, [getProductById, rate])

    useEffect(()=>{
        // console.log({showZoomImage});
        const isimgZoomLens = document.querySelector('.img-zoom-lens');
        if(isimgZoomLens){
            if(showZoomImage)
                isimgZoomLens.classList.add('show')
            else    
                isimgZoomLens.classList.remove('show') 
        }
    }, [showZoomImage])

    useEffect(()=>{
        if(Object.keys(getAddProductToCart).length !== 0){
            onGetCart({DOMAIN})
        }
    }, [getAddProductToCart])

    
    const decrease = () => {
        // setFlagImage(1)
        if(productCount > 1){
            setProductCount(productCount - 1)
        }
    }
    const increase = () => {
        // setFlagImage(1)
        setProductCount(productCount + 1)
    }


    const handlePricingOptions = ({target:{name, value}}) => {
        const index = selectPricingOptions.findIndex(({ID_WIDGET}) => Number(name) === ID_WIDGET)
        const getArray = update(selectPricingOptions, {[index]:{ID_RECORD:{$set:Number(value)}}})
        // setSelectPricingOptions([...getArray])
        const id = Number(match?.params?.id)

        onGetProductByPricingParameters({
            DOMAIN,
            ID_ITEM:id,
            DEFAULT_COLUMN:lang,
            PRICING_PROPERTIES:[...getArray.map(({ID_RECORD}) => ({ID_RECORD}))]
        })
    
    }


    const addToCart = () => {
        setFlag(2)
        if(getIsAuthenticated){
            const id = Number(match?.params?.id)
            onGetAddProductToCart({
                DOMAIN,
                DEFAULT_COLUMN:lang,
                ID_ITEM:id,
                QTY:productCount,
                ID_ORDER
            })
        }else
            setShow(true)
    }

    const openWritePreview = () =>{
        setFlag(0)
        if(getIsAuthenticated)
            setShowWritePreview(true)
        else
            setShow(true)
    }

    const clickLike = useCallback(({ID_FEEDBACK, TYPE}) =>{
        if(getIsAuthenticated){
            if(TYPE === 1){
                onCreateFeedbackLike({DOMAIN, ID_FEEDBACK})
            }else if(TYPE === 0){
                onCreateFeedbackDislike({DOMAIN, ID_FEEDBACK})
            }
        }else
            setShow(true)

        setFlag(1)

    }, [getIsAuthenticated])

    const closeAuth = ({status}) =>{
        setShow(false);
        if(status!==0 && flag === 0){
            setShowWritePreview(true)
        }  
        
    }

    const submitFeedback = () => {

        const id = Number(match?.params?.id)
        onCreateFeedback({
            DOMAIN,
            ID_ITEM:id,
            COMMENT:Base64.encode(text),
            RATE:stars
        })
    }

    const resetFeedBack = () => {
        const id = Number(match?.params?.id)
        onGetFeedbackList({
            DOMAIN,
            ID_ITEM:id,
            PAGE:0,
            SIZE:50
        })
    }

    const openImg = (id) =>{
        setModalImg(true)
        setImgId(id)
    }


    const imageZoom = (imgID, resultID) => {
        var img, lens, result, cx, cy;
        img = document.getElementById(imgID);
        result = document.getElementById(resultID);

        const isimgZoomLens = document.querySelector('.img-zoom-lens');
        if(isimgZoomLens) isimgZoomLens.remove()

        /*create lens:*/
        lens = document.createElement("DIV");
        lens.setAttribute("class", "img-zoom-lens");

        console.log({img});
        if(!img) return;
        /*insert lens:*/
        img.parentElement.insertBefore(lens, img);
        /*calculate the ratio between result DIV and lens:*/
        cx = result.offsetWidth / lens.offsetWidth;
        cy = result.offsetHeight / lens.offsetHeight;
        /*set background properties for the result DIV:*/
        result.style.backgroundImage = "url('" + img.src + "')";
        result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
        /*execute a function when someone moves the cursor over the image, or the lens:*/
        lens.addEventListener("mousemove", moveLens);
        img.addEventListener("mousemove", moveLens);
        /*and also for touch screens:*/
        lens.addEventListener("touchmove", moveLens);
        img.addEventListener("touchmove", moveLens);
        function moveLens(e){
          var pos, x, y;
          /*prevent any other actions that may occur when moving over the image:*/
          e.preventDefault();
          /*get the cursor's x and y positions:*/
          pos = getCursorPos(e);
          /*calculate the position of the lens:*/
          x = pos.x - (lens.offsetWidth / 2);
          y = pos.y - (lens.offsetHeight / 2);
          /*prevent the lens from being positioned outside the image:*/
          if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
          if (x < 0) {x = 0;}
          if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
          if (y < 0) {y = 0;}
          /*set the position of the lens:*/
          lens.style.left = x + "px";
          lens.style.top = y + "px";
          /*display what the lens "sees":*/
          result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
        }
        function getCursorPos(e) {
          var a, x = 0, y = 0;
          e = e || window.event;
          /*get the x and y positions of the image:*/
          a = img.getBoundingClientRect();
          /*calculate the cursor's x and y coordinates, relative to the image:*/
          x = e.pageX - a.left;
          y = e.pageY - a.top;
          /*consider any page scrolling:*/
          x = x - window.pageXOffset;
          y = y - window.pageYOffset;
          return {x : x, y : y};
        }
      }


    return (
        <>
            {/* <div className="pt-5 pb-4">
                <span>Home / Legal Cannabis / CBD Flowers / Indiana</span>
            </div> */}
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <div className="zoomImagg"  style={{position: 'relative'}}>
                                {initWebSait?.PRODUCT_LIST_TYPE === 0 && <svg style={{ position: 'absolute', top: '-59px', left: '50%', transform: 'translate(-50%)'}} width="476" height="469" viewBox="0 0 476 469" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M367.996 418.153C368.229 430.385 359.165 439.904 347.156 439.999C336.035 440.094 326.737 431.147 326.036 419.724C325.335 408.206 334.914 397.592 346.455 397.021C358.37 396.498 367.762 405.684 367.996 418.153Z" fill="#5782A2"/>
                                    <path d="M71.9917 128.504C71.6584 140.14 61.4213 149.449 49.5653 148.983C37.6616 148.471 28.2815 138.325 29.0433 126.735C29.7576 115.658 39.3281 106.908 50.6128 107.001C62.5165 107.094 72.3251 116.915 71.9917 128.504Z" fill="#915674"/>
                                    <path d="M71.9998 341.022C71.9522 352.834 62.1459 362.228 50.0548 361.996C37.8208 361.763 28.5859 352.183 29.0143 340.185C29.4427 328.838 38.4873 320.188 50.1501 320.002C62.3364 319.862 72.0474 329.21 71.9998 341.022Z" fill="#CA9E71"/>
                                    <path d="M152.998 418.599C152.807 431.078 143.193 440.328 130.662 439.991C118.801 439.654 109.952 430.499 110 418.647C110.048 406.457 120.236 396.58 132.289 397.014C143.767 397.399 153.142 407.228 152.998 418.599Z" fill="#8DA56C"/>
                                    <path d="M369 49.7932C369.048 61.9633 359.498 71.1844 347.021 70.9972C335.791 70.81 326.145 61.1675 326.002 50.1209C325.858 38.3253 336.079 28.636 348.221 29.0105C359.978 29.385 368.952 38.3721 369 49.7932Z" fill="#8DA56C"/>
                                    <path d="M21.5958 213C33.6757 213.047 43.1874 222.565 42.9972 234.324C42.807 245.988 32.8196 255.274 20.7872 254.994C9.80118 254.761 0.241916 245.242 0.00412265 234.324C-0.233671 222.939 9.84881 213 21.5958 213Z" fill="#B96B83"/>
                                    <path d="M475.993 234.387C475.659 246.456 465.973 255.449 453.757 254.983C442.783 254.61 433.096 244.918 433.001 234.201C432.905 222.366 442.544 212.954 454.712 213C467.07 213.093 476.28 222.366 475.993 234.387Z" fill="#BA6C84"/>
                                    <path d="M425.734 361.996C413.403 361.763 403.666 352.038 404.009 340.359C404.351 328.633 414.676 319.466 426.957 320.024C438.505 320.536 447.851 329.796 447.997 340.824C448.193 352.271 437.722 362.229 425.734 361.996Z" fill="#915674"/>
                                    <path d="M130.623 70.9985C119.513 70.8583 110.191 61.47 110.003 50.2601C109.814 38.3496 119.607 28.6811 131.565 29.0081C143.193 29.335 151.856 38.0694 151.997 49.6529C152.186 61.7036 142.77 71.1386 130.623 70.9985Z" fill="#5481A1"/>
                                    <path d="M425.689 148.992C413.176 148.619 403.669 139.057 404.009 127.349C404.397 115.641 414.582 106.638 426.998 107.011C438.057 107.338 447.999 117.18 447.999 127.908C448.096 139.197 437.378 149.319 425.689 148.992Z" fill="#C99D70"/>
                                    <path d="M259 20.9745C259 33.5545 250.52 41.4804 237.561 41.9873C225.65 42.4481 215.407 30.2828 216.027 20.3293C216.694 9.50037 227.175 -0.314831 237.466 0.00773472C250.377 0.422461 259 8.44051 259 20.9745Z" fill="#76ABC4"/>
                                    <path d="M238.804 426.073C251.374 426.168 259.803 434.27 259.998 446.446C260.144 459.475 251.423 468.856 238.95 468.999C227.159 469.141 215.855 458.575 216.001 447.441C216.099 438.297 225.405 424.889 238.804 426.073Z" fill="#76ABC4"/>
                                </svg>}
                                
                                {/* <img src="assets/images/secong-product-page.png" className="second-product-img" /> */}
                                {
                                    images.filter(({ID_IMAGE})=>ID_IMAGE===imageId).map(({IMAGE, ID_IMAGE}, k)=>{
                                        
                                        // const imagezoom = {width: 350, height:350,  zoomWidth: 500, img: `${WEB_PICTURES_FILE_RESIZER}${IMAGE}/1400`};
                                        
                                        // if(flagImage === 0)
                                        return(<>
                                            {/* <img src={ `${WEB_PICTURES_FILE_RESIZER}${IMAGE}/1400` } width="400px" />
                                            <ReactImageZoom key={k} {...imagezoom} /> */}

                                                <div className="img-zoom-container">
                                                    <div onMouseEnter={()=>setShowZoomImage(true)}
                                                            onMouseLeave={()=>setShowZoomImage(false)}>
                                                        <img 
                                                            onLoad={()=>imageZoom("myimage", "myresult")} 
                                                            id="myimage" src={ `${WEB_PICTURES_FILE_RESIZER}${IMAGE}/1400` } 
                                                            width="350" height="350" 
                                                            style={{borderRadius:initWebSait?.PRODUCT_LIST_TYPE === 1 ? 0 : '100%'}}
                                                        />
                                                    </div>
                                                    <div id="myresult" className={["img-zoom-result", showZoomImage ? 'show' : '' ].join(' ') }></div>
                                                </div>
                                            </>)
                                        
                                    })
                                }
                            </div>

                            <div className="d-flex w-100 pt-3 " style={{flexWrap: 'wrap', marginTop: initWebSait?.PRODUCT_LIST_TYPE === 1 ? 0 : '77px',  justifyContent: 'center'}}>
                                {
                                    images.map(({IMAGE, ID_IMAGE}, k)=>(
                                        <div key={k} className="px-2 ">
                                            <button className="border-0 bg-transparent select-img-btn" onClick={()=>{ setImageId(ID_IMAGE)}}>
                                                <div className="bottom-image" style={{backgroundImage:`url(${WEB_PICTURES_FILE_RESIZER}${IMAGE}/70)`, borderRadius:initWebSait?.PRODUCT_LIST_TYPE === 1 ? 0 : '100%', width:70, height:70}}></div>
                    
                                            </button>
                                        </div>
                                    ))
                                }
          
                            </div>
    
                        </div>
                    </div>
                    <div className="col-md-6 pt-5">
                        <div>
                            <div className="border-bottom pb-4 d-flex" style={{justifyContent: 'space-between'}}>
                                <div>
                                    <div>
                                        <h3 className="text-theme font-weight-bolder" style={{position:'relative'}}>
                                            {/* <div className="admin_click"><a href={`${ADMIN_URL}?editCode=4&itemId=${itemId}`} target="_blank">Edit</a></div> */}
                                            {categories.map(({INFO_RECORD})=>Base64.decode(INFO_RECORD)).join(', ')}
                                        </h3>
                                    </div>
                                    <div>
                                        <h1 style={{position:'relative', fontSize: title.length >= 18 ? (group2.WIDGETS.length === 0 ? 40 : 20) : 40}}>
                                            {title}
                                        </h1>
                                    </div>
                                    <div >
                                        <span>
                                            {
                                                Array.from({length:5}).map((i , k)=>{
                                                    return(<i key={k} className={['fa', middelaRate >= k+1 ? 'fa-star' : 'fa-star-o'].join(' ')}  ></i>)
                                                })
                                            }
                                             {' '}{view} reviews
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div className=" d-flex" style={{position:'relative'}}>
                                    {/* <div className="admin_click"><a href={`${ADMIN_URL}?editCode=1&itemId=${itemId}&typeId=${group2.TYPE_ID}&isGrouped=${group2.IS_GROUPED}`} target="_blank">Edit</a></div> */}
                                            {
                                                group2.WIDGETS?.map(({RECORDS_WIDGET, INFO_WIDGET, TYPE_WIDGET, MEASURE:{ID, NAME}}, k)=>{
                                                    // if(RECORDS_WIDGET.length !== 0)
                                                    return(<div key={k} className="px-3">
                                                                <div style={{textAlign:'center'}} className="font-weight-bolder font-size-custom">{Base64.decode(INFO_WIDGET)}</div>
                                                                <div className="circle-second-product">
                                                                    <p className="text-center text-white font-weight-bolder pt-4 pl-1">{TYPE_WIDGET === 1 ? RECORDS_WIDGET.map(({VALUE})=>{return(VALUE)}).join(', ') : (RECORDS_WIDGET.length === 0 ? '-' : RECORDS_WIDGET.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', '))} {ID !== 0 && Base64.decode(NAME)}</p>
                                                                </div>
                                                            </div>)
                                                })
                                            }
                                    </div>
                                </div>
                            </div>

                            <p className="pt-2" style={{position:'relative'}} dangerouslySetInnerHTML={{__html: desc}}>
                                {/* <div className="admin_click"><a href={`${ADMIN_URL}?editCode=2&itemId=${itemId}`} target="_blank">Edit</a></div> */}
                                {/* {desc} */}
                            </p>
                            <div style={{position:'relative'}}>
                                {/* <div className="admin_click"><a href={`${ADMIN_URL}?editCode=1&itemId=${itemId}&typeId=${group1.TYPE_ID}&isGrouped=${group1.IS_GROUPED}`} target="_blank">Edit</a></div>           */}
                                {
                                    group1.WIDGETS?.map(({RECORDS_WIDGET, INFO_WIDGET, TYPE_WIDGET, MEASURE:{ID, NAME}}, k)=>{
                                        // if(RECORDS_WIDGET.length !== 0)
                                        return(<div key={k} className="pb-4">
                                                    <div className="font-weight-bolder second-product-hdngs">{Base64.decode(INFO_WIDGET)} <span style={{fontWeight:100, fontSize:16}}>{ID !== 0 && `(${Base64.decode(NAME)})`}</span></div>
                                                    <div>{TYPE_WIDGET === 1 ? RECORDS_WIDGET.map(({VALUE})=>{return(VALUE)}).join(', ') : (RECORDS_WIDGET.length === 0 ? '-' : RECORDS_WIDGET.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', '))}</div>
                                                </div>)
                                    })
                                }
                            </div>

                            {priceOptions.length !== 0 && 
                                    <div >
                                        <div className="py-2 font-weight-normal">Select Pack Size</div>
                                        <div className="d-flex" style={{flexDirection:'column'}}>
                                            {
                                                priceOptions.map(({ID_WIDGET, IMAGE_WIDGET, INFO_WIDGET}, k)=>{
                                                    const index = selectPricingOptions.findIndex(({ID_WIDGET:id}) => id === ID_WIDGET)
                                                    const recordId = index === -1 ? 0 : selectPricingOptions[index].ID_RECORD
                                                    return(
                                                        <div key={k}>
                                                            <h6 className="flex">
                                                                {IMAGE_WIDGET !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE_WIDGET}/20`} style={{width:20, marginRight: 8}} />}
                                                            <b>{Base64.decode(INFO_WIDGET)}</b></h6>
                                                            <select name={ID_WIDGET} value={recordId} className="form-control" onChange={handlePricingOptions}>
                                                                <option value={0} disabled hidden></option>
                                                                <RecordsOption widgetId={ID_WIDGET} />
                                                            </select>
                                                            <br/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }

                                <div >
                                    <div className="border-bottom pb-4">
                                        <div className="d-flex">
                                            <div className="pb-4" style={{position:'relative'}}>
                                                <div>
                                                    <span className="font-weight-bolder heading-font">
                                                        {rate} {totalPrice.filter(({RATE_SHORT_NAME})=> RATE_SHORT_NAME === rate).map(({PRICE})=>{
                                                                const price = Number(PRICE.toFixed(2))
                                                                return(Number((price).toFixed(2)))
                                                            })}
                                                    </span> {' '}
                                                    {Object.keys(measure).length !== 0 && measure.ID !== 0 && `(per ${Base64.decode(measure.NAME)})`}
                                                </div>
                                                <div style={{color:'#915674'}}>
                                                    <span >TOKENS</span>
                                                    <span> {totalPrice.filter(({RATE_SHORT_NAME})=> RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>{
                                                                const price = Number(PRICE.toFixed(2))
                                                                return(Number((price).toFixed(2)))
                                                            })}</span>
                                                </div>
                                            </div>
                                            {procentOff !== 0 &&
                                                <div className="pb-4 ml-3" style={{position:'relative', color:'#4defe7'}}>
                                                    <div>
                                                        <span className="heading-font" style={{textDecoration: 'line-through'}}>
                                                            {rate} {price.filter(({RATE_SHORT_NAME})=> RATE_SHORT_NAME === rate).map(({PRICE})=>{
                                                                const price = Number(PRICE.toFixed(2))
                                                                return(price)
                                                            })}
                                                        </span> {' '}
                                                        {Object.keys(measure).length !== 0 && measure.ID !== 0 && `(per ${Base64.decode(measure.NAME)})`}
                                                    </div>
                                                    <div style={{color:'#915674'}}>
                                                        <span >TOKENS</span>
                                                        <span> {price.filter(({RATE_SHORT_NAME})=> RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>{
                                                                const price = Number(PRICE.toFixed(2))
                                                                return(price)
                                                            })}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        
                                        <div className="d-flex pb-4 display-block">
                                            <div className="plus-minus-div " style={{width:120}}>
                                                <i className="fa fa-minus  pointing" aria-hidden="true" onClick={() => decrease()}></i>
                                                <span className="plus-minus-size px-2 font-weight-bolder">{productCount}</span>
                                                <i className="fa fa-plus pointing " aria-hidden="true" onClick={() => increase()}></i>
                        
                                            </div>
                                            <div className="pb-4" style={{position:'relative', margin: '0 20px', top: -6}}>
                                                <div>
                                                    <span className="font-weight-bolder " style={{fontSize: 20}}>
                                                        Total {rate} {totalPrice.filter(({RATE_SHORT_NAME})=> RATE_SHORT_NAME === rate).map(({PRICE})=>{
                                                                const price = Number(PRICE.toFixed(2))
                                                                return(Number((price * productCount).toFixed(2)))
                                                            })}
                                                    </span>
                                                </div>
                                                <div style={{color:'#915674'}}>
                                                    <span >TOKENS</span>
                                                    <span> {totalPrice.filter(({RATE_SHORT_NAME})=> RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>{
                                                                const price = Number(PRICE.toFixed(2))
                                                                return(Number((price * productCount).toFixed(2)))
                                                            })}</span>
                                                </div>
                                            </div>
                                            <div>
                                                {/* {isProductCart && <Link to="/cart"><button className="long-cart-btn mx-3" style={{width: 'max-content', padding: '0 36px'}} >In Cart</button></Link>}
                                                {!isProductCart && <button className="long-cart-btn mx-3" style={{width: 'max-content', padding: '0 36px'}} onClick={()=>addToCart()} >Add to Cart</button>} */}
                                                {isProductCart && <Link to="/cart"><Button >In Cart</Button></Link>}
                                                {!isProductCart && <Button onClick={()=>addToCart()} >Add to Cart</Button>}
                                            
                                            </div>

                                            
                                        </div>
                                        
                                        {getProductById?.VENDOR_IS_MY === 0 &&
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <img src={ `${WEB_PICTURES_FILE_RESIZER}${getProductById?.VENDOR_IMAGE}/100` }  style={{width:70, height:70, objectFit:'cover', borderRadius:'100%'}} />
                                            <div style={{marginLeft:12, fontSize:18}}>{getProductById?.VENDOR_NAME}</div>
                                        </div>
                                        }
                                                
                                       
                        
                                    </div>
                      
                                    
                                </div>

                        </div>
                        
                    </div>
                </div>
    
               
            </div>

            {group3.WIDGETS?.length !== 0 && 
                        <div className="d-flex pb-5 mb-4 pt-5 mt-5  display-block" style={{flexWrap: "wrap", justifyContent: "center", position:'relative'}}>
                            {/* <div className="admin_click"><a href={`${ADMIN_URL}?editCode=1&itemId=${itemId}&typeId=${group3.TYPE_ID}&isGrouped=${group3.IS_GROUPED}`} target="_blank">Edit</a></div> */}
                        {/* // <div className="d-flex pb-5 mb-4 pt-5 mt-5 display-block"> */}
                                {
                                    group3.WIDGETS?.map(({RECORDS_WIDGET, INFO_WIDGET, TYPE_WIDGET, IMAGE_WIDGET, MEASURE:{ID, NAME}}, k)=>{
                                        // if(RECORDS_WIDGET.length !== 0)
                                        return(<div className="px-4 pb-3" key={k}>
                                                    <div className=" total-fancy-div">
                                                        <div className="fancy-pic-body">
                                                            {/* <img src="assets/images/fancy-one.png" className="fancy-pic" /> */}
                                                            {IMAGE_WIDGET !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE_WIDGET}/70`} className="fancy-pic" />}
                                                            {IMAGE_WIDGET === '' && <img src={noImage} className="fancy-pic" />}
                                                        </div>
                                                        <div className="fancy-img-inner-text-div">
                                                            <div>
                                                                <span>{Base64.decode(INFO_WIDGET)}</span>
                                                            </div>
                                                            <div>
                                                                <span className="color-mehroon font-weight-bolder">{TYPE_WIDGET === 1 ? RECORDS_WIDGET.map(({VALUE})=>{return(VALUE)}).join(', ') : (RECORDS_WIDGET.length === 0 ? '-' : RECORDS_WIDGET.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', '))} {ID !== 0 && Base64.decode(NAME)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                    })
                                }

                        </div>
                        }
            <div style={{display:'flex', position:'relative', marginTop:30}}>
                {/* <div className="admin_click"><a href={`${ADMIN_URL}?editCode=1&itemId=${itemId}&typeId=${5}&isGrouped=${1}`} target="_blank">Edit</a></div> */}
                {
                arrCards.filter(({WIDGETS}) => WIDGETS.length !== 0).map(({nameColumn, WIDGETS}, k)=>{
                    return(<div key={k} style={{margin:'0 8px', width:`${100/arrCards.filter(({WIDGETS}) => WIDGETS.length !== 0).length}%`}}>
                            <h5 style={{fontSize:13}}><b>{nameColumn}</b></h5>
                            {
                                WIDGETS.map(({INFO_WIDGET, IMAGE_WIDGET, ADDITIONAL_INFO_WIDGET, RECORDS_WIDGET, MEASURE:{ID, NAME}}, k)=>{

                                    const val = RECORDS_WIDGET.length === 0 ? 0 : RECORDS_WIDGET[0].VALUE
                                    return(<div key={k} className="groupbody">
                                        <div className="groupTitle"> 
                                            {IMAGE_WIDGET !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE_WIDGET}/20`} style={{width:20}} />}
                                            <span>{Base64.decode(INFO_WIDGET)}</span>
                                        </div>
                                        <div className="progressGroup">
                                            <div style={{width:val === 0 ? '20%' : `${val}%`}} />
                                            <span/>
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                        <small>{val !== 0 && val}{ID !== 0 && Base64.decode(NAME)} {ADDITIONAL_INFO_WIDGET !== '' && Base64.decode(ADDITIONAL_INFO_WIDGET)}</small>
                                    </div>)
                                })
                            }
                        </div>)
                })
            }</div>
   
            {group4.WIDGETS?.length !== 0 &&
            <div className="text-center pt-5">
                <h1 className="pb-5">{ group4?.NAME && Base64.decode(group4.NAME)}</h1>
                <div className="row text-center" style={{position:'relative'}}>
                {/* <div className="admin_click"><a href={`${ADMIN_URL}?editCode=1&itemId=${itemId}&typeId=${group4.TYPE_ID}&isGrouped=${group4.IS_GROUPED}`} target="_blank">Edit</a></div> */}
                    
                {
                    group4.WIDGETS?.map(({RECORDS_WIDGET, INFO_WIDGET, TYPE_WIDGET, IMAGE_WIDGET, MEASURE:{ID, NAME}}, k)=>{
                        if(RECORDS_WIDGET.length !== 0)
                        return(<div className="col-md-2 text-center" key={k}>
                                    {IMAGE_WIDGET !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE_WIDGET}/80`} style={{width:80}} />}
                                    {IMAGE_WIDGET === '' && <img src={noImage} style={{width:80}} />}
                
                                    <div className="font-weight-bolder py-4">
                                    {TYPE_WIDGET === 1 ? RECORDS_WIDGET.map(({VALUE})=>{return(VALUE)}).join(', ') : (RECORDS_WIDGET.length === 0 ? '-' : RECORDS_WIDGET.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', '))} {ID !== 0 && Base64.decode(NAME)}
                                    </div>
                
                                </div>)
                    })
                }

 
                </div>
            </div>
            }

            <div className="imageItems mt-5">
                {
                    images1.map(({IMAGE}, k)=>{
                        return(<div key={k}  style={{backgroundImage:IMAGE !== '' ? `url(${WEB_PICTURES_FILE_RESIZER}${IMAGE}/100)` : ''}} onClick={()=>openImg(k)} ></div>)
                    })
                }
            </div>
            
        
            <div className="w-100" style={{borderBottom: '1px solid #dadada'}}>
                <div className="d-flex" style={{justifyContent: 'space-between'}}>
                    <div className="pt-5 mb-4  pb-5">
                        <div className="d-flex pt-5 display-block">
                            <div className="pr-5 padding-zero-small">
                            <span>
                                <h2 className="font-weight-bolder">{getFeedbackList?.FEEDBACKS_INFO?.REVIEWS_QTY} User Reviews</h2>
                                {
                                    Array.from({length:5}).map((i , k)=>{
                                        return(<i key={k} className={['fa', getFeedbackList?.FEEDBACKS_INFO?.MIDDLE_RATE >= k+1 ? 'fa-star' : 'fa-star-o'].join(' ')}  ></i>)
                                    })
                                }
                            
                                          
                            </span>
                                <span> Based on {getFeedbackList?.FEEDBACKS_INFO?.REVIEWS_QTY} Reviews</span>
                            </div>
                            <div className="px-5 padding-zero-small">
                            <table className="tableRate">
                                <tbody>
                                {
                                    getFeedbackList?.FEEDBACKS_INFO?.RATES_SHORT_LIST?.map(({RATE, REVIEWS_QTY}, k)=>{
                                        const progress = 100 / view * REVIEWS_QTY

                                        return(
                                            <tr key={k}>
                                                <td>
                                                    <div className="flexCricle">
                                                        <span className={[RATE >= 1 ? 'active' : ''].join(' ')}></span>
                                                        <span className={[RATE >= 2 ? 'active' : ''].join(' ')}></span>
                                                        <span className={[RATE >= 3 ? 'active' : ''].join(' ')}></span>
                                                        <span className={[RATE >= 4 ? 'active' : ''].join(' ')}></span>
                                                        <span className={[RATE >= 5 ? 'active' : ''].join(' ')}></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="progressRate" >
                                                        <div style={{width:`${progress}%`}}></div>
                                                    </div>
                                                </td>
                                                <td style={{verticalAlign:'middle'}}>({REVIEWS_QTY})</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            
                            </table>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex pt-5 pb-4 my-4 display-block" style={{justifyContent:'flex-end'}}>
                        <div className="px-3">
                          
                        </div>
                        <div className="px-3">
                            <button className="last-page-btn mt-4" onClick={()=>openWritePreview()}>
                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.9922 3.04688C10.8828 2.9375 10.7188 2.9375 10.6094 3.04688L4.64844 9.00781L4.375 11.5508C4.32031 11.8789 4.62109 12.1797 4.94922 12.125L7.49219 11.8516L13.4531 5.89062C13.5625 5.78125 13.5625 5.61719 13.4531 5.50781L10.9922 3.04688ZM15.4219 2.41797L14.082 1.07812C13.6719 0.667969 12.9883 0.667969 12.5781 1.07812L11.6211 2.03516C11.5117 2.14453 11.5117 2.30859 11.6211 2.41797L14.082 4.87891C14.1914 4.98828 14.3555 4.98828 14.4648 4.87891L15.4219 3.92188C15.832 3.51172 15.832 2.82812 15.4219 2.41797ZM10.5 10.2383V13H1.75V4.25H8.01172C8.12109 4.25 8.20312 4.22266 8.25781 4.16797L9.35156 3.07422C9.54297 2.85547 9.40625 2.5 9.10547 2.5H1.3125C0.574219 2.5 0 3.10156 0 3.8125V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V9.14453C12.25 8.84375 11.8945 8.70703 11.6758 8.89844L10.582 9.99219C10.5273 10.0469 10.5 10.1289 10.5 10.2383Z"
                                        fill="#915674"/>
                                </svg>
                                Write a Review
                            </button> 
                        </div>
                    </div>
                </div>

                {showWritePreview &&

                    <div>
                        <h4>Write a Review</h4>

                        <div>
                            <label>Rating</label>
                            <Stars value={stars} onChange={state => setStars(state) } style={{fontSize:24, marginRight:8}} />
                        </div>
                        <br/>

                        <div>
                            <label>Body of Review (1500)</label>
                            <textarea value={text} onChange={({target:{value}}) => setText(value)} maxLength={1500} className="textAeraView" placeholder="Write your comments here" />
                        </div>

                        <div>
                        <Button onClick={()=>submitFeedback()}>Submit</Button>
                        <Button line onClick={()=>setShowWritePreview(false)} >Cancel</Button>
                            {/* <button className="long-cart-btn mr-3" style={{width:180}} onClick={()=>submitFeedback()}>Submit</button>
                            <button className="long-cart-btn mx-3" style={{width:180}} onClick={()=>setShowWritePreview(false)} >Cancel</button> */}
                        </div>
                        <br/>
                    </div>
                }
                
            </div>
            <div>
                
  
                {getFeedbackList?.FEEDBACKS?.content?.map(({ID_FEEDBACK, RATE, COMMENT, CREATE_DATE, POSITIVE_LIKE_QTY, NEGATIVE_LIKE_QTY, BUYER_LOGO, BUYER_NAME}, k)=>{
                    return(<div key={k} className="border-bottom pb-4 pt-4">
                    <div className="main-review-msg-sctn pt-4">
                        <div className="row">
                            <div className="col-md-10 d-flex">
                                <div className="px-2">
                                    {BUYER_LOGO === '' && <div className="feedbackImg"></div>}
                                    {BUYER_LOGO !== '' && <img className="feedbackImg" src={`${WEB_PICTURES_FILE_RESIZER}${BUYER_LOGO}/100`} />}
                                </div>
                                <div className="px-2">
                                    <div>
                                        <span className="pr-2">{BUYER_NAME}</span>
                                        {/* <span className="px-2 text-success">Verified Buyer</span> */}
                                    </div>
                                    <div>
                                    {
                                        Array.from({length:5}).map((i , k)=>{
                                            return(<i key={k} className={['fa', RATE >= k+1 ? 'fa-star' : 'fa-star-o'].join(' ')}  ></i>)
                                        })
                                    }
                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 text-right">{moment.unix(CREATE_DATE).format('MM/DD/YYYY')}</div>
                        </div>
                        <div className="py-4 last-page-prgrph">
                            <p>{Base64.decode(COMMENT)}</p>
                        </div>
                    </div>
                    <div className="py-4">
                        <div className="row">
                            <div className="col-md-8">
           
                            </div>
                            <div className="col-md-4 text-right">
                                <span className="px-2 font-weight-lighter">Was this helpful?</span>
                                <span>
                                    <i className="fa fa-thumbs-up pointer"  onClick={()=>clickLike({TYPE:1, ID_FEEDBACK})} />
                                </span>
                                <span> {POSITIVE_LIKE_QTY}</span>
                                <span className="pl-2 ">
                                    <i className="fa fa-thumbs-down pointer" onClick={()=>clickLike({TYPE:0, ID_FEEDBACK})} />
                                </span>
                                <span>{' '}{NEGATIVE_LIKE_QTY}</span>
                            </div>
                        </div>
    
                    </div>
                </div>)
                })}

                



            </div>
            {show && <PopupLoginRegister closePopup={state=>closeAuth(state)} /> }
            {/* {modal && <Modal src={srcImage} closeModal={()=>setModal(false)} />} */}

            {modalImg && (
                <Lightbox
                    mainSrc={srcImages[imgId]}
                    nextSrc={srcImages[(imgId + 1) % srcImages.length]}
                    prevSrc={srcImages[(imgId + srcImages.length - 1) % srcImages.length]}
                    onCloseRequest={() => setModalImg(false)}
                    onMovePrevRequest={() => setImgId((imgId + srcImages.length - 1) % srcImages.length)}
                    onMoveNextRequest={() => setImgId((imgId + 1) % srcImages.length) }
                />
                )}
        </>
    ) 
}


const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    errCode:state.userPrivate.err_code,
    orderId:state.userPrivate.orderId,
    getAddProductToCart:state.userPrivate.getAddProductToCart,
    getCart:state.userPrivate.getCart,
    getFeedbackList:state.user.getFeedbackList,
    // jsonResult:state.json.result,
    getProductById:state.user.getProductById,
    getProductByPricingParameters:state.user.getProductByPricingParameters,
    rate:state.auth.rate,
    getIsAuthenticated:authSelectors.getIsAuthenticated(state),
    initWebSait:state.user.initWebSait,

})

const mapDispatchToProps = {
    onCreateFeedback:userPrivateOperations.createFeedback,
    onGetFeedbackList:userOperations.getFeedbackList,
    onGetProductById:userOperations.getProductById,
    onGetProductByPricingParameters:userOperations.getProductByPricingParameters,
    onCreateFeedbackLike:userPrivateOperations.createFeedbackLike,
    onCreateFeedbackDislike:userPrivateOperations.createFeedbackDislike,
    onGetAddProductToCart:userPrivateOperations.getAddProductToCart,
    onGetCart:userPrivateOperations.getCart,
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductView);