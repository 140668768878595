import { useEffect, useRef} from "react";
import {useState} from "react";
import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';

import { connect } from "react-redux";
import userPrivateOperations from "../../redux/userPrivate/user-private-operations";
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'
import Slider from "react-slick";
import { Base64 } from 'js-base64';
import { Link } from "react-router-dom";
import PopupCrypto from "./PopupCrypto";
import PopupBrainTree from "./PopupBrainTree";
import PopupPayPal from "./PopupPayPal";
import Button from "../../components/Button/Button";
import PopupSquareUp from './PopupSquareUp';
import PopupStrip from "./PopupStrip";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
};

const INITIAL_STATE = {
    ADDRESS_TYPE:0,  //   - (0 - Billling address, 1 - Shipping address)
    FIRST_NAME:"",
    LAST_NAME:"",
    COUNTRY_REGION:"Canada",
    APARTAMENT_SUITE_UNIT:"",
    STREET_ADDRESS:"",
    TOWN_CITY:"",
    STATE:"",
    ZIP_POSTAL_CODE:"",
    PHONE:"",
    EMAIL:""
}

const Checkout = ({
    lang,
    rate,
    errCode,
    onProceedToCheckout,
    proceedToCheckout,
    onSaveProceedToCheckout,
    orderId
}) => {

    // const [useShip, setUseShip] = useState(false)
    const [useBill, setBill] = useState(false);
    const [currency, setCurrency] = useState('')

    const [billAddress, setBillAddress] = useState({});
    const [shipAddress, setShipAddress] = useState({});
    const [paymentId, setPaymetId] = useState(-1)
    const [payment, setPaymet] = useState({})
    const [errorPaymentId, setErrorPaymentId] = useState(false);
    const [comment, setComment] = useState('');
    const [checkout, setCheckout] = useState(INITIAL_STATE)

    const [popupCrypto, setPopupCrypto] = useState(false)
    const [popupBrainTree, setPopupBrainTree] = useState(false)
    const [popupPayPal, setPopupPayPal] = useState(false)
    const [popupSquareUp, setPopupSquareUp] = useState(false);
    const [popupStrip, setPopupStrip] = useState(false);


    const [total, setTotal] = useState(0)
    const [subTotal, setSubTotal] = useState(0)

    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator())

    const [ff, setFF] = useState(0)

    useEffect(()=>{
        setCurrency(rate)
    }, [rate])

    const sendCheckout = () => {
        const formValid = simpleValidator.current.allValid()
        if (!formValid && useBill) {
            simpleValidator.current.showMessages()
            forceUpdate(1)

            return
        }

        if(paymentId === -1){
            setErrorPaymentId(true);
            return
        }

        // console.log("SEND");

        onSaveProceedToCheckout({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            ID_ORDER:orderId,					//- id order
            COMPARE_ADDRESS_TYPE:useBill ? 1 : 0,		//	- (0-same as shipping, 1-different delivery)
            ID_PAYMENT_SYSTEM:paymentId,		//	- id payment system
            FIRST_NAME: checkout.FIRST_NAME,
            LAST_NAME: checkout.LAST_NAME,
            COUNTRY_REGION: checkout.COUNTRY_REGION,
            STREET_ADDRESS: checkout.STREET_ADDRESS,
            APARTAMENT_SUITE_UNIT: checkout.APARTAMENT_SUITE_UNIT,
            TOWN_CITY: checkout.TOWN_CITY,
            STATE: checkout.STATE,
            ZIP_POSTAL_CODE: checkout.ZIP_POSTAL_CODE,
            EMAIL: checkout.EMAIL,
            PHONE: checkout.PHONE,
            COMMENT: comment				//	- коментарий
        })
    }
   

    const handleCheckout = ({target: {name, value}}) => {
        setCheckout(state => ({...state, [name]: value}))
    }


    useEffect(()=>{
        onProceedToCheckout({DOMAIN, DEFAULT_COLUMN:lang})
        setFF(1)
    }, [])

    useEffect(()=>{
        if(Object.keys(proceedToCheckout).length !== 0){
            const {CART:{AMOUNT, TOTAL_TO_PAY}, USER_ADDRESSES, PAYMENT_METHODS} = proceedToCheckout

            const getAmount = AMOUNT.filter(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === currency)
            const getTotal = TOTAL_TO_PAY.filter(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === currency)

            setSubTotal(getAmount.length === 0 ? 0 : getAmount[0]?.PRICE )
            setTotal(getTotal.length === 0 ? 0 : getTotal[0]?.PRICE );

            let f = 0;
            USER_ADDRESSES.forEach((v) => {
                const {ADDRESS_TYPE} = v
                if(ADDRESS_TYPE === 0){
                    if(!useBill) setCheckout(v)
                    setBillAddress(v)
                }else if(ADDRESS_TYPE === 1){
                    if(!useBill) setCheckout(v)
                    setShipAddress(v)
                    f = 1
                }
            });
            
            if(f === 0) setBill(true)

            const getCurrentPayment = PAYMENT_METHODS.filter(({TYPE}) => TYPE === 1)

            if(ff === 1){            
                setPaymetId(getCurrentPayment.length === 0 ? -1 : getCurrentPayment[0]?.ID_RECORD)
                setCurrency(getCurrentPayment.length === 0 ? rate : getCurrentPayment[0]?.CURRENCY_SHORT_NAME)
                setFF(0)
            }
        }
    }, [proceedToCheckout, rate, currency])

    useEffect(()=>{
        if(errCode === 1){
            if(Object.keys(proceedToCheckout).length !== 0){
                const {PAYMENT_METHODS} = proceedToCheckout
                const getPayment = PAYMENT_METHODS?.filter(({ID_RECORD}) => paymentId === ID_RECORD)

                if(getPayment.length !== 0){
                    setPaymet(getPayment[0])

                    if([0, 2].indexOf(getPayment[0]?.TYPE) !== -1){
                        setPopupCrypto(true);
                    }

                    if(getPayment[0]?.TYPE === 1 && getPayment[0]?.DEFAULT_PAYMENT_TYPE === 'Braintree'){
                        setPopupBrainTree(true);
                    }

                    if(getPayment[0]?.TYPE === 1 && getPayment[0]?.DEFAULT_PAYMENT_TYPE === 'SquareUp'){
                        setPopupSquareUp(true);
                    }

                    if(getPayment[0]?.TYPE === 1 && getPayment[0]?.DEFAULT_PAYMENT_TYPE === 'Strip'){
                        setPopupStrip(true);
                    }

                    if(getPayment[0]?.TYPE === 3){
                        setPopupPayPal(true)
                    }
                }
            }
        }
    }, [errCode])

    const handleCheckBox = ({target:{checked}}) => {
        setBill(checked)

        if(checked){
            setCheckout(INITIAL_STATE)
        }else{
            setCheckout(shipAddress)
        }
    }

    const handlePayment = ({target:{value}}) =>{
        setPaymetId(Number(value))

        const {PAYMENT_METHODS} = proceedToCheckout
        const getPayment = PAYMENT_METHODS.filter(({ID_RECORD}) => Number(value) === ID_RECORD)
        // CURRENCY_SHORT_NAME
        if(getPayment.length !== 0)
            setCurrency(getPayment[0].CURRENCY_SHORT_NAME)
        
            setErrorPaymentId(false);
    }

    return (
        <>
        <div className="container">

            <div className="text-center py-5">
                <h1>Checkout</h1>
            </div>
         
            <div>
                <div className="row">
                    <div className="col-md-7">
                        <div className="border-theme py-4 mb-4">
                            <h4>Shipping details</h4>
                        </div>
                        {Object.keys(shipAddress).length !== 0 && <div>
                            <div>{shipAddress.STREET_ADDRESS}</div>
                            <div>{shipAddress.TOWN_CITY}, {shipAddress.STATE} </div>
                            <div>{shipAddress.COUNTRY_REGION}, {shipAddress.ZIP_POSTAL_CODE}</div>
                        </div>}

                        {Object.keys(shipAddress).length !== 0 &&
                        <div className="py-3">
                            <input type="checkbox" checked={useBill} onChange={handleCheckBox} />
                            <span className="px-3">Ship to a different address?</span>
                        </div>
                        }
                        {useBill && <>

                        {/* <div className="border-theme  pb-2 mb-5">
                            <h4>Billing details</h4>
                        </div> */}
                        <div className="d-flex display-block">
                            <div className="pr-3 w-50">
                                <span>First name *</span><br></br>

                                <input
                                    type="text"
                                    onChange={handleCheckout}
                                    className="w-100 form-control px-4 checkout-input-small"
                                    name="FIRST_NAME"
                                    value={checkout.FIRST_NAME}
                                    onBlur={()=>simpleValidator.current.showMessageFor('Firstname')} />
                                <div className="text-danger">
                                    {simpleValidator.current.message('Firstname', checkout.FIRST_NAME, 'required|alpha')}
                                </div>
                            </div>
                            <div className=" w-50">
                                <span>Last name *</span><br></br>
                                <input
                                    type="text"
                                    onChange={handleCheckout}
                                    className="w-100 form-control px-4 checkout-input-small"
                                    name="LAST_NAME"
                                    value={checkout.LAST_NAME}
                                    onBlur={()=>simpleValidator.current.showMessageFor('Lastname')} />
                                <div className="text-danger">
                                    {simpleValidator.current.message('Lastname', checkout.LAST_NAME, 'required|alpha')}
                                </div>
                            </div>
                        </div>
                        <div className="py-2">
                            <span>Country / Region *</span><br></br>
                            <select className="mdb-select md-form w-100 px-4 select-custom-styling"
                                    value={checkout.COUNTRY_REGION}
                                    onChange={({target:{value}}) => setCheckout(state => ({...state, COUNTRY_REGION:value}))}
                                
                            >
                                <option value="Canada" >Canada</option>
                                        <option value="USA">USA</option>
                                        <option value="Germany">Germany</option>
                                        <option value="France">France</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Japan">Japan</option>
                            </select>
                        </div>
                        <div className="py-2">
                            <span>Street address *</span><br></br>

                            <input
                                type="text"
                                onChange={handleCheckout}
                                className="form-control px-4 checkout-input-large mb-3 px-4"
                                name="STREET_ADDRESS"
                                value={checkout.STREET_ADDRESS}
                               
                                onBlur={()=>simpleValidator.current.showMessageFor('Address')} />
                            <div className="text-danger">
                                {simpleValidator.current.message('Address', checkout.STREET_ADDRESS, 'required|alpha_num_dash_space')}
                            </div>
                        
                        </div>
                        <div className="py-2">
                            <span>Town / City *</span><br></br>


                            <input
                                type="text"
                                onChange={handleCheckout}
                                className="form-control px-4 checkout-input-large"
                                name="TOWN_CITY"
                                value={checkout.TOWN_CITY}
                                onBlur={()=>simpleValidator.current.showMessageFor('City')} />
                            <div className="text-danger">
                                {simpleValidator.current.message('City', checkout.TOWN_CITY, 'required|alpha_num_dash_space')}
                            </div>
                        </div>
                        <div className="py-2">
                            <span>State *</span><br></br>

                            <input
                                type="text"
                                onChange={handleCheckout}
                                className="form-control px-4 checkout-input-large"
                                name="STATE"
                                value={checkout.STATE}
                                onBlur={()=>simpleValidator.current.showMessageFor('State')} />
                            <div className="text-danger">
                                {simpleValidator.current.message('State', checkout.STATE, 'required|alpha')}
                            </div>
                        </div>
                        <div className="py-2">
                            <span>ZIP *</span><br></br>

                            <input
                                type="number"
                                onChange={handleCheckout}
                                className="form-control px-4 checkout-input-large"
                                name="ZIP_POSTAL_CODE"
                                value={checkout.ZIP_POSTAL_CODE}
                                onBlur={()=>simpleValidator.current.showMessageFor('ZipCode')} />
                            <div className="text-danger">
                                {simpleValidator.current.message('ZipCode', checkout.ZIP_POSTAL_CODE, 'required|numeric|min:0|max:05')}
                            </div>
                        </div>
                        <div className="py-2">
                            <span>Phone *</span><br></br>

                            <input
                                type="phone"
                                onChange={handleCheckout}
                                className="form-control px-4 checkout-input-large"
                                minLength={7}
                                maxLength={10}
                                name="PHONE"
                                value={checkout.PHONE}
                                onBlur={()=>simpleValidator.current.showMessageFor('phone_number')} />
                            <div className="text-danger">
                                {simpleValidator.current.message('phone_number', checkout.PHONE, 'required|min:7|max:10')}
                            </div>
                        </div>
                        <div className="py-2">
                            <span>Email address *</span><br></br>
                            <input
                                type="email"
                                className="form-control px-4 checkout-input-large"
                                name="EMAIL"
                                onChange={handleCheckout}
                                value={checkout.EMAIL}
                                onBlur={() => simpleValidator.current.showMessageFor('email')}/>
                            <div className="text-danger">
                                {simpleValidator.current.message('email', checkout.EMAIL, 'required|email')}
                            </div>
                        </div>

                        </>}
                        
                        <div>
                            <span>Order notes (optional)</span><br></br>
                            <textarea className="checkout-message pt-2 form-control" value={comment} onChange={({target:{value}})=>setComment(value)}>

                        </textarea>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="chkout-grey-sctn">
                            <div className="border-theme mb-5 pb-3">
                                <h4>Your order</h4>
                            </div>
                            <div className="checkout-grey-table">
                                <table className="table border-bottom">
                                    <thead className="tbl-head-border">
                                    <tr>
                                        <th>Product</th>
                                        <th className="text-right">Subtotal</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    
                                        {proceedToCheckout?.CART?.PRODUCTS.length !== 0 &&
                                            proceedToCheckout?.CART?.PRODUCTS.map(({NAME, SHORT_DESCRIPTION, AMOUNT}, k)=>{
                                                const price = AMOUNT.filter(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === currency).map(({PRICE})=>(Number(PRICE.toFixed(2))))
                                                return(<tr key={k}>
                                                    <td>
                                                        <div>
                                                            <span className="text-theme">{Base64.decode(NAME)}</span>
                                                        </div>
                                                        {/* <div>
                                                            <span>{Base64.decode(SHORT_DESCRIPTION)}</span>
                                                        </div> */}
                                                    </td>
                                                    <td className="text-theme text-right">{currency} {price}</td>
                                                </tr>)
                                            })
                                        }
    
                                    <tr>
                                        <td className="font-weight-bolder">Subtotal</td>
                                        <td className="text-theme text-right">{currency} {Number(subTotal.toFixed(2))}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bolder">Total</td>
                                        <td className="font-weight-bolder text-right">{currency} {Number(total.toFixed(2))}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="payment-sections">
                                    <div className="border-theme pb-3 mb-3">
                                        <h4 className="text-white">Payment Method</h4>
                                    </div>

                                    {
                                        proceedToCheckout?.PAYMENT_METHODS?.map(({ID_RECORD, NAME, TYPE, ICON_NAME, ICON_1, ICON_2, STATUS}, k)=>{
                                            if(STATUS === 1)
                                            return(<div className="py-1 d-flex" key={k} style={{justifyContent:'space-between'}}>
                                                    <div>
                                                        <span className="px-2">
                                                            <input type="radio" 
                                                                name="payment" 
                                                                value={ID_RECORD} 
                                                                checked={paymentId === ID_RECORD}
                                                                onChange={handlePayment}
                                                                onBlur={()=>simpleValidator.current.showMessageFor('payment')}
                                                            />
                                                        </span>
                                                        <span className="px-2 text-white">{NAME}</span>
                                                    </div>
                                                    <div>
                                                        <span className="px-2">
                                                            {TYPE !== 1 && <img src={`${WEB_PICTURES_FILE_RESIZER}${ICON_NAME}/50`} height={20} />}
                                                            {TYPE === 1 && <>
                                                                <img src={`${WEB_PICTURES_FILE_RESIZER}${ICON_1}/50`} height={20} />
                                                                <img src={`${WEB_PICTURES_FILE_RESIZER}${ICON_2}/50`} height={20} className="ml-2" />
                                                                </>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>)
                                        })
                                    }
                                {errorPaymentId &&
                                    <div className="text-danger">The payment field is required.</div>
                                }

                            

                                </div>
                            </div>
                            <div className="py-4 checkout-paragraph">
                                <p>Your personal data will be used to process your order, support your experience
                                    throughout
                                    this website, and for other purposes described in our privacy policy.
                                </p>
                            </div>
                            <div className="d-flex pb-5">
                                <span className="pr-2"><input type="checkbox"/></span>
                                <span
                                    className="px-2">I have read and agree to Premium Cannabis Shop terms and conditions *</span>
                            </div>
                            <div>
                                <Button style={{width:'100%'}} onClick={()=>sendCheckout()} disabled={proceedToCheckout.CART?.PRODUCTS_QTY === 0 ? true : false}>Place Order</Button>
                            </div>

                        </div>
                    </div>
                </div>

        </div>

        <div className="pt-5 pb-5">
                <div className="row pt-5 pb-5">

                    <Slider {...settings} style={{width:'100%', marginTop: 24}}>

                        {proceedToCheckout?.PRODUCT_GROUPS?.map(({NAME, PRODUCTS}, k)=>{
                            return(<div key={k} className="px-2">
                                    <h2 className="cart-last-sctn-hdng-brdr pb-3">{Base64.decode(NAME)}</h2>
                                    {
                                        PRODUCTS.map(({ID_ITEM, IMAGE, NAME, PRICE, TOTAL_PRICE, PERCENT_OFF}, k)=>{
                                            const price = PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE
                                            const priceToken = PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN').PRICE

                                            const totalPrice = TOTAL_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE
                                            const totalPriceToken = TOTAL_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN').PRICE

                                            return(<div key={k} className="d-flex py-3">
                                                <div className="px-3">
                                                    <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/100`} width="80" height="80" style={{borderRadius:'100%'}} />
        
                                                </div>
                                                <div className="px-3 pt-2">
                                                    <div>
                                                        <Link to={`product/${ID_ITEM}`} className="text-success" >{Base64.decode(NAME)}</Link>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div>
                                                            <div>{rate} {Number(totalPrice.toFixed(2))}</div>
                                                            <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(totalPriceToken.toFixed(2))}</div>
                                                        </div>
                                                        {PERCENT_OFF !== 0 &&
                                                            <div className="ml-3">
                                                                <div style={{textDecoration: 'line-through', color:'#4defe7'}}>{rate} {Number(price.toFixed(2))}</div>
                                                                <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(priceToken.toFixed(2))}</div>
                                                            </div>
                                                        }
                                                    </div>
        
                                                </div>
        
                                            </div>);
                                        })
                                    }
                                    
                                </div>)
                        })}

                        

                    </Slider>

                   
                </div>
            </div>

</div>
{popupCrypto && <PopupCrypto payment={payment} currency={currency} total={Number(total.toFixed(2))} closePopup={()=>setPopupCrypto(false)} />}
{popupBrainTree && <PopupBrainTree orderId={orderId} payment={payment} currency={currency} total={Number(total.toFixed(2))} closePopup={()=>setPopupBrainTree(false)} />}
{popupPayPal && <PopupPayPal orderId={orderId} payment={payment} currency={currency} total={Number(total.toFixed(2))} closePopup={()=>setPopupPayPal(false)} />}
{popupSquareUp && <PopupSquareUp orderId={orderId} payment={payment} closePopup={()=>setPopupSquareUp(false)}  />}
{popupStrip && <PopupStrip orderId={orderId} payment={payment} closePopup={()=>setPopupStrip(false)}  />}
</>
)
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    orderId:state.userPrivate.orderId,
    proceedToCheckout:state.userPrivate.proceedToCheckout,
    // initWebSait:state.user.initWebSait,
    // legalInfoById:state.user.legalInfoById,
})

const mapDispatchToProps = {
    onProceedToCheckout:userPrivateOperations.proceedToCheckout,
    onSaveProceedToCheckout:userPrivateOperations.saveProceedToCheckout,
    // onLegalInfoById:userOperations.legalInfoById,
    // onGetCart:userPrivateOperations.getCart,
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
