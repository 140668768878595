import { useEffect, useRef, useState } from "react"
import Popup from "../../components/Popup/Popup"
import DropIn from "braintree-web-drop-in-react";
// import { Braintree, HostedField } from 'react-braintree-fields';
import {DOMAIN} from './../../config/Url'
import { connect } from "react-redux";
import { userPrivateActions, userPrivateOperations } from "../../redux/userPrivate";
import braintree from 'braintree-web'
// import braintree from 'braintree'
import dropIn from 'braintree-web-drop-in'
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";

const PopupBrainTree = ({
    closePopup,
    orderId,
    onGetUserBraintreeToken,
    getUserBraintreeToken,
    onUserTransactionSale,
    userTransactionSale,
    payment,
    setErrorCode
}) => {

    

    const [instance, setInstance] = useState()
    const [authorization, setAuthorization] = useState('')
    const [error, setError] = useState(false);


    const history = useHistory();

    useEffect(()=>{
        onGetUserBraintreeToken({DOMAIN})
        setError(false)

        // const gateway = new braintree.BraintreeGateway({
        //     environment: braintree.Environment.Production,
        //     merchantId: "gxyphtjgyc7zrkq7",
        //     publicKey: "8g7n3jwwm8xdr4dc",
        //     privateKey: "ae605a4416092756d245974436b6e28d"
        // });

        // gateway.clientToken.generate({}, function (err, response) {
        //     console.log(response);
        // });
    }, [])

    useEffect(()=>{
        // console.log(getUserBraintreeToken);
        if(getUserBraintreeToken !== ''){
            setAuthorization(getUserBraintreeToken)
            run(getUserBraintreeToken)
            // run('sandbox_w3yd874v_7b4b2w6nhvfxq3w5')
            // run('sandbox_v2m4zk6f_gxyphtjgyc7zrkq7')
        }
    }, [getUserBraintreeToken])

    const run = (token) => {
        // var submitButton = document.querySelector('#submit-button');

        dropIn.create({
          authorization: token,
          selector: '#dropin-container',
          vaultManager: true,
        //   paypal: {
        //     flow: 'checkout',
        //     // buttonStyle: {
        //     //   color: 'blue',
        //     //   shape: 'rect',
        //     //   size: 'medium'
        //     // }
        //   }
        }, function (err, dropinInstance) {
          if (err) {
            // Handle any errors that might've occurred when creating Drop-in
            console.error(err);
            return;
          }
          setInstance(dropinInstance)
        });
    }

    const purchase = () => {
        instance.requestPaymentMethod(function (err, payload) {
            if (err) {
            // Handle errors in requesting payment method
            return
            }

            console.log(payload);

            onUserTransactionSale({
                DOMAIN,
                ID_ORDER:orderId,
                PAYMENT_METHOD_NONCE:payload.nonce
            })

            // closePopup()
        });
    }

    useEffect(()=>{
        // console.log(userTransactionSale);
        // if(Object.keys(userTransactionSale).length !== 0){
        //     const {success} = userTransactionSale
        //     if(success)
        //         // history.push('/thanks') 
        //         history.push({
        //             pathname: '/thanks',
        //             search: `?record=${payment?.ID_RECORD}`,
        //         });
        // }

        if(userTransactionSale === 1){
            history.push({
                pathname: '/thanks',
                search: `?record=${payment?.ID_RECORD}`,
            });
        }
        if(userTransactionSale === -1){
            setError(true)
        }
    }, [userTransactionSale])

    return (
        <Popup closePopup={()=>{closePopup(); setErrorCode(0)}} >
            {/* <form id="checkout" method="post" action="/checkout">
            <div id="payment-form"></div>
            <input type="submit" value="Pay $10" />
            </form> */}
            {error && <h4 className="text-center" style={{
                        display:'flex',
                        position: 'absolute',
                        zIndex: 1000,
                        left: 0,
                        right: 0,
                        top: '24%',
                        bottom: 0,
                        textAlign:'center',
                        color:'red',
                        alignItems:'center',
                        backgroundColor:'#fff'
                        }} >Something went wrong. We`ll take you back to checkout so you can try again.</h4>}
            {authorization === '' && <h3 className="text-center my-5">Loading...</h3>}
            {authorization !== '' && 
                <div className="mx-3 mb-4 ">
                    <div id="dropin-container"></div>

                    <Button onClick={()=>purchase()}>Purchase</Button>
                </div>
            }
        </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    orderId:state.userPrivate.orderId,
    getUserBraintreeToken:state.userPrivate.getUserBraintreeToken,
    userTransactionSale:state.userPrivate.userTransactionSale,
})

const mapDispatchToProps = {
    onGetUserBraintreeToken:userPrivateOperations.getUserBraintreeToken,
    onUserTransactionSale:userPrivateOperations.userTransactionSale,
    setErrorCode:userPrivateActions.setErrorCode,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupBrainTree);
