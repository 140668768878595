import { Base64 } from "js-base64"
import { useEffect } from "react"
import { connect } from "react-redux"
import Popup from "../../components/Popup/Popup"
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'
import { userPrivateOperations } from "../../redux/userPrivate"


const PopupOrder = ({
    lang,
    onGetUserOrderById,
    getUserOrderById,
    closePopup,
    orderId:ID_ORDER,
    currentRate
}) => {

    useEffect(()=>{
        onGetUserOrderById({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            ID_ORDER
        })
    }, [])

    return (
        <Popup  title={`Order ${ID_ORDER} products list`} closePopup={closePopup} type="top" width="1000px">


            <div className="tablee mx-3 my-2">
                <table className="table border-bottom table-cart" style={{paddingBottom: 0}}>
                    <thead className="tbl-head-border">
                    <tr>
                        <th width="12%"></th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>Quantity</th>
                        <th>Loyalty discount</th>
                        <th>Subtotal</th>
                    </tr>
                    </thead>
                    <tbody>
                        {getUserOrderById?.PRODUCTS?.length !== 0 &&
                            getUserOrderById?.PRODUCTS?.map(({IMAGE, NAME, QTY, PRODUCT_PRICE, DISCOUNT, AMOUNT, LOYALTY_DISCOUNT}, k)=>{
                                const rate = currentRate
                                const price =  PRODUCT_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE;
                                const discount =  DISCOUNT.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE;
                                const amount =  AMOUNT.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE;

                                const loyaltyDiscountValue = LOYALTY_DISCOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate)?.VALUE
                                const loyaltyDiscountPoint = LOYALTY_DISCOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN')?.VALUE


                                return(<tr>
                                    <td>
                                        <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/200`} alt="" className="img-class"  />
                                    </td>
                                    <td>
                                        {Base64.decode(NAME)}
                                    </td>
                                    <td>{rate} {Number(price.toFixed(2))}</td>
                                    <td>{rate} {Number(discount.toFixed(2))}</td>
                                    <td >
                                        {QTY}
                                    </td>
                                    <td>CAD {loyaltyDiscountValue} (Points {loyaltyDiscountPoint})</td>
                                    <td>{rate} {Number(amount.toFixed(2))}</td>
                                </tr>)
                            })
                        }

                        {getUserOrderById?.PRODUCTS?.length === 0 && <tr><td colSpan="6" style={{textAlign: 'center', fontSize: 36}}> Cart is empty </td></tr>}
   
                    </tbody>
                </table>
            </div>
        </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    // orderId:state.userPrivate.orderId,
    getUserOrderById:state.userPrivate.getUserOrderById,

})

const mapDispatchToProps = {
    // onProceedToCheckout:userPrivateOperations.proceedToCheckout,
    onGetUserOrderById:userPrivateOperations.getUserOrderById,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupOrder);
