import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userOperations } from "../../redux/user";
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from './../../config/Url'

import Slider from "react-slick";
import { Base64 } from "js-base64";
import { useRef } from "react";
import Button from "../../components/Button/Button";

import image53 from './../../assets/img/image53.png'
import image55 from './../../assets/img/image55.png'
import image56 from './../../assets/img/image56.png'
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth:true
};

const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const Home = ({
    lang,
    // onGetProductsByGroup,
    // getProductsByGroup,
    rate,
    groupName, 
    history,
    initWebSait
}) => {

    const slider = useRef()
    const slider2 = useRef()

    const [count, setCount] = useState(0)

    // useEffect(()=>{
    //     onGetProductsByGroup({
    //             DOMAIN,
    //             DEFAULT_COLUMN:lang,
    //             GROUP_NAME,    //  -(New products, Featured, Top products, On Sale, Top selling, Top rating)
    //             PAGE:0,
    //             SIZE:20
    //         })
    // }, [GROUP_NAME, lang])

    // useEffect(()=>{
    //     console.log({getProductsByGroup});
    // }, [getProductsByGroup])

    useEffect(()=>{
        setCount(initWebSait?.PRODUCT_GROUPS?.find(({ID_GROUP}) => ID_GROUP === groupName)?.PRODUCTS.length)
    }, [initWebSait, groupName])

    const goPage = ID_ITEM =>{
        history.push(`/product/${ID_ITEM}`)
    }

    return (

        <div className="container">
            <div className="row sliderrr">
                <br/>
                <br/>
                {count > 1 && <i className="fa fa-angle-left" aria-hidden="true" onClick={()=>slider.current.slickPrev()}></i>}
                <Slider ref={slider} {...settings} style={{width:'100%', marginTop: 24}}>
                    {
                        initWebSait?.PRODUCT_GROUPS?.find(({ID_GROUP}) => ID_GROUP === groupName)?.PRODUCTS.map(({ID_ITEM, NAME, IMAGE, PRICE_START_FROM, SHORT_DESCRIPTION}, k)=>{
                            const text = Base64.decode(SHORT_DESCRIPTION);
                            // var sliced = text.slice(0,120);
                            // if (sliced.length < text.length) {
                            //     sliced += '...';
                            // }
                           
                           return( <div className="container" key={k}>
                                        <div className="row">
                                            <div className=" col-md-6  packet-lft-sctn ">
                                                <h4 className="text-success font-weight-normal ">{Base64.decode(NAME)}</h4>
                                                {/* <h4 className="text-dark font-weight-normal " dangerouslySetInnerHTML={{__html:Base64.decode(SHORT_DESCRIPTION)}} > </h4> */}
                                                {/* <div className="text-dark font-weight-normal "  >{Base64.decode(SHORT_DESCRIPTION)} </div> */}
                                                <div className="text-dark font-weight-normal "  dangerouslySetInnerHTML={{
                                                                                                __html: DOMPurify.sanitize(Base64.decode(SHORT_DESCRIPTION))
                                                                                            }} ></div>
                                                <h1 className="text-dark font-weight-bolder display-4 ">WORTH A TRY!</h1>
                                                <Button onClick={()=>goPage(ID_ITEM)}>Buy Now</Button>
                                                
                                                {/* <button type="button" className="home-buttons buy-btn text-white font-weight-bold" onClick={()=>goPage(ID_ITEM)}>Buy Now</button> */}
                                                {/* <button type="button" className="home-buttons buy-next-btn font-weight-bold mx-4">CBD Cannabis</button> */}
                                            </div>
                                            <div className="col-md-6 home-img-hide " style={{position:'relative', minHeight:473}}>
                                                {initWebSait?.PRODUCT_LIST_TYPE === 0 && <svg style={{ position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%)'}} width="476" height="469" viewBox="0 0 476 469" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M367.996 418.153C368.229 430.385 359.165 439.904 347.156 439.999C336.035 440.094 326.737 431.147 326.036 419.724C325.335 408.206 334.914 397.592 346.455 397.021C358.37 396.498 367.762 405.684 367.996 418.153Z" fill="#5782A2"/>
                                                    <path d="M71.9917 128.504C71.6584 140.14 61.4213 149.449 49.5653 148.983C37.6616 148.471 28.2815 138.325 29.0433 126.735C29.7576 115.658 39.3281 106.908 50.6128 107.001C62.5165 107.094 72.3251 116.915 71.9917 128.504Z" fill="#915674"/>
                                                    <path d="M71.9998 341.022C71.9522 352.834 62.1459 362.228 50.0548 361.996C37.8208 361.763 28.5859 352.183 29.0143 340.185C29.4427 328.838 38.4873 320.188 50.1501 320.002C62.3364 319.862 72.0474 329.21 71.9998 341.022Z" fill="#CA9E71"/>
                                                    <path d="M152.998 418.599C152.807 431.078 143.193 440.328 130.662 439.991C118.801 439.654 109.952 430.499 110 418.647C110.048 406.457 120.236 396.58 132.289 397.014C143.767 397.399 153.142 407.228 152.998 418.599Z" fill="#8DA56C"/>
                                                    <path d="M369 49.7932C369.048 61.9633 359.498 71.1844 347.021 70.9972C335.791 70.81 326.145 61.1675 326.002 50.1209C325.858 38.3253 336.079 28.636 348.221 29.0105C359.978 29.385 368.952 38.3721 369 49.7932Z" fill="#8DA56C"/>
                                                    <path d="M21.5958 213C33.6757 213.047 43.1874 222.565 42.9972 234.324C42.807 245.988 32.8196 255.274 20.7872 254.994C9.80118 254.761 0.241916 245.242 0.00412265 234.324C-0.233671 222.939 9.84881 213 21.5958 213Z" fill="#B96B83"/>
                                                    <path d="M475.993 234.387C475.659 246.456 465.973 255.449 453.757 254.983C442.783 254.61 433.096 244.918 433.001 234.201C432.905 222.366 442.544 212.954 454.712 213C467.07 213.093 476.28 222.366 475.993 234.387Z" fill="#BA6C84"/>
                                                    <path d="M425.734 361.996C413.403 361.763 403.666 352.038 404.009 340.359C404.351 328.633 414.676 319.466 426.957 320.024C438.505 320.536 447.851 329.796 447.997 340.824C448.193 352.271 437.722 362.229 425.734 361.996Z" fill="#915674"/>
                                                    <path d="M130.623 70.9985C119.513 70.8583 110.191 61.47 110.003 50.2601C109.814 38.3496 119.607 28.6811 131.565 29.0081C143.193 29.335 151.856 38.0694 151.997 49.6529C152.186 61.7036 142.77 71.1386 130.623 70.9985Z" fill="#5481A1"/>
                                                    <path d="M425.689 148.992C413.176 148.619 403.669 139.057 404.009 127.349C404.397 115.641 414.582 106.638 426.998 107.011C438.057 107.338 447.999 117.18 447.999 127.908C448.096 139.197 437.378 149.319 425.689 148.992Z" fill="#C99D70"/>
                                                    <path d="M259 20.9745C259 33.5545 250.52 41.4804 237.561 41.9873C225.65 42.4481 215.407 30.2828 216.027 20.3293C216.694 9.50037 227.175 -0.314831 237.466 0.00773472C250.377 0.422461 259 8.44051 259 20.9745Z" fill="#76ABC4"/>
                                                    <path d="M238.804 426.073C251.374 426.168 259.803 434.27 259.998 446.446C260.144 459.475 251.423 468.856 238.95 468.999C227.159 469.141 215.855 458.575 216.001 447.441C216.099 438.297 225.405 424.889 238.804 426.073Z" fill="#76ABC4"/>
                                                </svg>}
                                                <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/500`} alt="Image" className="homeImg" style={{borderRadius:initWebSait?.PRODUCT_LIST_TYPE === 1 ? 0 : '100%'}} />
                                            </div>
                                        </div>
                                    </div>)
                        })
                    }
                </Slider>
                {count > 1 && <i className="fa fa-angle-right" aria-hidden="true" onClick={()=>slider.current.slickNext()}></i>}
            </div>

            {initWebSait?.MAIN_INFO?.SHOW_CATALOGS === 1 && <>
                <div className="row">

            {initWebSait?.CATALOGS?.map(({NAME_CATALOG, TYPE_VIEW, PRODUCTS}, k)=>{

                const settingCatalog = {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: PRODUCTS.length < 5 ? PRODUCTS.length : 5,
                    slidesToScroll: 1,
                    variableWidth:PRODUCTS.length < 5 ? true : false
                }

                if(TYPE_VIEW === 0){
                    return(<div key={k} className="col-12" style={{marginBottom:25}}>
                        <div className=" mostProducts ">
                            <h4 style={{fontSize:16}}>{NAME_CATALOG}</h4>
                            <Slider {...settingCatalog} >
                                {PRODUCTS?.map(({IMAGE, ID_ITEM}, k)=>{
                                    return(<div key={k}>
                                                <Link to={`/product/${ID_ITEM}`}><img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/200`} alt="Image" className="mostImg" /></Link>
                                            </div>)
                                })}
                            </Slider>
                        </div>
                    </div>)
                }

                if(TYPE_VIEW === 1){
                    return(<div key={k} className="col-6" style={{marginBottom:25, marginRight: 12, maxWidth: '48%'}}>
                        <div  className=" mostProducts mostProducts2 ">
                            <h4 style={{fontSize:16}}>{NAME_CATALOG}</h4>
                            <Slider {...settingCatalog} slidesToShow={2} >
                                {PRODUCTS?.map(({IMAGE, ID_ITEM}, k)=>{
                                    return(<div key={k}>
                                                <Link to={`/product/${ID_ITEM}`}><img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/200`} alt="Image" className="mostImg" /></Link>
                                            </div>)
                                })}
                                
                            
                            </Slider>
                        </div>
                    </div>)
                }

                if(TYPE_VIEW === 2){
                    return( <div key={k} className="col" style={{marginBottom:25, maxWidth: '30%'}}>
                                <div className="seeMore">
                                    <h4 style={{fontSize:16}}>{NAME_CATALOG}</h4>
                                    <br/>
                                    <div className="container">
                                        <div className="row">
                                            {PRODUCTS?.filter((v, k)=> k < 4 )?.map(({IMAGE, ID_ITEM, NAME}, k)=>{
                                                return(<div className="col" key={k}>
                                                        <Link to={`/product/${ID_ITEM}`} style={{color:'#000'}}>
                                                            <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/200`} alt="Image" className="seeMoreImg" />
                                                            <div className="seeMoreTitle">{Base64.decode(NAME)}</div>
                                                        </Link>
                                                    </div>)
                                            })}
                                            
                                        </div>
                                    </div>
                                    <br/>

                                    <Link to={`/products`}>See More</Link>
                                </div>
                            </div>)
                }

                })}
                </div>
            </>}
        </div>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    groupName:state.auth.groupName,
    // getProductsByGroup:state.user.getProductsByGroup\
    initWebSait:state.user.initWebSait,
})

const mapDispatchToProps = {
    // onGetProductsByGroup:userOperations.getProductsByGroup,
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);