
import { authActions } from "./auth";
import configActions from "./config/config-actions";

const resetToken = (instance, state, dispatch) => {

    dispatch(configActions.loading(true));

    // if(Boolean(state().auth.token)){
        // instance.defaults.headers.common.Authorization = `Bearer ${state().auth.token}`;

        instance.interceptors.request.use(config => {
            // dispatch(userActions.loader(true))
            if(Boolean(state().auth.token)){
                config.headers.Authorization = `Bearer ${state().auth.token}`;
            }
            return config;
        
        }, error => {
            // console.log(error);
            // dispatch(userActions.loader(false))
            return Promise.reject(error);
        });
        
        instance.interceptors.response.use(response => {
            // dispatch(userActions.loader(false))
            // console.log(response);

            dispatch(configActions.loading(false));
            

            if(!response?.status){
                // console.log('Something went wrong. Please reload page.');
                // dispatch(authActions.alertError(true))
                dispatch(authActions.logoutSuccess());
                window.location.href = "/"
                return response
            }

            if([401, 400, 403].indexOf(response?.status) !== -1){
                dispatch(authActions.logoutSuccess());
                window.location.href = "/"
            }

            return response;
        }, error => {
            dispatch(configActions.loading(false));

            // dispatch(userActions.loader(false))

            // console.log(error?.response);

            if(!error?.response?.status){
                dispatch(authActions.logoutSuccess());
                window.location.href = "/"
                return Promise.reject(error);
            }

            if([401, 400, 403].indexOf(error?.response?.status) !== -1){
                dispatch(authActions.logoutSuccess());
                window.location.href = "/"
            }

            return Promise.reject(error);
        });

}

export default resetToken