import { connect } from "react-redux";
import { userPrivateActions, userPrivateOperations } from "../../redux/userPrivate";
import { useCallback, useEffect, useRef, useState } from "react"
import Popup from "../../components/Popup/Popup"
import {DOMAIN} from './../../config/Url'

import { loadStripe } from "@stripe/stripe-js";
import { Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useHistory } from "react-router-dom";

// const promise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
// const promise = loadStripe("pk_test_51JDp99IGDOo9yI9XWjbZvyYtmDbfqzdKiQStFXyABy1TyZXVzvxVq04rAYlwRoUa6D6WQAh7ixl10rRzdZztwXld00AEG37RjJ");


const PopupStrip = ({
    closePopup,
    setErrorCode,
    onGetUserStripToken,
    getUserStripToken,
    onGetUserStripClientSecret,
    getUserStripClientSecret,
    orderId,
    onUserTransactionStrip,
    userTransactionStrip,
    payment
}) => { 

    const [promise, setPromise] = useState('')
    const [clientSecret, setClientSecret] = useState('');
    const [payload, setPayload] = useState({});
    const history = useHistory();

    useEffect(()=>{
        onGetUserStripToken({DOMAIN})
        onGetUserStripClientSecret({DOMAIN, ID_ORDER:orderId})
        setErrorCode(0)
    }, [])

    useEffect(()=>{
        if(getUserStripToken !== ''){
            setPromise(loadStripe(getUserStripToken))
        }
    }, [getUserStripToken])

    useEffect(()=>{
        if(getUserStripClientSecret !== ''){
            setClientSecret(getUserStripClientSecret)
        }
    }, [getUserStripClientSecret])

    useEffect(()=>{
        if(payload.error){

        }else if(payload?.paymentIntent?.status === "succeeded"){
            onUserTransactionStrip({DOMAIN, ID_ORDER:orderId})
        }
    }, [payload])

    useEffect(()=>{
        if(userTransactionStrip === 1){
            history.push({
                pathname: '/thanks',
                search: `?record=${payment?.ID_RECORD}`,
            });
        }
    }, [userTransactionStrip])

    return (
        <Popup closePopup={()=>{closePopup(); setErrorCode(0)}} >
            <div style={{padding:12}}>
                {promise !== '' && <Elements stripe={promise}>
                    <CheckoutForm ClientSecret={clientSecret} onPayload = {state => setPayload(state)} />
                </Elements>}
            </div>
        </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    getUserStripToken:state.userPrivate.getUserStripToken,
    getUserStripClientSecret:state.userPrivate.getUserStripClientSecret,
    userTransactionStrip:state.userPrivate.userTransactionStrip,
})

const mapDispatchToProps = {
    onGetUserStripToken:userPrivateOperations.getUserStripToken,
    onGetUserStripClientSecret:userPrivateOperations.getUserStripClientSecret,
    onUserTransactionStrip:userPrivateOperations.userTransactionStrip,
    setErrorCode:userPrivateActions.setErrorCode,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupStrip);