import {createAction} from '@reduxjs/toolkit';


const registerRequest = createAction('auth/registerRequest')
const registerSuccess = createAction('auth/registerSuccess')
const registerError = createAction('auth/registerError')

const loginRequest = createAction('auth/loginRequest')
const loginSuccess = createAction('auth/loginSuccess')
const loginError = createAction('auth/loginError')

const logoutRequest = createAction('auth/logoutRequest')
const logoutSuccess = createAction('auth/logoutSuccess')
const logoutError = createAction('auth/logoutError')

const setToken = createAction('auth/setToken')
const rate = createAction('auth/rate')
const yearsAge18 = createAction('auth/yearsAge18')
const groupName = createAction('auth/groupName')




export default{
    setToken,
    rate,
    yearsAge18,
    groupName,
    registerRequest,
    registerSuccess,
    registerError,

    loginRequest,
    loginSuccess,
    loginError,

    logoutRequest,
    logoutSuccess,
    logoutError,

    forgotPasswordEmailRequest:createAction('model/forgotPasswordEmailRequest'),
    forgotPasswordEmailSuccess:createAction('model/forgotPasswordEmailSuccess'),
    forgotPasswordEmailError:createAction('model/forgotPasswordEmailError'),

    setErrCode:createAction('model/setErrCode'),
}