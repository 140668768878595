import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import userActions from './user-actions';
import { userOperations } from '.';
import { modelActions } from '../model';


const currencyList = createReducer([], {
    [userActions.currencyListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getProductsByGroup = createReducer({}, {
    [userActions.getProductsByGroupSuccess]:(_, {payload}) => payload.result,
});

const getProductById = createReducer({}, {
    [userActions.getProductByIdSuccess]:(_, {payload}) => payload.result,
    [userActions.getProductByPricingParametersSuccess]:(_, {payload}) => payload.result,
});

const getWidgetRecords = createReducer({}, {
    [userActions.getWidgetRecordsSuccess]:(_, {payload}) => payload.result,
});

const getProductByPricingParameters = createReducer({}, {
    [userActions.getProductByPricingParametersSuccess]:(_, {payload}) => payload.result,
});

// const getProductFromLucene = createReducer({}, {
//     [userActions.getProductFromLuceneSuccess]:(_, {payload}) => payload.result,
// });

const getCatalogsList = createReducer([], {
    [userActions.getCatalogsListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getCatalogsListMenu = createReducer([], {
    [userActions.getCatalogsListMenuSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getProducts = createReducer({}, {
    [userActions.getProductsSuccess]:(_, {payload}) => payload.result,
    [modelActions.getProductFromLuceneSuccess]:() => {}
});

const getFeedbackList = createReducer({}, {
    [userActions.getFeedbackListSuccess]:(_, {payload}) => payload.result,
});

const err_code = createReducer(0, {
    [userActions.removeManualMeasureRequest]:() => -1,
    [userActions.loginRequest]:() => -1,
    [userActions.getCatalogsListRequest]:() => -1,
    [userActions.getCatalogsListMenuRequest]:() => -1,

    [userActions.getCatalogsListMenuSuccess]:(_, {payload}) => payload.err_code,
    [userActions.getCatalogsListSuccess]:(_, {payload}) => payload.err_code,
    [userActions.loginSuccess]:(_, {payload}) => payload.err_code,
    [userActions.removeManualMeasureSuccess]:(_, {payload}) => payload.err_code,

})
const err_t = createReducer('', {
    // [userActions.updatePageSuccess]:(_, {payload}) => payload.err_t,
    // [userActions.getItemsListSuccess]:(_, {payload}) => payload.err_t,
    // [userActions.getItemsByIdSuccess]:(_, {payload}) => payload.err_t
})

const loading = createReducer(false, {
    // [userActions.getAppPagesListRequest]:() => true,
    // [userActions.getAppPagesListSuccess]:() => false,
    // [userActions.getAppPagesListError]:() => false,

})

const loader = createReducer(false, {
    [userActions.loader]:(_, {payload}) => payload
})

export default combineReducers({
    err_code,
    err_t,
    loading,
    loader,
    initWebSait:createReducer({}, {
        [userActions.initWebSaitSuccess]:(_, {payload}) => payload.result,
    }),
    legalInfoById:createReducer({}, {
        [userActions.legalInfoByIdSuccess]:(_, {payload}) => payload.result,
    }),
    getWidgetRecords,
    getProductByPricingParameters,
    getCatalogsList,
    getCatalogsListMenu,
    currencyList,
    getProductsByGroup,
    getProducts,
    getProductById,
    getFeedbackList
})