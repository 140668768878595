import { connect } from "react-redux";

import styled from 'styled-components';

const color = '#915674'
const radius = '60px';

const ButtonStyle = styled.button`
    width: 213px;
    height: 54px;
    background: ${props => !props.line ? props.color : '#fff'};
    color: ${props => props.line ? props.color : '#fff'};
    border: 2px solid ${props => props.color};
    border-radius: ${props => props.radius};
    font-weight: 600;
    /* margin: 0 12px; */

    :disabled {
        opacity: 0.4;
    }
    
`;



const Button = ({
    initWebSait,
    type='button',
    onClick=()=>{},
    disabled=false,
    children,
    line=false,
    style={}
}) => {

    // BUTTONS_STYLE
    // BUTTONS_FRAME_BG_COLOR

    return (
        <ButtonStyle 
            line={line}
            color={initWebSait?.BUTTONS_STYLE === 1 ? initWebSait?.BUTTONS_FRAME_BG_COLOR : color}
            radius={initWebSait?.BUTTONS_STYLE === 1 ? 0 : radius}
            type={type} 
            onClick={onClick}
            disabled={disabled}
            style={style}
        >{children}</ButtonStyle>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    initWebSait:state.user.initWebSait,
})

const mapDispatchToProps = {
    // onInitWebSait:userOperations.initWebSait,
}
export default connect(mapStateToProps, mapDispatchToProps)(Button);