import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { authSelectors } from '../auth';
import modelActions from './model-actions';


const url = 'https://whellli.com:8444/api/private/portal/wpa';
const resetToken = (state, dispatch) => {
    if(Boolean(state().auth.token)){
        // axios.defaults.headers.common.Authorization = `Bearer ${state().auth.token}`;

        axios.interceptors.request.use(config => {
            dispatch(modelActions.loader(true))
            config.headers.Authorization = `Bearer ${state().auth.token}`;
            return config;
        
        }, error => {
            dispatch(modelActions.loader(false))
            return Promise.reject(error);
        });
        
        axios.interceptors.response.use(response => {
            dispatch(modelActions.loader(false))
            return response;
        }, error => {
            dispatch(modelActions.loader(false))
            return Promise.reject(error);
        });
    }
}


const getProductFromLucene = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getProductFromLuceneRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_product_from_lucene",
            parameters:credential
        });
        dispatch(modelActions.getProductFromLuceneSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getProductFromLuceneError(error));
    }
}



export default {
    getProductFromLucene,
}
