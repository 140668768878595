import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { modelOperations } from "../../redux/model";
import { userOperations } from "../../redux/user";
import {DOMAIN} from './../../config/Url'

const RecordsOption = ({
    lang,
    onGetWidgetRecords,
    getWidgetRecords,
    widgetId:ID_WIDGET
}) => {

    const [list, setList] = useState([])

    useEffect(()=>{
        onGetWidgetRecords({DOMAIN, ID_WIDGET, DEFAULT_COLUMN:lang})
    }, [lang])

    useEffect(()=>{
        // console.log({getWidgetRecords});
        if(Object.keys(getWidgetRecords).length !== 0){
            const { RECORDS_WIDGET, ID_WIDGET:getWidgetId } = getWidgetRecords

            if(ID_WIDGET === getWidgetId){
                setList(RECORDS_WIDGET)
            }
        }
    }, [getWidgetRecords])

    // return (
    //     <h1>RecordsOption</h1>
    // ) 

    return ( 
        <>{ 
            list?.map(({INFO_RECORD, ID_RECORD})=>{
                return(<option key={ID_RECORD} value={ID_RECORD} >{Base64.decode(INFO_RECORD)} </option>)
            })
        }</>
    )


}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    getWidgetRecords:state.user.getWidgetRecords,
})
const mapDispatchToProps = {
    onGetWidgetRecords:userOperations.getWidgetRecords,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordsOption);