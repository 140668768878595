import {createAction} from '@reduxjs/toolkit';

const createFeedbackRequest = createAction('model/createFeedbackRequest')
const createFeedbackSuccess = createAction('model/createFeedbackSuccess')
const createFeedbackError = createAction('model/createFeedbackError')

const createFeedbackLikeRequest = createAction('model/createFeedbackLikeRequest')
const createFeedbackLikeSuccess = createAction('model/createFeedbackLikeSuccess')
const createFeedbackLikeError = createAction('model/createFeedbackLikeError')

const createFeedbackDislikeRequest = createAction('model/createFeedbackDislikeRequest')
const createFeedbackDislikeSuccess = createAction('model/createFeedbackDislikeSuccess')
const createFeedbackDislikeError = createAction('model/createFeedbackDislikeError')

export default{

    applyDiscountProductRequest:createAction('model/applyDiscountProductRequest'),
    applyDiscountProductSuccess:createAction('model/applyDiscountProductSuccess'),
    applyDiscountProductError:createAction('model/applyDiscountProductError'),

    userTransactionStripRequest:createAction('model/userTransactionStripRequest'),
    userTransactionStripSuccess:createAction('model/userTransactionStripSuccess'),
    userTransactionStripError:createAction('model/userTransactionStripError'),

    getUserStripClientSecretRequest:createAction('model/getUserStripClientSecretRequest'),
    getUserStripClientSecretSuccess:createAction('model/getUserStripClientSecretSuccess'),
    getUserStripClientSecretError:createAction('model/getUserStripClientSecretError'),

    getUserStripTokenRequest:createAction('model/getUserStripTokenRequest'),
    getUserStripTokenSuccess:createAction('model/getUserStripTokenSuccess'),
    getUserStripTokenError:createAction('model/getUserStripTokenError'),

    setErrorCode:createAction('model/setErrorCode'),

    userTransactionSquareupRequest:createAction('model/userTransactionSquareupRequest'),
    userTransactionSquareupSuccess:createAction('model/userTransactionSquareupSuccess'),
    userTransactionSquareupError:createAction('model/userTransactionSquareupError'),

    getUserSquareupSettingsRequest:createAction('model/getUserSquareupSettingsRequest'),
    getUserSquareupSettingsSuccess:createAction('model/getUserSquareupSettingsSuccess'),
    getUserSquareupSettingsError:createAction('model/getUserSquareupSettingsError'),

    getUserPaypalClientIdRequest:createAction('model/getUserPaypalClientIdRequest'),
    getUserPaypalClientIdSuccess:createAction('model/getUserPaypalClientIdSuccess'),
    getUserPaypalClientIdError:createAction('model/getUserPaypalClientIdError'),

    userTransactionPaypalRequest:createAction('model/userTransactionPaypalRequest'),
    userTransactionPaypalSuccess:createAction('model/userTransactionPaypalSuccess'),
    userTransactionPaypalError:createAction('model/userTransactionPaypalError'),

    logoutUserRequest:createAction('model/logoutUserRequest'),
    logoutUserSuccess:createAction('model/logoutUserSuccess'),
    logoutUserError:createAction('model/logoutUserError'),

    userTransactionSaleRequest:createAction('model/userTransactionSaleRequest'),
    userTransactionSaleSuccess:createAction('model/userTransactionSaleSuccess'),
    userTransactionSaleError:createAction('model/userTransactionSaleError'),

    getUserBraintreeTokenRequest:createAction('model/getUserBraintreeTokenRequest'),
    getUserBraintreeTokenSuccess:createAction('model/getUserBraintreeTokenSuccess'),
    getUserBraintreeTokenError:createAction('model/getUserBraintreeTokenError'),

    getUserDeliveryInfoByIdRequest:createAction('model/getUserDeliveryInfoByIdRequest'),
    getUserDeliveryInfoByIdSuccess:createAction('model/getUserDeliveryInfoByIdSuccess'),
    getUserDeliveryInfoByIdError:createAction('model/getUserDeliveryInfoByIdError'),

    getUserOrderByIdRequest:createAction('model/getUserOrderByIdRequest'),
    getUserOrderByIdSuccess:createAction('model/getUserOrderByIdSuccess'),
    getUserOrderByIdError:createAction('model/getUserOrderByIdError'),

    getUserOrdersHistoryRequest:createAction('model/getUserOrdersHistoryRequest'),
    getUserOrdersHistorySuccess:createAction('model/getUserOrdersHistorySuccess'),
    getUserOrdersHistoryError:createAction('model/getUserOrdersHistoryError'),

    // getProductsRequest:createAction('model/getProductsRequest'),
    // getProductsSuccess:createAction('model/getProductsSuccess'),
    // getProductsError:createAction('model/getProductsError'),

    checkoutCryptoOrderRequest:createAction('model/checkoutCryptoOrderRequest'),
    checkoutCryptoOrderSuccess:createAction('model/checkoutCryptoOrderSuccess'),
    checkoutCryptoOrderError:createAction('model/checkoutCryptoOrderError'),

    saveProceedToCheckoutRequest:createAction('model/saveProceedToCheckoutRequest'),
    saveProceedToCheckoutSuccess:createAction('model/saveProceedToCheckoutSuccess'),
    saveProceedToCheckoutError:createAction('model/saveProceedToCheckoutError'),

    proceedToCheckoutRequest:createAction('model/proceedToCheckoutRequest'),
    proceedToCheckoutSuccess:createAction('model/proceedToCheckoutSuccess'),
    proceedToCheckoutError:createAction('model/proceedToCheckoutError'),

    updateUserPasswordRequest:createAction('model/updateUserPasswordRequest'),
    updateUserPasswordSuccess:createAction('model/updateUserPasswordSuccess'),
    updateUserPasswordError:createAction('model/updateUserPasswordError'),

    updateUserProfileRequest:createAction('model/updateUserProfileRequest'),
    updateUserProfileSuccess:createAction('model/updateUserProfileSuccess'),
    updateUserProfileError:createAction('model/updateUserProfileError'),
    
    getUserProfileRequest:createAction('model/getUserProfileRequest'),
    getUserProfileSuccess:createAction('model/getUserProfileSuccess'),
    getUserProfileError:createAction('model/getUserProfileError'),

    getUserAddressRequest:createAction('model/getUserAddressRequest'),
    getUserAddressSuccess:createAction('model/getUserAddressSuccess'),
    getUserAddressError:createAction('model/getUserAddressError'),

    updateUserAddressRequest:createAction('model/updateUserAddressRequest'),
    updateUserAddressSuccess:createAction('model/updateUserAddressSuccess'),
    updateUserAddressError:createAction('model/updateUserAddressError'),

    getAddProductToCartRequest:createAction('model/getAddProductToCartRequest'),
    getAddProductToCartSuccess:createAction('model/getAddProductToCartSuccess'),
    getAddProductToCartError:createAction('model/getAddProductToCartError'),
    
    getCartRequest:createAction('model/getCartRequest'),
    getCartSuccess:createAction('model/getCartSuccess'),
    getCartError:createAction('model/getCartError'),
    getCartClear:createAction('model/getCartClear'),

    getUpdateProductQtyInCartRequest:createAction('model/getUpdateProductQtyInCartRequest'),
    getUpdateProductQtyInCartSuccess:createAction('model/getUpdateProductQtyInCartSuccess'),
    getUpdateProductQtyInCartError:createAction('model/getUpdateProductQtyInCartError'),

    getRemoveProductFromCartRequest:createAction('model/getRemoveProductFromCartRequest'),
    getRemoveProductFromCartSuccess:createAction('model/getRemoveProductFromCartSuccess'),
    getRemoveProductFromCartError:createAction('model/getRemoveProductFromCartError'),

    createFeedbackDislikeRequest,
    createFeedbackDislikeSuccess,
    createFeedbackDislikeError,
    
    createFeedbackLikeRequest,
    createFeedbackLikeSuccess,
    createFeedbackLikeError,

    createFeedbackRequest,
    createFeedbackSuccess,
    createFeedbackError,
}