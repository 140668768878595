import Sidebar from "../Sidebar";
import {useEffect, useRef} from "react";
import {useState} from "react";
import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";
import { userPrivateOperations } from "../../redux/userPrivate";
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'

import upload from './../../modules/upload'
import { authSelectors } from "../../redux/auth";
import Button from "../../components/Button/Button";

const INITIAL_STATE_USER = {
    EMAIL: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    PHOTO:""
}

const INITIAL_STATE_PASS = {
    CURRENT_PASSWORD:"",
    NEW_PASSWORD:"",
    CONFIRM_NEW_PASSWORD:""
}

// const Initial_Account = {
//     Firstname: "",
//     Lastname:"",
//     Email:"",
//     Displayname:"",
// }
const Account = ({
    errCode,
    onGetUserProfile,
    getUserProfile,
    onUpdateUserProfile,
    onUpdateUserPassword,
    getIsAuthenticated
}) => {
    const [rel, setRel] = useState(0)
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator())
    const simpleValidator2 = useRef(new SimpleReactValidator())

    const [showSuccess, setShowSuccess] = useState(false);

    // const saveAccount = () => {
    //     const formValid = simpleValidator.current.allValid()
    //     if (!formValid) {
    //         simpleValidator.current.showMessages()
    //         forceUpdate(1)
    //     }
    // }
    const [account, setAccount] = useState(INITIAL_STATE_USER)
    const [pass, setPass] = useState(INITIAL_STATE_PASS)

    const handleAccount = ({target: {name, value}}) => {
        setAccount(state => ({...state, [name]: value}))
    }
    const handlePass = ({target: {name, value}}) => {
        setPass(state => ({...state, [name]: value}))
    }
    


    useEffect(()=>{
        // console.log(getIsAuthenticated);
        if(getIsAuthenticated)
            onGetUserProfile({DOMAIN})
    }, [getIsAuthenticated])

    useEffect(()=>{
        if(Object.keys(getUserProfile).length !== 0){
            setAccount(getUserProfile)
        }
    }, [getUserProfile])

    const saveChange = () => {
        const formValid = simpleValidator.current.allValid()

        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)

            return
        }
        setRel(0)
        onUpdateUserProfile({DOMAIN, ...account})
    }

    const savePassword = () =>{
        const formValid = simpleValidator2.current.allValid()

        if (!formValid) {
            simpleValidator2.current.showMessages()
            forceUpdate(1)

            return
        }
        setRel(1)
        onUpdateUserPassword({
            DOMAIN,
            CURRENT_PASSWORD:pass.CURRENT_PASSWORD,
            NEW_PASSWORD:pass.NEW_PASSWORD
        })
    }

    const handleChangeFile = (e) =>{
        const files = e.target.files

        upload(files, ({uuid})=>{
            setAccount(state => ({...state, PHOTO: uuid}))
        })
    }

    return (
        <div className="container">

            <div className="text-center py-5">
                <h1>Account details</h1>
            </div>

            <div className="row mobile-view">
                <Sidebar/>
                <div className="px-5 col-md-10 form-parent">

                    <div className="accnt-details-img mb-4" style={{position:'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {account.PHOTO === '' && <span >Uploaded image</span>}
                        {account.PHOTO !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${account.PHOTO}/500`} style={{width:'100%', height:'100%', borderRadius:'100%'}} />}
                        <input type="file" className="upload" onChange={handleChangeFile} />
                    </div>
                    <div className="pb-4">
                        <div className="d-flex display-block pb-3">
                            <div className="pr-3 w-50 mobile-name">
                                <span>Name *</span>
                                <input
                                    type="text"
                                    onChange={handleAccount}
                                    placeholder="First Name"
                                    className="w-100 form-control px-4 checkout-input-small"
                                    name="FIRST_NAME"
                                    value={account.FIRST_NAME}
                                    onClick={()=>simpleValidator.current.showMessageFor('Firstname')} />
                                <div className="text-danger">
                                    {simpleValidator.current.message('Firstname', account.FIRST_NAME, 'required|alpha')}
                                </div>
                            </div>
                            <div className=" w-50 mobile-name">
                                <br></br>
                                <input
                                    type="text"
                                    onChange={handleAccount}
                                    placeholder="Last Name"
                                    className="w-100 form-control px-4 checkout-input-small"
                                    name="LAST_NAME"
                                    value={account.LAST_NAME}
                                    onClick={()=>simpleValidator.current.showMessageFor('Lastname')} />
                                <div className="text-danger">
                                    {simpleValidator.current.message('Lastname', account.LAST_NAME, 'required|alpha')}
                                </div>
                            </div>
                        </div>

                        <div className="py-2">
                            <span>Email address *</span><br></br>

                            <input
                                type="email"
                                className="form-control px-4 checkout-input-large"
                                name="EMAIL"
                                placeholder="Enter your email address"
                                onChange={handleAccount}
                                value={account.EMAIL}
                                onBlur={() => simpleValidator.current.showMessageFor('Email')}/>
                            <div className="text-danger">
                                {simpleValidator.current.message('Email', account.EMAIL, 'required|email')}
                            </div>
                        </div>
                        <div className="py-2">
                            <Button onClick={()=>saveChange()}>Save Changes</Button>
                        </div>
                        <div className="border-bottom">
                            <h4 className="py-2">Password change</h4>
                            {showSuccess && <div style={{color:"green"}}></div>}
                        </div>
                        <div className="pt-4">
                            <div className="py-2">
                                <span>Current password (leave blank for no change)</span><br></br>
                                <input className="form-control px-4 checkout-input-large" 
                                    type="password"
                                    name="CURRENT_PASSWORD"
                                    value={pass.CURRENT_PASSWORD}
                                    onChange={handlePass}
                                    onClick={()=>simpleValidator2.current.showMessageFor('current_password')}
                                />
                                <div className="text-danger">
                                    {simpleValidator2.current.message('current_password', pass.CURRENT_PASSWORD, 'required|alpha_num')}
                                </div>
                            </div>
                            <div className="py-2">
                                <span>New password (leave blank for no change)</span><br></br>
                                <input className="form-control px-4 checkout-input-large"
                                    type="password"
                                    name="NEW_PASSWORD"
                                    value={pass.NEW_PASSWORD}
                                    onChange={handlePass}
                                    onClick={()=>simpleValidator2.current.showMessageFor('new_password')}
                                />
                                <div className="text-danger">
                                    {simpleValidator2.current.message('new_password', pass.NEW_PASSWORD, 'required|alpha_num')}
                                </div>
                            </div>
                            <div className="py-2">
                                <span>Confirm new password</span><br></br>
                                <input className="form-control px-4 checkout-input-large"
                                    type="password"
                                    name="CONFIRM_NEW_PASSWORD"
                                    value={pass.CONFIRM_NEW_PASSWORD}
                                    onChange={handlePass}
                                    onClick={()=>simpleValidator2.current.showMessageFor('confirm_password')}
                                />
                                <div className="text-danger">
                                {simpleValidator2.current.message('confirm_password', pass.CONFIRM_NEW_PASSWORD, `required|in:${pass.NEW_PASSWORD}`, {messages: {in: 'Passwords need to match!'}})}
                                </div>
                            </div>

                        </div>
                        <div className="py-2">
                            <Button onClick={()=>{savePassword()}}>Save Password</Button>
                        </div>
                        {rel === 1 && [-1, 1, 0, 18].indexOf(errCode) === -1 && <div style={{color:'red'}}>Error code: {errCode}</div>}
                        {rel === 1 && errCode === 18 && <div style={{color:'red'}} >Incorrect Current password</div>}
                </div>



            </div>
        </div>

</div>
    )
}
const mapStateToProps = (state) => ({
    lang: state.lang.LANG,
    errCode: state.userPrivate.err_code,
    getUserProfile: state.userPrivate.getUserProfile,
    getIsAuthenticated:authSelectors.getIsAuthenticated(state),
})

const mapDispatchToProps = {
    onGetUserProfile: userPrivateOperations.getUserProfile,
    onUpdateUserProfile: userPrivateOperations.updateUserProfile,
    onUpdateUserPassword: userPrivateOperations.updateUserPassword,
}
export default connect(mapStateToProps, mapDispatchToProps)(Account);
