import { Base64 } from "js-base64";
import { useState } from "react";
import { useEffect } from "react"
import { connect } from "react-redux";
import { useRouteMatch } from "react-router";
import { authActions } from "../../redux/auth";
import { modelOperations } from "../../redux/model";

// import {WEB_PICTURES_FILE} from './../../config/Url'
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from './../../config/Url'
import noImage from './../../assets/img/no_image.png' 
import RecordsOption from "../../components/RecordsOption/RecordsOption";

const Page = ({
    lang,
    onSetToken,
    onGetProductFromLucene,
    getProductFromLucene,
    rate
}) => {

    const match = useRouteMatch()

    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [group1, setGroup1] = useState({})
    const [group2, setGroup2] = useState({})
    const [group3, setGroup3] = useState({})
    const [group4, setGroup4] = useState({})
    const [group5, setGroup5] = useState({})
    const [categories, setCategories] = useState([])
    const [images, setImages] = useState([])
    const [imageId, setImageId] = useState(0)
    const [totalPrice, setTotalPrice] = useState([])
    const [measure, setMeasure] = useState({});
    const [priceOptions, setPriceOptions] = useState([])

    const [view, setView] = useState(0)
    const [middelaRate, setMiddelaRate] = useState(0)

    useEffect(()=>{
        // console.log(match);
        const id = Number(match?.params?.id)
        const token = match?.params?.token

        console.log({id});

        if(token) onSetToken(Base64.decode(token))

       
        if(id)
            onGetProductFromLucene({ID_ITEM:id, DEFAULT_COLUMN:lang})


    }, [])

    useEffect(()=>{
        console.log(getProductFromLucene);
        if(Object.keys(getProductFromLucene).length !== 0){
            const {
                NAME,
                USE_DESCRIPTION,
                ATTRIBUTES_GROUP_1,
                ATTRIBUTES_GROUP_2,
                ATTRIBUTES_GROUP_3,
                ATTRIBUTES_GROUP_4,
                ATTRIBUTES_GROUP_5,
                CATEGORIES,
                IMAGES:{GALLERY_MAIN},
                TOTAL_PRICE,
                MEASURE,
                PRICING_OPTIONS,
                MIDDLE_RATE, 
                REVIEWS_QTY,
            } = getProductFromLucene

            setTitle(Base64.decode(NAME))
            setDesc(Base64.decode(USE_DESCRIPTION))
            setGroup1(ATTRIBUTES_GROUP_1)
            setGroup2(ATTRIBUTES_GROUP_2)
            setGroup3(ATTRIBUTES_GROUP_3)
            setGroup4(ATTRIBUTES_GROUP_4)
            setGroup5(ATTRIBUTES_GROUP_5)
            setCategories(CATEGORIES)
            setImages(GALLERY_MAIN)

            setTotalPrice(TOTAL_PRICE)
            setMeasure(MEASURE)
            setPriceOptions(PRICING_OPTIONS)

            setImageId(!GALLERY_MAIN[0]?.ID_IMAGE ? 0 : GALLERY_MAIN[0].ID_IMAGE)

            setView(REVIEWS_QTY)
            setMiddelaRate(MIDDLE_RATE)
        }
    }, [getProductFromLucene])


    return (
        <>
                        <div className="pt-5 pb-4">
                <span>Home / Legal Cannabis / CBD Flowers / Indiana</span>
            </div>
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            {/* <img src="assets/images/secong-product-page.png" className="second-product-img" /> */}
                            {
                                images.filter(({ID_IMAGE})=>ID_IMAGE===imageId).map(({IMAGE, ID_IMAGE}, k)=>{
                                    return(<img key={k} src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/300`} className="second-product-img" />)
                                })
                            }

                            <div className="d-flex w-100 pt-3 " style={{flexWrap: 'wrap', justifyContent: 'center'}}>
                                {
                                    images.map(({IMAGE, ID_IMAGE}, k)=>(
                                        <div key={k} className="px-2 ">
                                            <button className="border-0 bg-transparent select-img-btn" onClick={()=>setImageId(ID_IMAGE)}>
                                                <div className="bottom-image" style={{backgroundImage:`url(${WEB_PICTURES_FILE_RESIZER}${IMAGE}/70)`, width:70, height:70}}></div>
                                             
                                            </button>
                                        </div>
                                    ))
                                }
                             
                            </div>
    
                        </div>
                    </div>
                    <div className="col-md-6 pt-5">
                        <div>
                            <div>
                                <h3 className="text-theme font-weight-bolder">{categories.map(({INFO_RECORD})=>Base64.decode(INFO_RECORD)).join(', ')}</h3>
                            </div>
                            <div>
                                <h1>{title}</h1>
                            </div>
                            <div className="border-bottom pb-4">
                            <span>
                                <i className="fa fa-star" style={{color: middelaRate >= 1 ? '#915674' : '#ffc107' }}></i>
                                <i className="fa fa-star" style={{color: middelaRate >= 2 ? '#915674' : '#ffc107' }}></i>
                                <i className="fa fa-star" style={{color: middelaRate >= 3 ? '#915674' : '#ffc107' }}></i>
                                <i className="fa fa-star" style={{color: middelaRate >= 4 ? '#915674' : '#ffc107' }}></i>
                                <i className="fa fa-star" style={{color: middelaRate >= 5 ? '#915674' : '#ffc107' }}></i>
                                {' '}{view} reviews
                            </span>
                            </div>

                                {
                                    group1.WIDGETS?.map(({RECORDS_WIDGET, INFO_WIDGET, TYPE_WIDGET, MEASURE:{ID, NAME}}, k)=>{
                                        // if(RECORDS_WIDGET.length !== 0)
                                        return(<div key={k} className="pb-4">
                                                    <div className="font-weight-bolder second-product-hdngs">{Base64.decode(INFO_WIDGET)} <span style={{fontWeight:100, fontSize:16}}>{ID !== 0 && `(${Base64.decode(NAME)})`}</span></div>
                                                    <div>{TYPE_WIDGET === 1 ? RECORDS_WIDGET.map(({VALUE})=>{return(VALUE)}).join(', ') : (RECORDS_WIDGET.length === 0 ? '-' : RECORDS_WIDGET.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', '))}</div>
                                                </div>)
                                    })
                                }

                           
                        </div>
                    </div>
                </div>
    
                <div className="text-center d-flex center-margin">
                        {
                            group2.WIDGETS?.map(({RECORDS_WIDGET, INFO_WIDGET, TYPE_WIDGET, MEASURE:{ID, NAME}}, k)=>{
                                // if(RECORDS_WIDGET.length !== 0)
                                return(<div key={k} className="px-3">
                                            <div className="font-weight-bolder font-size-custom">{Base64.decode(INFO_WIDGET)}</div>
                                            <div className="circle-second-product">
                                                <p className="text-white font-weight-bolder pt-4 pl-1">{TYPE_WIDGET === 1 ? RECORDS_WIDGET.map(({VALUE})=>{return(VALUE)}).join(', ') : (RECORDS_WIDGET.length === 0 ? '-' : RECORDS_WIDGET.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', '))} {ID !== 0 && Base64.decode(NAME)}</p>
                                            </div>
                                        </div>)
                            })
                        }
                   
                </div>
            </div>
            <div className="d-flex pb-5 mb-4 pt-5 mt-5 center-margin display-block">
                {
                    group3.WIDGETS?.map(({RECORDS_WIDGET, INFO_WIDGET, TYPE_WIDGET, IMAGE_WIDGET, MEASURE:{ID, NAME}}, k)=>{
                        // if(RECORDS_WIDGET.length !== 0)
                        return(<div className="px-4" key={k}>
                                    <div className=" total-fancy-div">
                                        <div className="fancy-pic-body">
                                            {/* <img src="assets/images/fancy-one.png" className="fancy-pic" /> */}
                                            {IMAGE_WIDGET !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE_WIDGET}/70`} className="fancy-pic" />}
                                            {IMAGE_WIDGET === '' && <img src={noImage} className="fancy-pic" />}
                                        </div>
                                        <div className="fancy-img-inner-text-div">
                                            <div>
                                                <span>{Base64.decode(INFO_WIDGET)}</span>
                                            </div>
                                            <div>
                                                <span className="color-mehroon font-weight-bolder">{TYPE_WIDGET === 1 ? RECORDS_WIDGET.map(({VALUE})=>{return(VALUE)}).join(', ') : (RECORDS_WIDGET.length === 0 ? '-' : RECORDS_WIDGET.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', '))} {ID !== 0 && Base64.decode(NAME)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                    })
                }
              
            </div>
            {/* priceOptions */}
            {priceOptions.length !== 0 && 
                <div className="center-margin">
                    <div className="py-2 font-weight-normal">Select Pack Size</div>
                    <div className="d-flex">
                        {
                            priceOptions.map(({RECORDS_WIDGET, MEASURE:{ID, NAME}}, k)=>(
                                <div key={k} className="forty-circle-div my-2" style={{marginRight:12}}>
                                    <p className="pt-1 pl-1">{RECORDS_WIDGET.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', ')}{ID !== 0 && Base64.decode(NAME)}</p>
                                </div> 
                            ))
                        }
                    </div>
                </div>
            }
            <div className="center-margin">
                <div className="border-bottom pb-4">
                    <div className="pb-4">
                        <span className="font-weight-bolder heading-font">{rate} {totalPrice.filter(({RATE_SHORT_NAME})=> RATE_SHORT_NAME === rate).map(({PRICE})=>(Number(PRICE.toFixed(2))))}</span> {' '}
                        {/* (CHF 1.00 per unit) */}
                        {Object.keys(measure).length !== 0 && measure.ID !== 0 && `(per ${Base64.decode(measure.NAME)})`}
                    </div>
                    <div className="d-flex pb-4 display-block">
                        <div className="plus-minus-div pl-3 mr-3">
                            <a href="#" className="plus-minus-size px-2 text-decoration-none">-</a> <span
                                className="plus-minus-size px-2 font-weight-bolder">1</span> <a href="#"
                                                                                            className="plus-minus-size px-2 text-decoration-none">+</a>
    
                        </div>
                        <div>
                            <button className="long-cart-btn mx-3">Add to Cart</button>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="pr-2">
                            <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 >
                                <rect width="33" height="24" fill="url(#pattern0)"/>
                                <defs>
                                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                        <use  transform="scale(0.030303 0.0416667)"/>
                                    </pattern>
                                    {/* <image id="image0" width="33" height="24"
                                           xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAYCAYAAAB0kZQKAAAEC0lEQVRIDcVW/W7iRhDnMfJ/qyYF/CVjIA0yppeL8s+9Cv+d1EppLw6cAw7FmA+nCTkcbC9rG+fySPsMfoKpllyInUCgVU61NPJ4d3bmN7Pz4UxmxVNwDUUKT5AQncS5aAB74XWcjYbox/mFskL87Zdq9hBVXBNk/DspB7+Zuair/hRZ6t5dn/z8tQO79w309lYTGuVpD8nTMdRu+/XE8pLNRu26GGpQxmffB8ih3VBk14TaZJgCkJ83lN37DnlEIuFmvRhoUMCNt7+aDzcNdGxrS2PUaMn/kzBRI+bRZ/4RBH3n7tokF128fTSO7fP4/URTk8Z+wZ/IAf70wlh+3jVzYX8BmA0uCRdcAudfvSA2HEH2ziDPnUjaSPH7aAAlx0qBSAkkPkpeXxXxJdAlwb8EHluIxwPlOeXxQMmFI8KEvReOJNQ9sUXPiiVnshWIA6evFr2/40cQ4qy/9hyLh3Va5tnwdOfJ2hru6MZCsn2byok1opnirE9EZC28oyGXZq21IKgONjSIOD811+lbrh+PDaUytaF866SqYynwjandtusl3IGy+9C42LkJpVnzVRBMqKuFoBFL9sfN0eDxFSp4U5CcyUogR7ftuow0kL2nhiUGnY0gsra+wwcGkdBfK/U+dzQjohskzKbA4WvChJaaDS2VDYZmEfdIxevAr9PPqSQr+vpGENSIiAeIxyNSdgcKjSLtM69GhnfHiuRNUNH9Ej+U3ohIuIcq9svZUcLbgZBsfYcJhrHgj0D0B1AIdKBNr4y17aLzIlyJhSLefB0J8RQroQuTC/pxRkYqkdEZVGZncICbsI9bQEMsBvoC7T7WoORrwEUaCHMNqq4O76Yd2EcGiL4JNDHLuPmfvCm7hkKjkhHCDoi4Xa+4DUWxT5XDBZ0oFfeBDu0ThVIZU6L7jQWV3XOlgM8V+la2yfpUDB4+liDydzpQRStkvvsS7bA0RzL5iNb8/wOCtnUuGNHrMKDwrfEkXZednik7ZlybGiA7F5u7XfJwgq86TbPqNaGKTqHq/ZHS8xCJS8iItGTwsM67A4VHo8XIFrxrVcBjoM1LxBaiycPO/z0QaaarYmAAH3YRH16ggt8BadZVJWTwPDYUAQ/ri+vg8Rhx/hjYYAy58GrRiDjfink8XDYlPtSREOiLgZVwciNbnJmxmNDD4ivEBFbMhEMkBINFdYl4zYTlAnr4aZONWogPz4nonxNawhXUAtlrQdVtwTunBTVHhwOkA+2oR/bTX1gRmTEd849oGTxB2eB6qyGZEX1NpaET/S5iwy7i5h1govQPz6Pi196S11XzkQm7kYUYfIM4/wvQUfDamdSehNumiA3CBT3ChN3tD6a0ZDJ7X7vqD/cjkguuCTO7WannH7IkRGhzIPEBAAAAAElFTkSuQmCC"/> */}
                                </defs>
                            </svg>
                        </div>
                        <div className="px-2">
                            <p>Order <strong>until Monday 15:00</strong> and receive it <strong>on Tuesday</strong></p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="pr-2">
                            <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 >
                                <rect width="33" height="24" fill="url(#pattern0)"/>
                                <defs>
                                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                        <use  transform="scale(0.030303 0.0416667)"/>
                                    </pattern>
                                    {/* <image id="image0" width="33" height="24"
                                           xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAYCAYAAAB0kZQKAAAEC0lEQVRIDcVW/W7iRhDnMfJ/qyYF/CVjIA0yppeL8s+9Cv+d1EppLw6cAw7FmA+nCTkcbC9rG+fySPsMfoKpllyInUCgVU61NPJ4d3bmN7Pz4UxmxVNwDUUKT5AQncS5aAB74XWcjYbox/mFskL87Zdq9hBVXBNk/DspB7+Zuair/hRZ6t5dn/z8tQO79w309lYTGuVpD8nTMdRu+/XE8pLNRu26GGpQxmffB8ih3VBk14TaZJgCkJ83lN37DnlEIuFmvRhoUMCNt7+aDzcNdGxrS2PUaMn/kzBRI+bRZ/4RBH3n7tokF128fTSO7fP4/URTk8Z+wZ/IAf70wlh+3jVzYX8BmA0uCRdcAudfvSA2HEH2ziDPnUjaSPH7aAAlx0qBSAkkPkpeXxXxJdAlwb8EHluIxwPlOeXxQMmFI8KEvReOJNQ9sUXPiiVnshWIA6evFr2/40cQ4qy/9hyLh3Va5tnwdOfJ2hru6MZCsn2byok1opnirE9EZC28oyGXZq21IKgONjSIOD811+lbrh+PDaUytaF866SqYynwjandtusl3IGy+9C42LkJpVnzVRBMqKuFoBFL9sfN0eDxFSp4U5CcyUogR7ftuow0kL2nhiUGnY0gsra+wwcGkdBfK/U+dzQjohskzKbA4WvChJaaDS2VDYZmEfdIxevAr9PPqSQr+vpGENSIiAeIxyNSdgcKjSLtM69GhnfHiuRNUNH9Ej+U3ohIuIcq9svZUcLbgZBsfYcJhrHgj0D0B1AIdKBNr4y17aLzIlyJhSLefB0J8RQroQuTC/pxRkYqkdEZVGZncICbsI9bQEMsBvoC7T7WoORrwEUaCHMNqq4O76Yd2EcGiL4JNDHLuPmfvCm7hkKjkhHCDoi4Xa+4DUWxT5XDBZ0oFfeBDu0ThVIZU6L7jQWV3XOlgM8V+la2yfpUDB4+liDydzpQRStkvvsS7bA0RzL5iNb8/wOCtnUuGNHrMKDwrfEkXZednik7ZlybGiA7F5u7XfJwgq86TbPqNaGKTqHq/ZHS8xCJS8iItGTwsM67A4VHo8XIFrxrVcBjoM1LxBaiycPO/z0QaaarYmAAH3YRH16ggt8BadZVJWTwPDYUAQ/ri+vg8Rhx/hjYYAy58GrRiDjfink8XDYlPtSREOiLgZVwciNbnJmxmNDD4ivEBFbMhEMkBINFdYl4zYTlAnr4aZONWogPz4nonxNawhXUAtlrQdVtwTunBTVHhwOkA+2oR/bTX1gRmTEd849oGTxB2eB6qyGZEX1NpaET/S5iwy7i5h1govQPz6Pi196S11XzkQm7kYUYfIM4/wvQUfDamdSehNumiA3CBT3ChN3tD6a0ZDJ7X7vqD/cjkguuCTO7WannH7IkRGhzIPEBAAAAAElFTkSuQmCC"/> */}
                                </defs>
                            </svg>
                        </div>
                        <div className="px-2">
                            <p><strong>FREE Shipping </strong>on all orders over CHF 49.00</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="pr-2">
                            <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 >
                                <rect width="33" height="24" fill="url(#pattern0)"/>
                                <defs>
                                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                        <use  transform="scale(0.030303 0.0416667)"/>
                                    </pattern>
                                    {/* <image id="image0" width="33" height="24"
                                           xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAYCAYAAAB0kZQKAAAEC0lEQVRIDcVW/W7iRhDnMfJ/qyYF/CVjIA0yppeL8s+9Cv+d1EppLw6cAw7FmA+nCTkcbC9rG+fySPsMfoKpllyInUCgVU61NPJ4d3bmN7Pz4UxmxVNwDUUKT5AQncS5aAB74XWcjYbox/mFskL87Zdq9hBVXBNk/DspB7+Zuair/hRZ6t5dn/z8tQO79w309lYTGuVpD8nTMdRu+/XE8pLNRu26GGpQxmffB8ih3VBk14TaZJgCkJ83lN37DnlEIuFmvRhoUMCNt7+aDzcNdGxrS2PUaMn/kzBRI+bRZ/4RBH3n7tokF128fTSO7fP4/URTk8Z+wZ/IAf70wlh+3jVzYX8BmA0uCRdcAudfvSA2HEH2ziDPnUjaSPH7aAAlx0qBSAkkPkpeXxXxJdAlwb8EHluIxwPlOeXxQMmFI8KEvReOJNQ9sUXPiiVnshWIA6evFr2/40cQ4qy/9hyLh3Va5tnwdOfJ2hru6MZCsn2byok1opnirE9EZC28oyGXZq21IKgONjSIOD811+lbrh+PDaUytaF866SqYynwjandtusl3IGy+9C42LkJpVnzVRBMqKuFoBFL9sfN0eDxFSp4U5CcyUogR7ftuow0kL2nhiUGnY0gsra+wwcGkdBfK/U+dzQjohskzKbA4WvChJaaDS2VDYZmEfdIxevAr9PPqSQr+vpGENSIiAeIxyNSdgcKjSLtM69GhnfHiuRNUNH9Ej+U3ohIuIcq9svZUcLbgZBsfYcJhrHgj0D0B1AIdKBNr4y17aLzIlyJhSLefB0J8RQroQuTC/pxRkYqkdEZVGZncICbsI9bQEMsBvoC7T7WoORrwEUaCHMNqq4O76Yd2EcGiL4JNDHLuPmfvCm7hkKjkhHCDoi4Xa+4DUWxT5XDBZ0oFfeBDu0ThVIZU6L7jQWV3XOlgM8V+la2yfpUDB4+liDydzpQRStkvvsS7bA0RzL5iNb8/wOCtnUuGNHrMKDwrfEkXZednik7ZlybGiA7F5u7XfJwgq86TbPqNaGKTqHq/ZHS8xCJS8iItGTwsM67A4VHo8XIFrxrVcBjoM1LxBaiycPO/z0QaaarYmAAH3YRH16ggt8BadZVJWTwPDYUAQ/ri+vg8Rhx/hjYYAy58GrRiDjfink8XDYlPtSREOiLgZVwciNbnJmxmNDD4ivEBFbMhEMkBINFdYl4zYTlAnr4aZONWogPz4nonxNawhXUAtlrQdVtwTunBTVHhwOkA+2oR/bTX1gRmTEd849oGTxB2eB6qyGZEX1NpaET/S5iwy7i5h1govQPz6Pi196S11XzkQm7kYUYfIM4/wvQUfDamdSehNumiA3CBT3ChN3tD6a0ZDJ7X7vqD/cjkguuCTO7WannH7IkRGhzIPEBAAAAAElFTkSuQmCC"/> */}
                                </defs>
                            </svg>
                        </div>
                        <div className="px-2">
                            <p>Earn <strong>40 points </strong>in the uWeed Club</p>
                        </div>
                    </div>
    
                </div>
                <div className="d-flex border-bottom py-4">
                    <div className="w-75">
                        <span>Share this product on:</span>
                    </div>
                    <div className="w-25">
                    <span className="pull-right">
                        <a href="#" className="text-decoration-none text-dark"><i className="fa fa-facebook fa-lg px-2"></i></a>
                    <a href="#" className="text-decoration-none text-dark"><i className="fa fa-instagram fa-lg px-2"></i></a>
                    <a href="#" className="text-decoration-none text-dark"><i className="fa fa-twitter fa-lg px-2"></i></a>
                    </span>
    
                    </div>
                </div>
                <div className=" border-bottom py-4">
                    <div className="d-flex">
                        <div className="w-75">
                            <span className="font-weight-bolder font-size-custom">Description</span>
                        </div>
                        <div className="w-25">
                            <a href="#" className="text-decoration-none text-dark">
                                <span className="pull-right"><i className="fa fa-plus"></i></span>
                            </a>
                        </div>
                    </div>
                    <p>{desc}</p>
                </div>
                <div className="d-flex border-bottom py-4">
                    <div className="w-75">
                        <span className="font-weight-bolder font-size-custom">Ingredients</span>
                    </div>
                    <div className="w-25">
                        <a href="#" className="text-decoration-none text-dark">
                            <span className="pull-right"><i className="fa fa-plus"></i></span>
                        </a>
                    </div>
                </div>
                <div className="d-flex border-bottom py-4">
                    <div className="w-75">
                        <span className="font-weight-bolder font-size-custom">Storage</span>
                    </div>
                    <div className="w-25">
                        <a href="#" className="text-decoration-none text-dark">
                            <span className="pull-right"><i className="fa fa-plus"></i></span>
                        </a>
                    </div>
                </div>
                <div className="d-flex border-bottom py-4">
                    <div className="w-50">
                        <span className="font-weight-bolder font-size-custom">Legal Disclaimer</span>
                    </div>
                    <div className="w-50">
                        <a href="#" className="text-decoration-none text-dark">
                            <span className="pull-right"><i className="fa fa-plus"></i></span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="text-center pt-5">
                <h1 className="pb-5">Good to know about this product</h1>
                <div className="row text-center">
                    
                {
                    group4.WIDGETS?.map(({RECORDS_WIDGET, INFO_WIDGET, TYPE_WIDGET, IMAGE_WIDGET, MEASURE:{ID, NAME}}, k)=>{
                        if(RECORDS_WIDGET.length !== 0)
                        return(<div className="col-md-2 text-center" key={k}>
                                    {IMAGE_WIDGET !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE_WIDGET}/80`} style={{width:80}} />}
                                    {IMAGE_WIDGET === '' && <img src={noImage} style={{width:80}} />}
                
                                    <div className="font-weight-bolder py-4">
                                    {TYPE_WIDGET === 1 ? RECORDS_WIDGET.map(({VALUE})=>{return(VALUE)}).join(', ') : (RECORDS_WIDGET.length === 0 ? '-' : RECORDS_WIDGET.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', '))} {ID !== 0 && Base64.decode(NAME)}
                                    </div>
                
                                </div>)
                    })
                }
                </div>
            </div>
            <div className="pt-5 mb-4  pb-5">
                <h1 className="font-weight-bolder">{view} User Reviews</h1>
                <div className="d-flex pt-5 display-block">
                    <div className="px-5 padding-zero-small">
                    <span>
                        <h2 className="font-weight-bolder">{middelaRate}</h2>
                        <i className="fa fa-star" style={{color: middelaRate >= 1 ? '#915674' : '#ffc107' }}></i>
                        <i className="fa fa-star" style={{color: middelaRate >= 2 ? '#915674' : '#ffc107' }}></i>
                        <i className="fa fa-star" style={{color: middelaRate >= 3 ? '#915674' : '#ffc107' }}></i>
                        <i className="fa fa-star" style={{color: middelaRate >= 4 ? '#915674' : '#ffc107' }}></i>
                        <i className="fa fa-star" style={{color: middelaRate >= 5 ? '#915674' : '#ffc107' }}></i>
                                        
                    </span>
                        <span> Based on {view} Reviews</span>
                    </div>
                    <div className="px-5 padding-zero-small">
                        <svg width="299" height="117" viewBox="0 0 299 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M275.561 9.16504C275.561 7.95736 275.736 6.82715 276.087 5.77441C276.442 4.72168 276.953 3.79883 277.618 3.00586H278.726C278.069 3.88542 277.575 4.85156 277.242 5.9043C276.914 6.95703 276.75 8.03939 276.75 9.15137C276.75 10.2451 276.919 11.3138 277.256 12.3574C277.593 13.401 278.078 14.3535 278.712 15.2148H277.618C276.948 14.4401 276.438 13.5355 276.087 12.501C275.736 11.4665 275.561 10.3545 275.561 9.16504ZM284.03 13H282.923V5.87695C282.923 5.28451 282.941 4.72396 282.978 4.19531C282.882 4.29102 282.775 4.39128 282.656 4.49609C282.538 4.60091 281.995 5.04525 281.029 5.8291L280.428 5.0498L283.073 3.00586H284.03V13ZM294.462 7.98926C294.462 9.71647 294.188 11.0062 293.642 11.8584C293.099 12.7106 292.268 13.1367 291.146 13.1367C290.071 13.1367 289.253 12.7015 288.692 11.8311C288.132 10.9561 287.852 9.67546 287.852 7.98926C287.852 6.24837 288.123 4.95638 288.665 4.11328C289.207 3.27018 290.035 2.84863 291.146 2.84863C292.231 2.84863 293.054 3.28841 293.614 4.16797C294.179 5.04753 294.462 6.32129 294.462 7.98926ZM289 7.98926C289 9.44303 289.171 10.5026 289.513 11.168C289.854 11.8288 290.399 12.1592 291.146 12.1592C291.903 12.1592 292.45 11.8242 292.787 11.1543C293.129 10.4798 293.3 9.4248 293.3 7.98926C293.3 6.55371 293.129 5.50326 292.787 4.83789C292.45 4.16797 291.903 3.83301 291.146 3.83301C290.399 3.83301 289.854 4.16341 289.513 4.82422C289.171 5.48047 289 6.53548 289 7.98926ZM298.748 9.16504C298.748 10.3636 298.57 11.4801 298.215 12.5146C297.864 13.5492 297.356 14.4492 296.69 15.2148H295.597C296.23 14.3581 296.715 13.4079 297.053 12.3643C297.39 11.3161 297.559 10.2451 297.559 9.15137C297.559 8.03939 297.392 6.95703 297.06 5.9043C296.731 4.85156 296.239 3.88542 295.583 3.00586H296.69C297.36 3.80339 297.871 4.73079 298.222 5.78809C298.573 6.84082 298.748 7.96647 298.748 9.16504Z"
                                  fill="#4A4A4A"/>
                            <path d="M275.561 36.165C275.561 34.9574 275.736 33.8271 276.087 32.7744C276.442 31.7217 276.953 30.7988 277.618 30.0059H278.726C278.069 30.8854 277.575 31.8516 277.242 32.9043C276.914 33.957 276.75 35.0394 276.75 36.1514C276.75 37.2451 276.919 38.3138 277.256 39.3574C277.593 40.401 278.078 41.3535 278.712 42.2148H277.618C276.948 41.4401 276.438 40.5355 276.087 39.501C275.736 38.4665 275.561 37.3545 275.561 36.165ZM286.02 32.3574C286.02 32.9954 285.84 33.5173 285.479 33.9229C285.124 34.3285 284.618 34.5996 283.962 34.7363V34.791C284.764 34.8913 285.359 35.1465 285.746 35.5566C286.133 35.9668 286.327 36.5046 286.327 37.1699C286.327 38.1224 285.997 38.8561 285.336 39.3711C284.675 39.8815 283.736 40.1367 282.52 40.1367C281.991 40.1367 281.506 40.0957 281.063 40.0137C280.626 39.9362 280.2 39.7972 279.785 39.5967V38.5166C280.218 38.7308 280.678 38.8949 281.166 39.0088C281.658 39.1182 282.123 39.1729 282.561 39.1729C284.288 39.1729 285.151 38.4961 285.151 37.1426C285.151 35.9303 284.199 35.3242 282.294 35.3242H281.31V34.3467H282.308C283.087 34.3467 283.704 34.1758 284.16 33.834C284.616 33.4876 284.844 33.0091 284.844 32.3984C284.844 31.9108 284.675 31.528 284.338 31.25C284.005 30.972 283.552 30.833 282.978 30.833C282.54 30.833 282.128 30.8923 281.74 31.0107C281.353 31.1292 280.911 31.348 280.414 31.667L279.84 30.9014C280.25 30.5778 280.722 30.3249 281.255 30.1426C281.793 29.9557 282.358 29.8623 282.95 29.8623C283.921 29.8623 284.675 30.0856 285.213 30.5322C285.751 30.9743 286.02 31.5827 286.02 32.3574ZM290.736 36.165C290.736 37.3636 290.559 38.4801 290.203 39.5146C289.852 40.5492 289.344 41.4492 288.679 42.2148H287.585C288.218 41.3581 288.704 40.4079 289.041 39.3643C289.378 38.3161 289.547 37.2451 289.547 36.1514C289.547 35.0394 289.381 33.957 289.048 32.9043C288.72 31.8516 288.228 30.8854 287.571 30.0059H288.679C289.349 30.8034 289.859 31.7308 290.21 32.7881C290.561 33.8408 290.736 34.9665 290.736 36.165Z"
                                  fill="#4A4A4A"/>
                            <path d="M275.561 59.165C275.561 57.9574 275.736 56.8271 276.087 55.7744C276.442 54.7217 276.953 53.7988 277.618 53.0059H278.726C278.069 53.8854 277.575 54.8516 277.242 55.9043C276.914 56.957 276.75 58.0394 276.75 59.1514C276.75 60.2451 276.919 61.3138 277.256 62.3574C277.593 63.401 278.078 64.3535 278.712 65.2148H277.618C276.948 64.4401 276.438 63.5355 276.087 62.501C275.736 61.4665 275.561 60.3545 275.561 59.165ZM286.45 57.9893C286.45 59.7165 286.177 61.0062 285.63 61.8584C285.088 62.7106 284.256 63.1367 283.135 63.1367C282.059 63.1367 281.241 62.7015 280.681 61.8311C280.12 60.9561 279.84 59.6755 279.84 57.9893C279.84 56.2484 280.111 54.9564 280.653 54.1133C281.196 53.2702 282.023 52.8486 283.135 52.8486C284.219 52.8486 285.042 53.2884 285.603 54.168C286.168 55.0475 286.45 56.3213 286.45 57.9893ZM280.988 57.9893C280.988 59.443 281.159 60.5026 281.501 61.168C281.843 61.8288 282.387 62.1592 283.135 62.1592C283.891 62.1592 284.438 61.8242 284.775 61.1543C285.117 60.4798 285.288 59.4248 285.288 57.9893C285.288 56.5537 285.117 55.5033 284.775 54.8379C284.438 54.168 283.891 53.833 283.135 53.833C282.387 53.833 281.843 54.1634 281.501 54.8242C281.159 55.4805 280.988 56.5355 280.988 57.9893ZM290.736 59.165C290.736 60.3636 290.559 61.4801 290.203 62.5146C289.852 63.5492 289.344 64.4492 288.679 65.2148H287.585C288.218 64.3581 288.704 63.4079 289.041 62.3643C289.378 61.3161 289.547 60.2451 289.547 59.1514C289.547 58.0394 289.381 56.957 289.048 55.9043C288.72 54.8516 288.228 53.8854 287.571 53.0059H288.679C289.349 53.8034 289.859 54.7308 290.21 55.7881C290.561 56.8408 290.736 57.9665 290.736 59.165Z"
                                  fill="#4A4A4A"/>
                            <path d="M275.561 85.165C275.561 83.9574 275.736 82.8271 276.087 81.7744C276.442 80.7217 276.953 79.7988 277.618 79.0059H278.726C278.069 79.8854 277.575 80.8516 277.242 81.9043C276.914 82.957 276.75 84.0394 276.75 85.1514C276.75 86.2451 276.919 87.3138 277.256 88.3574C277.593 89.401 278.078 90.3535 278.712 91.2148H277.618C276.948 90.4401 276.438 89.5355 276.087 88.501C275.736 87.4665 275.561 86.3545 275.561 85.165ZM286.45 83.9893C286.45 85.7165 286.177 87.0062 285.63 87.8584C285.088 88.7106 284.256 89.1367 283.135 89.1367C282.059 89.1367 281.241 88.7015 280.681 87.8311C280.12 86.9561 279.84 85.6755 279.84 83.9893C279.84 82.2484 280.111 80.9564 280.653 80.1133C281.196 79.2702 282.023 78.8486 283.135 78.8486C284.219 78.8486 285.042 79.2884 285.603 80.168C286.168 81.0475 286.45 82.3213 286.45 83.9893ZM280.988 83.9893C280.988 85.443 281.159 86.5026 281.501 87.168C281.843 87.8288 282.387 88.1592 283.135 88.1592C283.891 88.1592 284.438 87.8242 284.775 87.1543C285.117 86.4798 285.288 85.4248 285.288 83.9893C285.288 82.5537 285.117 81.5033 284.775 80.8379C284.438 80.168 283.891 79.833 283.135 79.833C282.387 79.833 281.843 80.1634 281.501 80.8242C281.159 81.4805 280.988 82.5355 280.988 83.9893ZM290.736 85.165C290.736 86.3636 290.559 87.4801 290.203 88.5146C289.852 89.5492 289.344 90.4492 288.679 91.2148H287.585C288.218 90.3581 288.704 89.4079 289.041 88.3643C289.378 87.3161 289.547 86.2451 289.547 85.1514C289.547 84.0394 289.381 82.957 289.048 81.9043C288.72 80.8516 288.228 79.8854 287.571 79.0059H288.679C289.349 79.8034 289.859 80.7308 290.21 81.7881C290.561 82.8408 290.736 83.9665 290.736 85.165Z"
                                  fill="#4A4A4A"/>
                            <path d="M275.561 110.165C275.561 108.957 275.736 107.827 276.087 106.774C276.442 105.722 276.953 104.799 277.618 104.006H278.726C278.069 104.885 277.575 105.852 277.242 106.904C276.914 107.957 276.75 109.039 276.75 110.151C276.75 111.245 276.919 112.314 277.256 113.357C277.593 114.401 278.078 115.354 278.712 116.215H277.618C276.948 115.44 276.438 114.535 276.087 113.501C275.736 112.466 275.561 111.354 275.561 110.165ZM286.45 108.989C286.45 110.716 286.177 112.006 285.63 112.858C285.088 113.711 284.256 114.137 283.135 114.137C282.059 114.137 281.241 113.701 280.681 112.831C280.12 111.956 279.84 110.675 279.84 108.989C279.84 107.248 280.111 105.956 280.653 105.113C281.196 104.27 282.023 103.849 283.135 103.849C284.219 103.849 285.042 104.288 285.603 105.168C286.168 106.048 286.45 107.321 286.45 108.989ZM280.988 108.989C280.988 110.443 281.159 111.503 281.501 112.168C281.843 112.829 282.387 113.159 283.135 113.159C283.891 113.159 284.438 112.824 284.775 112.154C285.117 111.48 285.288 110.425 285.288 108.989C285.288 107.554 285.117 106.503 284.775 105.838C284.438 105.168 283.891 104.833 283.135 104.833C282.387 104.833 281.843 105.163 281.501 105.824C281.159 106.48 280.988 107.535 280.988 108.989ZM290.736 110.165C290.736 111.364 290.559 112.48 290.203 113.515C289.852 114.549 289.344 115.449 288.679 116.215H287.585C288.218 115.358 288.704 114.408 289.041 113.364C289.378 112.316 289.547 111.245 289.547 110.151C289.547 109.039 289.381 107.957 289.048 106.904C288.72 105.852 288.228 104.885 287.571 104.006H288.679C289.349 104.803 289.859 105.731 290.21 106.788C290.561 107.841 290.736 108.966 290.736 110.165Z"
                                  fill="#4A4A4A"/>
                            <rect x="105" width="161" height="17" fill="#F0F0F0"/>
                            <rect x="105" y="25" width="161" height="17" fill="#F0F0F0"/>
                            <rect x="105" y="50" width="161" height="17" fill="#F0F0F0"/>
                            <rect x="105" y="75" width="161" height="17" fill="#F0F0F0"/>
                            <rect x="105" y="100" width="161" height="17" fill="#F0F0F0"/>
                            <path d="M105 0H226V17H105V0Z" fill="#915674"/>
                            <path d="M105 25H139V42H105V25Z" fill="#915674"/>
                            <circle cx="7" cy="110" r="7" fill="#915674"/>
                            <circle cx="7" cy="85" r="7" fill="#915674"/>
                            <circle cx="7" cy="59" r="7" fill="#915674"/>
                            <circle cx="7" cy="33" r="7" fill="#915674"/>
                            <circle cx="7" cy="8" r="7" fill="#915674"/>
                            <circle cx="47" cy="110" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="47" cy="85" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="47" cy="59" r="7" fill="#915674"/>
                            <circle cx="47" cy="33" r="7" fill="#915674"/>
                            <circle cx="47" cy="8" r="7" fill="#915674"/>
                            <circle cx="27" cy="110" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="27" cy="85" r="7" fill="#915674"/>
                            <circle cx="27" cy="59" r="7" fill="#915674"/>
                            <circle cx="27" cy="33" r="7" fill="#915674"/>
                            <circle cx="27" cy="8" r="7" fill="#915674"/>
                            <circle cx="67" cy="110" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="67" cy="85" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="67" cy="59" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="67" cy="33" r="7" fill="#915674"/>
                            <circle cx="67" cy="8" r="7" fill="#915674"/>
                            <circle cx="87" cy="110" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="87" cy="85" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="87" cy="59" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="87" cy="33" r="6.5" fill="white" stroke="#915674"/>
                            <circle cx="87" cy="8" r="7" fill="#915674"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="last-grey-sctn pt-4">
                <div className="row">
                    <div className="col-md-3">
                        <div className="slidecontainer px-2">
                            <p>Aussehen</p>
                            <input type="range" min="1" max="100" value="50" className="slider" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="slidecontainer px-2">
                            <p>Effekt</p>
                            <input type="range" min="1" max="100" value="50" className="slider" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="slidecontainer px-2">
                            <p>Geruch</p>
                            <input type="range" min="1" max="100" value="50" className="slider" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="slidecontainer px-2">
                            <p>Geschmack</p>
                            <input type="range" min="1" max="100" value="50" className="slider" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100">
                <div className="d-flex margin-left pt-5 pb-4 my-4 display-block">
                    <div className="px-3">
                        <button className="last-page-btn">
                            <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.375 5C12.375 2.59375 9.80469 0.625 6.6875 0.625C3.54297 0.625 1 2.59375 1 5C1 5.95703 1.38281 6.80469 2.03906 7.51562C1.65625 8.36328 1.05469 9.01953 1.05469 9.01953C1 9.07422 0.972656 9.18359 1 9.26562C1.05469 9.34766 1.10938 9.375 1.21875 9.375C2.20312 9.375 3.02344 9.04688 3.625 8.69141C4.5 9.12891 5.56641 9.375 6.6875 9.375C9.80469 9.375 12.375 7.43359 12.375 5ZM15.7109 11.0156C16.3398 10.3047 16.75 9.45703 16.75 8.5C16.75 6.69531 15.2734 5.10938 13.1953 4.45312C13.2227 4.64453 13.25 4.83594 13.25 5C13.25 7.89844 10.2969 10.25 6.6875 10.25C6.38672 10.25 6.08594 10.25 5.8125 10.2227C6.66016 11.7812 8.68359 12.875 11.0625 12.875C12.1836 12.875 13.2227 12.6289 14.0977 12.1914C14.6992 12.5469 15.5195 12.875 16.5312 12.875C16.6133 12.875 16.6953 12.8477 16.7227 12.7656C16.75 12.6836 16.75 12.5742 16.668 12.5195C16.668 12.5195 16.0664 11.8633 15.7109 11.0156Z"
                                      fill="#915674"/>
                            </svg>
                            Ask a Question
                        </button>
                    </div>
                    <div className="px-3">
                        <button className="last-page-btn">
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9922 3.04688C10.8828 2.9375 10.7188 2.9375 10.6094 3.04688L4.64844 9.00781L4.375 11.5508C4.32031 11.8789 4.62109 12.1797 4.94922 12.125L7.49219 11.8516L13.4531 5.89062C13.5625 5.78125 13.5625 5.61719 13.4531 5.50781L10.9922 3.04688ZM15.4219 2.41797L14.082 1.07812C13.6719 0.667969 12.9883 0.667969 12.5781 1.07812L11.6211 2.03516C11.5117 2.14453 11.5117 2.30859 11.6211 2.41797L14.082 4.87891C14.1914 4.98828 14.3555 4.98828 14.4648 4.87891L15.4219 3.92188C15.832 3.51172 15.832 2.82812 15.4219 2.41797ZM10.5 10.2383V13H1.75V4.25H8.01172C8.12109 4.25 8.20312 4.22266 8.25781 4.16797L9.35156 3.07422C9.54297 2.85547 9.40625 2.5 9.10547 2.5H1.3125C0.574219 2.5 0 3.10156 0 3.8125V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V9.14453C12.25 8.84375 11.8945 8.70703 11.6758 8.89844L10.582 9.99219C10.5273 10.0469 10.5 10.1289 10.5 10.2383Z"
                                      fill="#915674"/>
                            </svg>
                            Write a Review
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <div className="d-flex border-bottom pb-3">
                    <div className="px-2">
                        <span className="px-2 font-weight-bolder">Reviews</span><span className="p-1 bg-grey">13</span>
                    </div>
                    <div className="px-2">
                        <span className="px-2 font-weight-bolder">Question</span><span className="p-1 bg-grey">13</span>
                    </div>
                </div>
                <div className="row pb-5 border-bottom">
                    <div className="col-md-10">
                        <span>Filter Reviews:</span>
                    </div>
                    <div className="col-md-2">
                        <div className="pull-right">
                            <select className="mdb-select md-form custom-select-sctn" searchable="Search here..">
                                <option value="" disabled selected>Sort</option>
                                <option value="1">USA</option>
                                <option value="2">Germany</option>
                                <option value="3">France</option>
                                <option value="3">Poland</option>
                                <option value="3">Japan</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="border-bottom pb-4 pt-4">
                    <div className="main-review-msg-sctn pt-4">
                        <div className="row">
                            <div className="col-md-10 d-flex">
                                <div className="px-2">
                                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="26" cy="26" r="26" fill="#F5F5F5"/>
                                        <path d="M17.0703 36.5859C16.4961 36.5859 16.0156 36.5127 15.6289 36.3662V34.5996C16.1211 34.7227 16.5488 34.7842 16.9121 34.7842C18.0605 34.7842 18.6348 34.0576 18.6348 32.6045V20.1504H20.7441V32.5342C20.7441 33.8467 20.4307 34.8486 19.8037 35.54C19.1826 36.2373 18.2715 36.5859 17.0703 36.5859ZM29.3311 26.0303H34.0596V32.4814C33.2861 32.7334 32.542 32.9121 31.8271 33.0176C31.1182 33.123 30.3506 33.1758 29.5244 33.1758C27.5791 33.1758 26.0791 32.6016 25.0244 31.4531C23.9697 30.2988 23.4424 28.6729 23.4424 26.5752C23.4424 24.5068 24.0371 22.8896 25.2266 21.7236C26.416 20.5518 28.0537 19.9658 30.1396 19.9658C31.4814 19.9658 32.7529 20.2236 33.9541 20.7393L33.2158 22.4883C32.1729 22.0078 31.1299 21.7676 30.0869 21.7676C28.7158 21.7676 27.6318 22.1982 26.835 23.0596C26.0381 23.9209 25.6396 25.0986 25.6396 26.5928C25.6396 28.1631 25.9971 29.3584 26.7119 30.1787C27.4326 30.9932 28.4668 31.4004 29.8145 31.4004C30.4941 31.4004 31.2207 31.3154 31.9941 31.1455V27.832H29.3311V26.0303Z"
                                              fill="#BBBBBB"/>
                                    </svg>
                                </div>
                                <div className="px-2">
                                    <div>
                                        <span className="pr-2">Jack G. </span>
                                        <span className="px-2 text-success">Verified Buyer</span>
                                    </div>
                                    <div>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 text-right">
                                03/15/2021
                            </div>
                        </div>
                        <div className="py-4 last-page-prgrph">
                            <p>5**</p>
                            <p>New packaging was nice, the quality of the flower was higher than last time which was already
                                great, but the latest was amazing flower for the cost. Floral as always - definitely
                                recommend!
                            </p>
                        </div>
                    </div>
                    <div className="py-4">
                        <div className="row">
                            <div className="col-md-8">
                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5312 5.61719C15.8047 5.34375 15.8047 4.93359 15.5312 4.66016L11.5938 0.941406C11.1836 0.558594 10.5 0.832031 10.5 1.43359V3.40234C6.48047 3.40234 3.28125 4.19531 3.28125 8.13281C3.28125 9.69141 4.23828 11.25 5.30469 12.043C5.63281 12.2891 6.09766 11.9883 5.98828 11.5781C4.86719 7.85938 6.53516 6.90234 10.5 6.90234V8.84375C10.5 9.44531 11.1836 9.71875 11.5938 9.33594L15.5312 5.61719ZM10.5 11.1406V13H1.75V4.25H3.11719C3.19922 4.25 3.30859 4.22266 3.36328 4.16797C3.77344 3.73047 4.23828 3.40234 4.75781 3.12891C5.05859 2.96484 4.94922 2.5 4.62109 2.5H1.3125C0.574219 2.5 0 3.10156 0 3.8125V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V11.0312C12.25 10.7852 12.0039 10.6484 11.7852 10.7031C11.6211 10.7852 11.3203 10.8398 11.1562 10.8398C11.0742 10.8398 10.9375 10.8125 10.8555 10.8125C10.6641 10.7852 10.5 10.9219 10.5 11.1406Z" fill="black"/>
                                </svg>
                                <span className="px-2 font-weight-lighter">Share</span>
                            </div>
                            <div className="col-md-4 text-right">
                                <span className="px-2 font-weight-lighter">Was this helpful?</span>
                                <span>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.84375 6.875H0.65625C0.273438 6.875 0 7.17578 0 7.53125V14.0938C0 14.4766 0.273438 14.75 0.65625 14.75H2.84375C3.19922 14.75 3.5 14.4766 3.5 14.0938V7.53125C3.5 7.17578 3.19922 6.875 2.84375 6.875ZM1.75 13.6562C1.36719 13.6562 1.09375 13.3828 1.09375 13C1.09375 12.6445 1.36719 12.3438 1.75 12.3438C2.10547 12.3438 2.40625 12.6445 2.40625 13C2.40625 13.3828 2.10547 13.6562 1.75 13.6562ZM10.5 2.99219C10.5 0.96875 9.1875 0.75 8.53125 0.75C7.95703 0.75 7.71094 1.84375 7.60156 2.33594C7.4375 2.9375 7.30078 3.53906 6.89062 3.94922C6.01562 4.85156 5.55078 5.97266 4.45703 7.03906C4.40234 7.12109 4.375 7.20312 4.375 7.28516V13.1367C4.375 13.3008 4.51172 13.4375 4.67578 13.4648C5.11328 13.4648 5.6875 13.7109 6.125 13.9023C7 14.2852 8.06641 14.75 9.37891 14.75H9.46094C10.6367 14.75 12.0312 14.75 12.5781 13.957C12.8242 13.6289 12.8789 13.2188 12.7422 12.7266C13.207 12.2617 13.4258 11.3867 13.207 10.6758C13.6719 10.0469 13.7266 9.14453 13.4531 8.51562C13.7812 8.1875 14 7.66797 13.9727 7.17578C13.9727 6.32812 13.2617 5.5625 12.3594 5.5625H9.57031C9.78906 4.79688 10.5 4.14062 10.5 2.99219Z" fill="#915674"/>
    </svg>
    
                        </span>
                                <span> 0</span>
                                <span className="pl-2 ">
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2.28125V8.84375C0 9.22656 0.273438 9.5 0.65625 9.5H2.84375C3.19922 9.5 3.5 9.22656 3.5 8.84375V2.28125C3.5 1.92578 3.19922 1.625 2.84375 1.625H0.65625C0.273438 1.625 0 1.92578 0 2.28125ZM1.09375 7.75C1.09375 7.39453 1.36719 7.09375 1.75 7.09375C2.10547 7.09375 2.40625 7.39453 2.40625 7.75C2.40625 8.13281 2.10547 8.40625 1.75 8.40625C1.36719 8.40625 1.09375 8.13281 1.09375 7.75ZM8.53125 14.75C9.1875 14.75 10.5 14.5312 10.5 12.5352C10.5 11.3867 9.78906 10.7305 9.57031 9.9375H12.3594C13.2617 9.9375 13.9727 9.19922 14 8.35156C14 7.85938 13.7812 7.33984 13.4531 7.01172C13.7266 6.38281 13.6719 5.48047 13.207 4.85156C13.4258 4.14062 13.207 3.26562 12.7422 2.80078C12.8789 2.30859 12.8242 1.89844 12.5781 1.57031C12.0312 0.777344 10.6367 0.75 9.46094 0.75H9.37891C8.06641 0.777344 7 1.24219 6.125 1.625C5.6875 1.81641 5.11328 2.0625 4.67578 2.0625C4.51172 2.08984 4.375 2.22656 4.375 2.39062V8.24219C4.375 8.32422 4.40234 8.43359 4.45703 8.48828C5.55078 9.55469 6.01562 10.6758 6.89062 11.5781C7.30078 11.9883 7.4375 12.5898 7.60156 13.1914C7.71094 13.6836 7.95703 14.75 8.53125 14.75Z" fill="#915674"/>
    </svg>
    
                        </span>
                                <span>0</span>
                            </div>
                        </div>
    
                    </div>
                </div>
                <div className="border-bottom pb-4 pt-4">
                    <div className="main-review-msg-sctn pt-4">
                        <div className="row">
                            <div className="col-md-10 d-flex">
                                <div className="px-2">
                                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="26" cy="26" r="26" fill="#F5F5F5"/>
                                        <path d="M17.0703 36.5859C16.4961 36.5859 16.0156 36.5127 15.6289 36.3662V34.5996C16.1211 34.7227 16.5488 34.7842 16.9121 34.7842C18.0605 34.7842 18.6348 34.0576 18.6348 32.6045V20.1504H20.7441V32.5342C20.7441 33.8467 20.4307 34.8486 19.8037 35.54C19.1826 36.2373 18.2715 36.5859 17.0703 36.5859ZM29.3311 26.0303H34.0596V32.4814C33.2861 32.7334 32.542 32.9121 31.8271 33.0176C31.1182 33.123 30.3506 33.1758 29.5244 33.1758C27.5791 33.1758 26.0791 32.6016 25.0244 31.4531C23.9697 30.2988 23.4424 28.6729 23.4424 26.5752C23.4424 24.5068 24.0371 22.8896 25.2266 21.7236C26.416 20.5518 28.0537 19.9658 30.1396 19.9658C31.4814 19.9658 32.7529 20.2236 33.9541 20.7393L33.2158 22.4883C32.1729 22.0078 31.1299 21.7676 30.0869 21.7676C28.7158 21.7676 27.6318 22.1982 26.835 23.0596C26.0381 23.9209 25.6396 25.0986 25.6396 26.5928C25.6396 28.1631 25.9971 29.3584 26.7119 30.1787C27.4326 30.9932 28.4668 31.4004 29.8145 31.4004C30.4941 31.4004 31.2207 31.3154 31.9941 31.1455V27.832H29.3311V26.0303Z"
                                              fill="#BBBBBB"/>
                                    </svg>
                                </div>
                                <div className="px-2">
                                    <div>
                                        <span className="pr-2">Jack G. </span>
                                        <span className="px-2 text-success">Verified Buyer</span>
                                    </div>
                                    <div>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 text-right">
                                03/15/2021
                            </div>
                        </div>
                        <div className="py-4 last-page-prgrph">
                            <p>5**</p>
                            <p>New packaging was nice, the quality of the flower was higher than last time which was already
                                great, but the latest was amazing flower for the cost. Floral as always - definitely
                                recommend!
                            </p>
                        </div>
                    </div>
                    <div className="py-4">
                        <div className="row">
                            <div className="col-md-8">
                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5312 5.61719C15.8047 5.34375 15.8047 4.93359 15.5312 4.66016L11.5938 0.941406C11.1836 0.558594 10.5 0.832031 10.5 1.43359V3.40234C6.48047 3.40234 3.28125 4.19531 3.28125 8.13281C3.28125 9.69141 4.23828 11.25 5.30469 12.043C5.63281 12.2891 6.09766 11.9883 5.98828 11.5781C4.86719 7.85938 6.53516 6.90234 10.5 6.90234V8.84375C10.5 9.44531 11.1836 9.71875 11.5938 9.33594L15.5312 5.61719ZM10.5 11.1406V13H1.75V4.25H3.11719C3.19922 4.25 3.30859 4.22266 3.36328 4.16797C3.77344 3.73047 4.23828 3.40234 4.75781 3.12891C5.05859 2.96484 4.94922 2.5 4.62109 2.5H1.3125C0.574219 2.5 0 3.10156 0 3.8125V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V11.0312C12.25 10.7852 12.0039 10.6484 11.7852 10.7031C11.6211 10.7852 11.3203 10.8398 11.1562 10.8398C11.0742 10.8398 10.9375 10.8125 10.8555 10.8125C10.6641 10.7852 10.5 10.9219 10.5 11.1406Z" fill="black"/>
                                </svg>
                                <span className="px-2 font-weight-lighter">Share</span>
                            </div>
                            <div className="col-md-4 text-right">
                                <span className="px-2 font-weight-lighter">Was this helpful?</span>
                                <span>
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.84375 6.875H0.65625C0.273438 6.875 0 7.17578 0 7.53125V14.0938C0 14.4766 0.273438 14.75 0.65625 14.75H2.84375C3.19922 14.75 3.5 14.4766 3.5 14.0938V7.53125C3.5 7.17578 3.19922 6.875 2.84375 6.875ZM1.75 13.6562C1.36719 13.6562 1.09375 13.3828 1.09375 13C1.09375 12.6445 1.36719 12.3438 1.75 12.3438C2.10547 12.3438 2.40625 12.6445 2.40625 13C2.40625 13.3828 2.10547 13.6562 1.75 13.6562ZM10.5 2.99219C10.5 0.96875 9.1875 0.75 8.53125 0.75C7.95703 0.75 7.71094 1.84375 7.60156 2.33594C7.4375 2.9375 7.30078 3.53906 6.89062 3.94922C6.01562 4.85156 5.55078 5.97266 4.45703 7.03906C4.40234 7.12109 4.375 7.20312 4.375 7.28516V13.1367C4.375 13.3008 4.51172 13.4375 4.67578 13.4648C5.11328 13.4648 5.6875 13.7109 6.125 13.9023C7 14.2852 8.06641 14.75 9.37891 14.75H9.46094C10.6367 14.75 12.0312 14.75 12.5781 13.957C12.8242 13.6289 12.8789 13.2188 12.7422 12.7266C13.207 12.2617 13.4258 11.3867 13.207 10.6758C13.6719 10.0469 13.7266 9.14453 13.4531 8.51562C13.7812 8.1875 14 7.66797 13.9727 7.17578C13.9727 6.32812 13.2617 5.5625 12.3594 5.5625H9.57031C9.78906 4.79688 10.5 4.14062 10.5 2.99219Z" fill="#915674"/>
    </svg>
    
                        </span>
                                <span> 0</span>
                                <span className="pl-2 ">
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2.28125V8.84375C0 9.22656 0.273438 9.5 0.65625 9.5H2.84375C3.19922 9.5 3.5 9.22656 3.5 8.84375V2.28125C3.5 1.92578 3.19922 1.625 2.84375 1.625H0.65625C0.273438 1.625 0 1.92578 0 2.28125ZM1.09375 7.75C1.09375 7.39453 1.36719 7.09375 1.75 7.09375C2.10547 7.09375 2.40625 7.39453 2.40625 7.75C2.40625 8.13281 2.10547 8.40625 1.75 8.40625C1.36719 8.40625 1.09375 8.13281 1.09375 7.75ZM8.53125 14.75C9.1875 14.75 10.5 14.5312 10.5 12.5352C10.5 11.3867 9.78906 10.7305 9.57031 9.9375H12.3594C13.2617 9.9375 13.9727 9.19922 14 8.35156C14 7.85938 13.7812 7.33984 13.4531 7.01172C13.7266 6.38281 13.6719 5.48047 13.207 4.85156C13.4258 4.14062 13.207 3.26562 12.7422 2.80078C12.8789 2.30859 12.8242 1.89844 12.5781 1.57031C12.0312 0.777344 10.6367 0.75 9.46094 0.75H9.37891C8.06641 0.777344 7 1.24219 6.125 1.625C5.6875 1.81641 5.11328 2.0625 4.67578 2.0625C4.51172 2.08984 4.375 2.22656 4.375 2.39062V8.24219C4.375 8.32422 4.40234 8.43359 4.45703 8.48828C5.55078 9.55469 6.01562 10.6758 6.89062 11.5781C7.30078 11.9883 7.4375 12.5898 7.60156 13.1914C7.71094 13.6836 7.95703 14.75 8.53125 14.75Z" fill="#915674"/>
    </svg>
    
                        </span>
                                <span>0</span>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
   
        </>
    ) 
}


const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    // errCode:state.model.err_code,
    // jsonResult:state.json.result,
    getProductFromLucene:state.model.getProductFromLucene,
    rate:state.auth.rate

})

const mapDispatchToProps = {
    onSetToken:authActions.setToken,
    onGetProductFromLucene:modelOperations.getProductFromLucene,
}
export default connect(mapStateToProps, mapDispatchToProps)(Page);