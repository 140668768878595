import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { authSelectors } from '../auth';
import userActions from './user-actions';

import {PUBLIC_USER} from './../../config/Url'
import { authActions } from '../auth';

const instance = axios.create({
    baseURL: PUBLIC_USER,
    headers: { "Access-Control-Allow-Origin": "*", }
});

const resetToken = (state, dispatch) => {
    if(Boolean(state().auth.token)){
        // instance.defaults.headers.common.Authorization = `Bearer ${state().auth.token}`;

        instance.interceptors.request.use(config => {
            // dispatch(userActions.loader(true))
            config.headers.Authorization = ``;
            return config;
        
        }, error => {
            console.log('public', error);

            // dispatch(userActions.loader(false))
            return Promise.reject(error);
        });
        
        instance.interceptors.response.use(response => {
            // dispatch(userActions.loader(false))
            console.log('public', response);

            return response;
        }, error => {

            console.log('public', error);

            // dispatch(userActions.loader(false))
            return Promise.reject(error);
        });
    }
}


const userRegistration = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(authActions.registerRequest())

    try{
        const res = await instance.post('/', {
            name:"USER_REGISTRATION_ACCOUNT",
            parameters:credential
        });
        dispatch(authActions.registerSuccess(res.data))

    }catch (error){
        dispatch(authActions.registerError())
    }
}

const userAutorization = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(authActions.loginRequest())

    try{
        const res = await instance.post('/', {
            name:"USER_AUTORISATION_BY_EMAIL",
            parameters:credential
        });
        dispatch(authActions.loginSuccess(res.data))
    }catch (error){
        dispatch(authActions.loginError())
    }
}

const getCatalogsList = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(userActions.getCatalogsListRequest());

    try{
        const res = await instance.post('/', {
            name:"get_catalogs_list",
            parameters:credential
        });
        dispatch(userActions.getCatalogsListSuccess(res.data));

    }catch (error){
        dispatch(userActions.getCatalogsListError(error));
    }
}

const getCatalogsListMenu = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(userActions.getCatalogsListMenuRequest());

    try{
        const res = await instance.post('/', {
            name:"get_catalogs_list",
            parameters:credential
        });
        dispatch(userActions.getCatalogsListMenuSuccess(res.data));

    }catch (error){
        dispatch(userActions.getCatalogsListMenuError(error));
    }
}

const currencyList = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(userActions.currencyListRequest());

    try{
        const res = await instance.post('/', {
            name:"get_currency_list",
            parameters:credential
        });
        dispatch(userActions.currencyListSuccess(res.data));

    }catch (error){
        dispatch(userActions.currencyListError(error));
    }
}

const getProductsByGroup = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(userActions.getProductsByGroupRequest());

    try{
        const res = await instance.post('/', {
            name:"get_products_by_group",
            parameters:credential
        });
        dispatch(userActions.getProductsByGroupSuccess(res.data));

    }catch (error){
        dispatch(userActions.getProductsByGroupError(error));
    }
}

const getProducts = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(userActions.getProductsRequest());

    try{
        const res = await instance.post('/', {
            name:"get_products",
            parameters:credential
        });
        dispatch(userActions.getProductsSuccess(res.data));

    }catch (error){
        dispatch(userActions.getProductsError(error));
    }
}

const getProductById = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(userActions.getProductByIdRequest());

    try{
        const res = await instance.post('/', {
            name:"get_product_by_id",
            parameters:credential
        });
        dispatch(userActions.getProductByIdSuccess(res.data));

    }catch (error){
        dispatch(userActions.getProductByIdError(error));
    }
}


const getWidgetRecords = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(userActions.getWidgetRecordsRequest());

    try{
        const res = await instance.post('/', {
            name:"get_widget_records",
            parameters:credential
        });
        dispatch(userActions.getWidgetRecordsSuccess(res.data));

    }catch (error){
        dispatch(userActions.getWidgetRecordsError(error));
    }
}

const getProductByPricingParameters = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(userActions.getProductByPricingParametersRequest());

    try{
        const res = await instance.post('/', {
            name:"get_product_by_pricing_parameters",
            parameters:credential
        });
        dispatch(userActions.getProductByPricingParametersSuccess(res.data));

    }catch (error){
        dispatch(userActions.getProductByPricingParametersError(error));
    }
}

const getFeedbackList = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(userActions.getFeedbackListRequest())

    try{
        const res = await instance.post('/', {
            name:"get_feedback_list",
            parameters:credential
        });
        dispatch(userActions.getFeedbackListSuccess(res.data))

    }catch (error){
        dispatch(userActions.getFeedbackListError())
    }
}

export default {
    initWebSait: credential => async (dispatch, state) => {
        resetToken(state, dispatch)
        dispatch(userActions.initWebSaitRequest())
        try{
            const res = await instance.post('/', {
                name:"init_web_sait",
                parameters:credential
            });
            dispatch(userActions.initWebSaitSuccess(res.data))

        }catch (error){
            dispatch(userActions.initWebSaitError())
        }
    },
    legalInfoById: credential => async (dispatch, state) => {
        resetToken(state, dispatch)
        dispatch(userActions.legalInfoByIdRequest())
        try{
            const res = await instance.post('/', {
                name:"get_legal_info_by_id",
                parameters:credential
            });
            dispatch(userActions.legalInfoByIdSuccess(res.data))

        }catch (error){
            dispatch(userActions.legalInfoByIdError())
        }
    },
    forgotPasswordEmail: credential => async (dispatch, state) => {
        resetToken(state, dispatch)
        dispatch(authActions.forgotPasswordEmailRequest())
        try{
            const res = await instance.post('/', {
                name:"USER_FORGOT_PASSWORD_BY_EMAIL",
                parameters:credential
            });
            dispatch(authActions.forgotPasswordEmailSuccess(res.data))

        }catch (error){
            dispatch(authActions.forgotPasswordEmailError())
        }
    },
    getFeedbackList,

    getWidgetRecords,
    getProductByPricingParameters,
    userAutorization,
    userRegistration,
    getProductById,
    getProducts,
    getProductsByGroup,
    getCatalogsList,
    getCatalogsListMenu,
    currencyList
}
