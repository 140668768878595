import { useEffect } from "react";

const Popup = ({
    title='',
    children,
    closePopup,
    width = '600px'
}) => {
    useEffect(()=>{
        window.addEventListener('keydown', handler)
        document.body.style.overflow = 'hidden';
        return () => {
            window.removeEventListener('keydown', handler) 
            document.body.style.overflow = 'auto';
        }
    }, [])
    const handler = evt => {
        if (evt.key === 'Escape') {
            closePopup({status:0})
        }
    }
    const handleBackdrop = evt =>{
        if(evt.currentTarget === evt.target) closePopup({status:0})
    }

    return (
        <div className="modal" tabIndex="-1" role="dialog"  onClick={handleBackdrop} style={{display: 'block', paddingRight: 17, overflowX: 'hidden', overflowY: 'auto'}}>
            <div className="modal-dialog" role="document" style={{width: `${width} !important`, maxWidth: width, marginTop:50}}>
                <div className="modal-content" style={{borderRadius: 5}}>
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" onClick={()=>closePopup({status:0})}>&times;</span>
                        </button>
                    </div>
                    {children}  
                </div>
            </div>
        </div>
    ) 
}

export default Popup