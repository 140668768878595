

import $ from 'jquery'
import moment from 'moment'
import {WEB_PICTURES_UPLOAD} from './../config/Url'

const upload = (files, callback) =>{
	var nameFile = new Array();
	var formData = new FormData(); 
	var name = ''
	// for (i = 0; i < files.length; i++) {
	//     formData.append('file', files[i]);
	//     console.log(files[i]);
	//     nameFile.push(files[i].name)
	// }

	$.each(files, function(k, v){
		formData.append('file', v);
	    nameFile.push(v.name)
	    name = v.name;
	})


	console.log("File count: ", files);

	formData.append("session", 1234567890);
	formData.append("date", moment().format("YYYY-MM-DD HH:mm:ss"));
	formData.append("type", 0);


	// $(".logoUpload").append('<div class="uploadLoading"><i class="fa fa-spinner fa-spin fa-3x"></i></div>');

	$.ajax({
		type: "POST",
		url: WEB_PICTURES_UPLOAD,
		data: formData,
		processData: false, 
		contentType: false, 
		success:function(data){
			console.log(data);
			data['name'] = name
			callback(data);
		}
	})
	.fail(function(jqXHR, textStatus){

	});
}
export default upload