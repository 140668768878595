import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userPrivateOperations } from "../../redux/userPrivate";
import { useEffect, useState } from "react";
import {DOMAIN} from '../../config/Url'
import PopupEditAddress from "./PopupEditAddress";

const Address = ({
    onGetUserAddress,
    getUserAddress,
    errCode
}) => {

    const [popupEditAddress, setPopupEditAddress] = useState(false);
    const [title, setTitle] = useState('')
    const [type, setType] = useState(0)

    const [billAddress, setBillAddress] = useState({});
    const [shipAddress, setShipAddress] = useState({});

    useEffect(()=>{
        onGetUserAddress({DOMAIN})
    }, [])

    useEffect(()=>{
        if(errCode === 1){
            onGetUserAddress({DOMAIN}) 
        }
    }, [errCode])

    useEffect(()=>{
        console.log(getUserAddress);

        if(getUserAddress.length !== 0){

            getUserAddress.forEach((v) => {
                const {ADDRESS_TYPE} = v
                if(ADDRESS_TYPE === 0){
                    setBillAddress(v)
                }else if(ADDRESS_TYPE === 1){
                    setShipAddress(v)
                }
            });
        }

    }, [getUserAddress])

    return (
        <>
        <div className="container">
            <div className="text-center py-4">
                <h1>Addresses</h1>
            </div>
            <div className="row mobile-view">
                <Sidebar/>
                <div className="py-3 px-5 col-md-10">
                    <p>The following addresses will be used on the checkout page by default.</p>
                    <div className="row py-3">
                        <div className="col-md-6">
                            <div className="pr-2">
                                <div className="border-bottom py-2">
                                    <span className="text-size-custom">Billling address</span>
                                   <a className="float-right py-2 color-from-theme pointer" onClick={()=>{setTitle('Billling address'); setType(0); setPopupEditAddress(true)}}>{Object.keys(billAddress).length === 0 ? 'Add' : 'Edit'}</a>
                                </div>
                                <div className="py-3">
                                    {Object.keys(billAddress).length === 0 &&  <p>You have not set up this type of address yet.</p>}
                                    {Object.keys(billAddress).length !== 0 && 
                                    <div>
                                        <div>{billAddress.STREET_ADDRESS}</div>
                                        <div>{billAddress.TOWN_CITY}, {billAddress.STATE} </div>
                                        <div>{billAddress.COUNTRY_REGION}, {billAddress.ZIP_POSTAL_CODE}</div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pl-2">
                                <div className="border-bottom py-2">
                                    <span className="text-size-custom">Shipping address</span>
                                    <a  className="float-right py-2 color-from-theme pointer" onClick={()=>{setTitle('Shipping address'); setType(1); setPopupEditAddress(true)}}>{Object.keys(shipAddress).length === 0 ? 'Add' : 'Edit'}</a>
                                </div>
                                <div className="py-3">
                                    {Object.keys(shipAddress).length === 0 &&  <p>You have not set up this type of address yet.</p>}
                                    {Object.keys(shipAddress).length !== 0 && <div>
                                        <div>{shipAddress.STREET_ADDRESS}</div>
                                        <div>{shipAddress.TOWN_CITY}, {shipAddress.STATE} </div>
                                        <div>{shipAddress.COUNTRY_REGION}, {shipAddress.ZIP_POSTAL_CODE}</div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {popupEditAddress && <PopupEditAddress billAddress={billAddress} shipAddress={shipAddress} type={type} title={title} closePopup={()=>setPopupEditAddress(false)} />}
        </>
    )
}
const mapStateToProps = (state) => ({
    lang: state.lang.LANG,
    errCode: state.userPrivate.err_code,
    getUserAddress: state.userPrivate.getUserAddress
})

const mapDispatchToProps = {
    onGetUserAddress: userPrivateOperations.getUserAddress,
    // onUpdateUserProfile: userPrivateOperations.updateUserProfile,
    // onUpdateUserPassword: userPrivateOperations.updateUserPassword,
}
export default connect(mapStateToProps, mapDispatchToProps)(Address);
