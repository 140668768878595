import { useEffect } from "react"
import { connect } from "react-redux"
import Popup from "../../components/Popup/Popup"
import { userPrivateOperations } from "../../redux/userPrivate"
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'
import { Base64Decode } from "../../modules/helper"
import Button from "../../components/Button/Button"


const PopupInfoDelivery = ({
    lang,
    closePopup,
    orderId:ID_ORDER,
    currentRate,
    onGetUserDeliveryInfoById,
    getUserDeliveryInfoById
}) => {

    useEffect(()=>{
        onGetUserDeliveryInfoById({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            ID_ORDER
        })
    }, [])

    return (
        <Popup title={`Order ${ID_ORDER} delivery list`} closePopup={closePopup}>
            <div className="mx-3">
                        <h3>Packages</h3>

                        {
                            getUserDeliveryInfoById?.PACKAGES?.map(({LOCATION_ID, LOCATION_NAME, TRACKING_NUMBER, PRODUCTS, DELIVERY_SERVICE}, k)=>{
                                const deliveryId = DELIVERY_SERVICE?.ID_DELIVERY_TYPE
                                const deliveryName = DELIVERY_SERVICE?.NAME_DELIVERY_TYPE

                                
                                
                                return(<div key={k}>
                                    <h5><b>Storage or location: {LOCATION_NAME}</b></h5>
                                    <div className="d-flex">
                                        <div className="w-50">
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <td > <b>Product Name </b></td>
                                                        <td > <b>QTY</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {PRODUCTS.map(({NAME, QTY})=>{
                                                        return(<tr>
                                                                <td>{Base64Decode(NAME)}</td>
                                                                <td>{QTY}</td>
                                                            </tr>)
                                                    })}
                
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="w-50">
                                            <div className="d-flex f-col f-acenter">
                                                <label>Delivery service:</label>
                                               
                                                 {deliveryName}
                                                
                                            </div>
                                            <br/>

                                            <div className="d-flex f-col f-acenter"> 
                                                <label>Enter tracking number:</label>
                                                {TRACKING_NUMBER}
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                </div>)
                            })
                        }


                    </div>

            <div className="d-flex mb-4">
                <Button onClick={() => closePopup()} style={{width: 200, margin: '0 auto'}}>Close</Button>

                {/* <button className="checkout-final-btn" style={{width: 200, margin: '0 auto'}} type="submit" onClick={() => closePopup()}>Close</button> */}
            </div>
        </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    // orderId:state.userPrivate.orderId,
    getUserDeliveryInfoById:state.userPrivate.getUserDeliveryInfoById,

})

const mapDispatchToProps = {
    // onProceedToCheckout:userPrivateOperations.proceedToCheckout,
    onGetUserDeliveryInfoById:userPrivateOperations.getUserDeliveryInfoById,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupInfoDelivery);