import { useState } from "react";
import { useEffect } from "react"
import { connect } from "react-redux";
import { authActions } from "../../redux/auth";
import {WEB_PICTURES_FILE_RESIZER} from './../../config/Url'
import Button from "./../Button";


const PopupAlert = ({
    yearsAge18,
    setYearsAge18,
    initWebSait
}) => {

    const [pin, setPin] = useState('');
    const [pinCode, setPinCode] = useState('')

    useEffect(()=>{
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [])

    useEffect(()=>{
        if(Object.keys(initWebSait).length !== 0){
            setPinCode(initWebSait?.MAIN_INFO?.PIN_CODE)
            if(initWebSait?.MAIN_INFO?.VALIDATE_AGE === 0)
                setYearsAge18(1)
        }
    }, [initWebSait])

    const yes = () =>{

        if(initWebSait?.MAIN_INFO?.PIN_CODE_STATUS === 0){
            setYearsAge18(1) 
            return;
        }

        if(pin === pinCode) 
            setYearsAge18(1) 
        else 
            setYearsAge18(2)
    }

    return (
        <>
        {initWebSait?.MAIN_INFO?.VALIDATE_AGE === 1 && 
        <div className="popup-cover-div" style={{position: 'fixed', zIndex: 1000000000, top: 0, left: 0, backgroundImage:`url(${WEB_PICTURES_FILE_RESIZER}${initWebSait?.MAIN_INFO?.VALIDATE_AGE_BACKGROUND}/1200)`}}>
        <div className="modal fade show" id="exampleModal-two" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{overflow: 'auto', display: 'block'}}>
            <div className="modal-dialog">
                <div className="modal-content" style={{backgroundColor:initWebSait?.MAIN_INFO?.AGE_VALIDATION_BG_POUP === '' ? '#fff' : initWebSait?.MAIN_INFO?.AGE_VALIDATION_BG_POUP}}>
                    <div className="modal-body">
                        <div className="text-center pt-3 pb-5">
                            <img src={`${WEB_PICTURES_FILE_RESIZER}${initWebSait?.MAIN_INFO?.LOGO_HEADER}/400`} width="377" height="139" style={{objectFit: 'contain'}} />
                           

                        </div>
                        {yearsAge18 === -1 &&
                            <>
                                <div className="text-center pb-4">
                                    <h3 className="font-weight-normal">Are you over 18 years of age?</h3>
                                </div>
                                <div className="text-center" >
                                    <Button onClick={()=>yes()} line>Yes</Button>
                                    <Button onClick={()=>setYearsAge18(2)} style={{marginLeft:12}} line>No</Button>
                                    {/* <span className="popup-text-size btn" onClick={()=>yes()}>Yes</span> */}
                                    {/* <span className="popup-text-size btn" onClick={()=>setYearsAge18(2)}>No</span> */}
                                </div>
                                {/* <div className="text-center pt-4 pb-4">
                                    <input type="checkbox" />
                                    <span>Remember me</span>
                                </div> */}
                                {initWebSait?.MAIN_INFO?.PIN_CODE_STATUS === 1 &&
                                    <div className="text-center pt-4 pb-4">
                                        <input className="form-control top-search-input" autoComplete="off" type="password" value={pin} onChange={({target:{value}})=>setPin(value)} style={{textAlign:'center', margin: '0 auto'}} />
                                    </div>
                                }
                            </>
                        }
                        {yearsAge18 === 2 &&
                            <>
                                <h3 style={{textAlign:'center'}}>Access denied</h3>
                                <div className="text-center">
                                    <span className="popup-text-size btn" onClick={()=>setYearsAge18(-1)}>Back</span>
                                </div>
                                <br /> 
                                <br /> 
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    </>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    errCode:state.model.err_code,
    yearsAge18:state.auth.yearsAge18,
    initWebSait:state.user.initWebSait,
})
const mapDispatchToProps = {
    setYearsAge18:authActions.yearsAge18,
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupAlert);
