import { useEffect, useState } from "react"
import $ from 'jquery'
import { connect } from "react-redux";
import { userOperations } from "../../redux/user";
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from './../../config/Url'
import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import userPrivateOperations from "../../redux/userPrivate/user-private-operations";
import { authSelectors } from "../../redux/auth";
import PopupLoginRegister from "../../components/PopupLoginRegister/PopupLoginRegister";
import Cart from "../Cart/Cart";
import Button from "../../components/Button/Button";

const sort = [
    {id:0, name:'Do not sort'},
    {id:1, name:'Name A-Z'},
    {id:2, name:'Name Z-A'},
    {id:3, name:'Price low to high'},
    {id:4, name:'Price high to low'},
    {id:5, name:'Rate low to high'},
    {id:6, name:'Rate high to low'},
]

const Products = ({
    onGetProducts,
    getProducts,
    lang,
    match,
    history,
    location,
    rate,
    onGetCatalogsList,
    getCatalogsList,
    orderId:ID_ORDER,
    onGetAddProductToCart,
    getAddProductToCart,
    getIsAuthenticated,
    getCart,
    onGetCart,
    initWebSait
    // onGetProducts
}) => {

    const [show, setShow] = useState(false)
    const [flag, setFlag] = useState(0)
    const [itemId, setItemId] = useState(0)
    const [currentSortId, setCurrentSortId] = useState(0)

    useEffect(()=>{

        onGetCatalogsList({DOMAIN, TYPE:1, DEFAULT_COLUMN:lang})

        // $(document).ready(function(){
            // Add minus icon for collapse element which is open by default
            $(".collapse.show").each(function(){
                $(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
            });

            // Toggle plus minus icon on show hide of collapse element
            $(".collapse").on('show.bs.collapse', function(){
                $(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
            }).on('hide.bs.collapse', function(){
                $(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
            });
        // });
    }, [])

    useEffect(()=>{
        if(Object.keys(getAddProductToCart).length !== 0){
            onGetCart({DOMAIN})
        }
    }, [getAddProductToCart])

    useEffect(()=>{
        console.log(match);

        const ID_RECORD = match?.params?.recordId
        // if(!ID_RECORD) return;

        console.log(ID_RECORD);

        // const PROPERTIES = isNaN(Number(!ID_RECORD)) ? [] : [{ID_RECORD:Number(ID_RECORD)}]
        const PROPERTIES = isNumeric(ID_RECORD) ? [{ID_RECORD:Number(ID_RECORD)}] : []
        const FILTER_TEXT = !isNumeric(ID_RECORD) ? ID_RECORD : ''

        onGetProducts({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            PAGE:0,
            SIZE:20,
            FILTER_TEXT,		//- optional
            SORT_FIELD:"price",//	- optional (price,rate)
            SORT_TYPE:"asc",	//	- optional (asc,desc)
            PROPERTIES
        })

    }, [match])

    const goPage = ID_ITEM =>{
        history.push(`/product/${ID_ITEM}`)
    }

    const isNumeric = (num) =>{
        return !isNaN(num)
    }

    const addToCart = ID_ITEM => {
        setFlag(0)
        setItemId(ID_ITEM)
        if(getIsAuthenticated){
            onGetAddProductToCart({
                DOMAIN,
                DEFAULT_COLUMN:lang,
                ID_ITEM,
                QTY:1,
                ID_ORDER
            })
        }else
            setShow(true)
    }

    const closeAuth = ({status}) =>{
        setShow(false);
        if(status!==0){
            addToCart(itemId)
        }  
    }

    const cart = () => history.push('/cart')

    const clickSort = id => {
        setCurrentSortId(id)

        const ID_RECORD = match?.params?.recordId
        // if(!ID_RECORD) return;

        let SORT_FIELD = 'none'
        let SORT_TYPE = 'asc'

        // {id:0, name:'Do not sort'},
        // {id:1, name:'Name A-Z'},
        // {id:2, name:'Name Z-A'},
        // {id:3, name:'Price low to high'},
        // {id:4, name:'Price high to low'},
        // {id:5, name:'Rate low to high'},
        // {id:6, name:'Rate high to low'},

        if(id === 1){
            SORT_FIELD = 'name'
            SORT_TYPE = 'asc'
        }else if(id === 2){
            SORT_FIELD = 'name'
            SORT_TYPE = 'desc'
        }else if(id === 3){
            SORT_FIELD = 'price'
            SORT_TYPE = 'asc'
        }else if(id === 4){
            SORT_FIELD = 'price'
            SORT_TYPE = 'desc'
        }else if(id === 5){
            SORT_FIELD = 'rate'
            SORT_TYPE = 'asc'
        }else if(id === 6){
            SORT_FIELD = 'rate'
            SORT_TYPE = 'desc'
        }

        const PROPERTIES = isNumeric(ID_RECORD) ? [{ID_RECORD:Number(ID_RECORD)}] : []

        onGetProducts({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            PAGE:0,
            SIZE:20,
            FILTER_TEXT:"",		//- optional
            SORT_FIELD,//	- optional (price,rate)
            SORT_TYPE,	//	- optional (asc,desc)
            PROPERTIES
        })
    }

    return (
        <>
        <div className="container">

        <div className="row small-screen-center" style={{justifyContent:"center"}}>
            {getCatalogsList.length !== 0 &&
            <div className="col-md-3 pt-5">
                <div className="bs-example text-dark">
                    <div className="accordion" id="accordionExample">
                        {
                            getCatalogsList.map(({INFO_WIDGET, ID_WIDGET,  RECORDS_WIDGET}, k)=>{
                                return(<div key={k} className="card">
                                        <div className="card-header" id={`heading${ID_WIDGET}`}>
                                            <h2 className="mb-0">
                                                <button type="button" className="btn btn-link w-100 text-left" data-toggle="collapse" data-target={`#collapse${ID_WIDGET}`}><i className="fa fa-plus"></i> {Base64.decode(INFO_WIDGET)} </button>
                                            </h2>
                                        </div>
                                        <div id={`collapse${ID_WIDGET}`} className="collapse " aria-labelledby={`heading${ID_WIDGET}`} data-parent="#accordionExample">
                                            {/* <div className="card-body">
                                                <p>Something Here</p>
                                            </div> */}
                                            {
                                                RECORDS_WIDGET.map(({INFO_RECORD, ID_RECORD}, k)=>(
                                                    <div key={k} className="py-1" style={{marginLeft: 35, cursor:'pointer'}}>
                                                        {/* <span>{Base64.decode(INFO_RECORD)}</span> */}
                                                        <Link key={k} className="dropdown-item" to={{
                                                            pathname:`/products/${ID_RECORD}`,
                                                            state:{from:location}
                                                        }}>{Base64.decode(INFO_RECORD)}</Link>
                                                    </div>
                                                ))
                                            }
                                            
                                        </div>
                                    </div>)
                            })
                        }
            
                    </div>
                </div>
            </div>
            }

            <div className="col-md-9 pt-5">
                <div>
                    <div className="row pb-4">
                        <div className="col-md-6">
                            {/* <span>135 Products</span> */}
                        </div>
                        <div className="col-md-6 d-flex" style={{justifyContent: "flex-end"}}>
                            <span>Sort by:</span>
                            <div className=" dropdown sort-by">
                                <a className=" dropdown-toggle text-dark" id="navbarDropdownones" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {sort.filter(({id})=>id === currentSortId).map(({name})=>(name))}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownones">
                                    {sort.map(({id, name}, k)=>(<a className="dropdown-item pointer" key={k} onClick={()=>clickSort(id)} >{name}</a>))}
                                    {/* <a className="dropdown-item" href="#">Action</a>
                                    <a className="dropdown-item" href="#">Another action</a> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row pb-5 small-center">
                        {initWebSait?.PRODUCT_LIST_TYPE === 0 && 
                            getProducts?.content?.map(({CATEGORIES, ID_ITEM, MIDDLE_RATE, REVIEWS_QTY, IMAGE, NAME, PRICE, TOTAL_PRICE, PERCENT_OFF}, k)=>{

                                const price = PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE
                                const priceToken = PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN').PRICE

                                const totalPrice = TOTAL_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE
                                const totalPriceToken = TOTAL_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN').PRICE

                                // const { PRODUCTS } = getCart
                                const product = getCart?.PRODUCTS ? getCart?.PRODUCTS?.filter(({ID_ITEM:getId}) => ID_ITEM=== getId) : []

                                
                               return( <div key={k} className="col-md-4 mb-3">
                                <div className="product-list-div text-center pt-4" style={{margin: '0 auto'}}>
                                    {/* <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/200`} alt="Image" style={{width: '100%', maxHeight: '100%'}} /> */}
                                    <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/200`} alt="Image" style={{width: 350,
                                                                                                            height: 350,
                                                                                                            position: 'relative',
                                                                                                            top: '50%',
                                                                                                            left: '50%',
                                                                                                            transform: 'translate(-50%, -50%)', objectFit: 'cover'}} />
                                 <div className="hover-effect-div">
                                        <div className="mt-5 mb-5 text-center ">
                                            <div className="hover-main-sctn text-center pt-3 pb-4">
                                                <br/>
                                                <br/>
                                                <div className="mt-1 mb-2">
                                                    {product.length === 0 &&
                                                    <>
                                                    <Button onClick={()=>addToCart(ID_ITEM)}>ADD TO CART</Button>
                                                    
                                                     {/* <button className="text-center hover-add-cart-btn" onClick={()=>addToCart(ID_ITEM)} >ADD TO CART</button> */}
                                                   </>
                                                    }
                                                    {product.length !== 0 && 
                                                    <Button onClick={()=>cart()}>In Cart</Button>
                                                    // <button className="text-center hover-add-cart-btn" onClick={()=>cart()}>In Cart</button>
                                                    }
                                                </div>
                                                <div className="mt-1 mb-2">
                                                    <Button line onClick={()=>goPage(ID_ITEM)}>VIEW ITEM</Button>
                                                    {/* <button className="text-center hover-view-item-btn" onClick={()=>goPage(ID_ITEM)}>VIEW ITEM</button> */}
                                                </div>
     
                                            </div>
                                        </div>
                                    </div>

                                   
                                </div>
                                <div className="text-center pt-4">
                                    <div>
                                        <span className="font-weight-bolder">{Base64.decode(NAME)}</span>
                                    </div>
                                    <div style={{minHeight:24}}>
                                        <span>{CATEGORIES.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', ')}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {
                                                Array.from({length:5}).map((i , k)=>{
                                                    return(<i key={k} className={['fa', MIDDLE_RATE >= k+1 ? 'fa-star' : 'fa-star-o'].join(' ')}  ></i>)
                                                })
                                            }
                                        </span>
                                        <span> {REVIEWS_QTY} reviews</span>
                                    </div>
                                    <div>
                                        <div className="d-flex" style={{justifyContent: 'center'}}>
                                            <div>
                                                <div>{rate} {Number(totalPrice.toFixed(2))}</div>
                                                <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(totalPriceToken.toFixed(2))}</div>
                                            </div>
                                            {PERCENT_OFF !== 0 &&
                                                <div className="ml-3">
                                                    <div style={{textDecoration: 'line-through', color:'#4defe7'}}>{rate} {Number(price.toFixed(2))}</div>
                                                    <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(priceToken.toFixed(2))}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
    
                            </div>)
                            })
                        }



                        {initWebSait?.PRODUCT_LIST_TYPE === 1 && 
                            getProducts?.content?.map(({CATEGORIES, ID_ITEM, MIDDLE_RATE, REVIEWS_QTY, IMAGE, NAME, PRICE, TOTAL_PRICE, PERCENT_OFF}, k)=>{

                                const price = PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE
                                const priceToken = PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN').PRICE

                                const totalPrice = TOTAL_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE
                                const totalPriceToken = TOTAL_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN').PRICE

                                // const { PRODUCTS } = getCart
                                const product = getCart?.PRODUCTS ? getCart?.PRODUCTS?.filter(({ID_ITEM:getId}) => ID_ITEM=== getId) : []

                                
                               return( <div key={k} className="col-md-4 mb-3 "  >
                                   <div className="px-3 py-3"  style={{border: '1px solid #d2d0d0', height:'100%' }}>
                                <div className="product-list-div text-center" style={{margin: '0 auto', borderRadius:0, width: '100%', height: 220}}>
                                    {/* <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/200`} alt="Image" style={{width: '100%', maxHeight: '100%'}} /> */}
                                    <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/200`} alt="Image" style={{width: '100%',
                                                                                                            height: '100%',
                                                                                                            objectFit: 'cover'}} />
                                 
                                </div>
                                <div className="text-center pt-4">
                                    <div>
                                        <span className="font-weight-bolder">{Base64.decode(NAME)}</span>
                                    </div>
                                    <div style={{minHeight:24}}>
                                        <span>{CATEGORIES.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', ')}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {
                                                Array.from({length:5}).map((i , k)=>{
                                                    return(<i key={k} className={['fa', MIDDLE_RATE >= k+1 ? 'fa-star' : 'fa-star-o'].join(' ')}  ></i>)
                                                })
                                            }
                                        </span>
                                        <span> {REVIEWS_QTY} reviews</span>
                                    </div>
                                    <div>
                                        <div className="d-flex" style={{justifyContent: 'center'}}>
                                            <div>
                                                <div>{rate} {Number(totalPrice.toFixed(2))}</div>
                                                <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(totalPriceToken.toFixed(2))}</div>
                                            </div>
                                            {PERCENT_OFF !== 0 &&
                                                <div className="ml-3">
                                                    <div style={{textDecoration: 'line-through', color:'#4defe7'}}>{rate} {Number(price.toFixed(2))}</div>
                                                    <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(priceToken.toFixed(2))}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="hover-effect-div2" style={{width: '100%', height: '100%', border:`1px solid #915674 `}}>
                                        <div className="text-center" style={{width: '100%', height: '100%'}}>
                                            <div className="hover-main-sctn text-center pt-3 pb-4 px-2" style={{borderRadius:0, width: '100%', height: '100%', opacity: 1, background: 'rgb(255 255 255 / 80%)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                                <div>
                                                    <div>
                                                        <span className="font-weight-bolder">{Base64.decode(NAME)}</span>
                                                    </div>
                                                    <div style={{minHeight:24}}>
                                                        <span>{CATEGORIES.map(({INFO_RECORD})=>(Base64.decode(INFO_RECORD))).join(', ')}</span>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex" style={{justifyContent: 'center'}}>
                                                            <div>
                                                                <div>{rate} {Number(totalPrice.toFixed(2))}</div>
                                                                <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(totalPriceToken.toFixed(2))}</div>
                                                            </div>
                                                            {PERCENT_OFF !== 0 &&
                                                                <div className="ml-3">
                                                                    <div style={{textDecoration: 'line-through', color:'#4defe7'}}>{rate} {Number(price.toFixed(2))}</div>
                                                                    <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(priceToken.toFixed(2))}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                <div>
                                                    <div className="mt-1 mb-2">
                                                        {product.length === 0 &&
                                                        <>
                                                        <Button onClick={()=>addToCart(ID_ITEM)}>ADD TO CART</Button>
                                                        
                                                        {/* <button className="text-center hover-add-cart-btn" onClick={()=>addToCart(ID_ITEM)} >ADD TO CART</button> */}
                                                    </>
                                                        }
                                                        {product.length !== 0 && 
                                                        <Button onClick={()=>cart()}>In Cart</Button>
                                                        // <button className="text-center hover-add-cart-btn" onClick={()=>cart()}>In Cart</button>
                                                        }
                                                    </div>
                                                    <div className="mt-1 mb-2">
                                                        <Button line onClick={()=>goPage(ID_ITEM)}>VIEW ITEM</Button>
                                                        {/* <button className="text-center hover-view-item-btn" onClick={()=>goPage(ID_ITEM)}>VIEW ITEM</button> */}
                                                    </div>
                                                </div>
     
                                            </div>
                                        </div>
                                    </div>
                                    </div>
    
                            </div>)
                            })
                        }
              
                    </div>
                    {/* <div className="row pb-4 small-center">
                        <div className="col-md-6 d-flex">
                            <span>Items 1-6 of 135</span>
                            <div className=" dropdown border ml-2 pl-2 pr-2 rounded">
                                <a className=" dropdown-toggle text-dark" href="#" id="hevingss" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Show 6 items
                                </a>
                                <div className="dropdown-menu" aria-labelledby="hevingss">
                                    <a className="dropdown-item" href="#">Action</a>
                                    <a className="dropdown-item" href="#">Another action</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex forecly-pdng">

                        </div>

                    </div> */}



                </div>

            </div>

        </div>
    </div>
    {show && <PopupLoginRegister closePopup={state=>closeAuth(state)} /> }
    </>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    getProducts:state.user.getProducts,
    getCatalogsList:state.user.getCatalogsList,
    rate:state.auth.rate,
    orderId:state.userPrivate.orderId,
    getCart:state.userPrivate.getCart,
    getIsAuthenticated:authSelectors.getIsAuthenticated(state),
    getAddProductToCart:state.userPrivate.getAddProductToCart,
    initWebSait:state.user.initWebSait,
})

const mapDispatchToProps = {
    onGetProducts:userOperations.getProducts,
    onGetCatalogsList:userOperations.getCatalogsList,
    onGetAddProductToCart:userPrivateOperations.getAddProductToCart,
    onGetCart:userPrivateOperations.getCart,
    // onGetProducts:userPrivateOperations.getProducts,
}
export default connect(mapStateToProps, mapDispatchToProps)(Products);
// export default Products