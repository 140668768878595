import Popup from "../../components/Popup/Popup"
import bar_code from '../../assets/images/bar-code.png';
import { useEffect, useRef, useState } from "react";
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'
import QRCode from "qrcode.react";

import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";
import { userPrivateOperations } from "../../redux/userPrivate";
import { useHistory } from "react-router";
import Button from "../../components/Button/Button";

const PopupCrypto = ({
    lang,
    closePopup,
    payment,
    onCheckoutCryptoOrder,
    checkoutCryptoOrder,
    orderId,
    errCode,
    currency,
    total
}) => {
    const history = useHistory();
    const [value, setValue] = useState('')
    const [text, setText] = useState('')

    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator({
        messages: {
          required: "Please enter your public key"
        }
      }))

    
    useEffect(()=>{
        // onCheckoutCryptoOrder({
        //     DOMAIN,
		//     DEFAULT_COLUMN:lang,
		//     ID_ORDER:orderId
        // })
    }, [])

    useEffect(()=>{
        setValue(payment.ADDRESS)
    }, [payment])

    useEffect(()=>{
        if(checkoutCryptoOrder === 1){
            // history.push('/thanks')
            history.push({
                pathname: '/thanks',
                search: `?record=${payment?.ID_RECORD}`,
            });
        }
    }, [checkoutCryptoOrder])

    const submit = () => {
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)

            return
        }


        onCheckoutCryptoOrder({
            DOMAIN,
		    DEFAULT_COLUMN:lang,
		    ID_ORDER:orderId,
            PUBLIC_ADDRESS_FROM:text
        })
    }

    return (
        <Popup closePopup={closePopup} width="665px">
            <div style={{position: "absolute", top: 15, left: 21}}>
               {payment?.ICON_NAME !== '' && <img  src={`${WEB_PICTURES_FILE_RESIZER}${payment?.ICON_NAME}/50`} />}
               <span className="ml-2">{payment?.NAME}</span>
            </div>
            <div className="modal-body">
                <div className="d-flex" style={{justifyContent:'space-between'}}>
                    <div className="">
                        <QRCode value={value} size={180} id="qr-gen" level={"H"} includeMargin={true}/>
                        <div  style={{textAlign:'center'}} >
                        Scan to get address 
                        </div>
                    </div>
                    <div className="px-2 text-center ">
                        <div className="py-2">To Pay, send exactly this  amount</div>
                        <div className="py-2 rounded-modal-div">
                            <b>{currency} {total} </b>
                        </div>
                        <div className="py-2">
                            To this address
                        </div>
                        <div className="py-2 rounded-modal-div px-4">{value}</div>
                        <div className="py-2">
                            Enter your public address so we can track your payment
                        </div>
                        <div className="my-2">
                            <input
                                    type="text"
                                    onChange={({target:{value}})=>setText(value)}
                                    className="form-control checkout-input-large "
                                    value={text} 
                                    onBlur={()=>simpleValidator.current.showMessageFor('text')} />

                            {/* <div className="text-danger">
                                Please enter your public key
                            </div> */}

                            
                            <div className="text-danger">
                                {simpleValidator.current.message('text', text, 'required|alpha_num')}
                            </div>

                            {/* {simpleValidator.current.fieldValid('text') && 
                                <div className="text-danger">
                                    Please enter your public key
                                </div>} */}
                        </div>

                        <div className="d-flex my-4">
                            <Button line style={{marginRight:12}} onClick={()=>closePopup()}>CANCEL</Button>
                            <Button onClick={()=>submit()}>SUBMIT</Button>
                            
                        </div>

                    </div>
                </div>
                
            </div>
        </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    orderId:state.userPrivate.orderId,
    checkoutCryptoOrder:state.userPrivate.checkoutCryptoOrder,
})

const mapDispatchToProps = {
    onCheckoutCryptoOrder:userPrivateOperations.checkoutCryptoOrder,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupCrypto);