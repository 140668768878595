import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import authActions from './auth-actions';

const rate = createReducer('', {
    [authActions.rate]:(_, {payload}) => payload,
})

const groupName = createReducer(1, {
    [authActions.groupName]:(_, {payload}) => payload,
})

const token = createReducer(null, {
    [authActions.registerSuccess]:(_, {payload}) => payload.token?.access_token,
    [authActions.loginSuccess]:(_, {payload}) => payload.token?.access_token,
    [authActions.logoutSuccess]:() => null,
    [authActions.setToken]:(_, {payload}) => payload,
});

const fullAdminName = createReducer(null, {
    [authActions.registerSuccess]:(_, {payload}) => payload.result?.FULL_NAME,
    [authActions.loginSuccess]:(_, {payload}) => payload.result?.FULL_NAME,
    [authActions.logoutSuccess]:() => null
});

// const project_id = createReducer(0, {
//     [authActions.registerSuccess]:(_, {payload}) => payload.result?.project_id,
//     [authActions.loginSuccess]:(_, {payload}) => payload.result?.project_id,
//     [authActions.logoutSuccess]:() => 0,

// });

const yearsAge18 = createReducer(-1, {
    [authActions.yearsAge18]:(_, {payload}) => payload,
});


const errCode = createReducer(0, {
    [authActions.setErrCode]:(_, {payload}) => payload,
    [authActions.registerSuccess]:(_, {payload}) => payload.err_code,
    [authActions.loginSuccess]:(_, {payload}) => payload.err_code,
    [authActions.logoutSuccess]:() => 0,
    [authActions.forgotPasswordEmailRequest]:() => -1,
    [authActions.forgotPasswordEmailSuccess]:(_, {payload}) => payload.err_code,

})

const errMessage = createReducer('', {
    // [authActions.registerSuccess]:(_, {payload}) => payload.err_t,
    [authActions.loginSuccess]:(_, {payload}) => payload.err_t,
    [authActions.logoutSuccess]:() => '',
})

const setError = (_, {payload}) => payload;

const error = createReducer(null, {
    [authActions.registerError]: setError,
    [authActions.loginError]: setError,
});

const lodaing = createReducer(false, {
    // [authActions.registerRequest]:()=> true,
    [authActions.loginRequest]:()=> true,


    // [authActions.registerSuccess]:()=> false,
    [authActions.loginSuccess]:()=> false,

    // [authActions.registerError]:()=> false,
    [authActions.loginError]:()=> false,
})



export default combineReducers({
    token,
    error,
    errCode,
    errMessage,
    lodaing,
    fullAdminName,
    rate,
    yearsAge18,
    groupName
    // project_id
})