import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import modelActions from './model-actions';
import { modelOperations } from '.';
import { userActions } from '../user';

// const result = createReducer({}, {
//     // [modelActions.getItemsListSuccess]:(_, {payload}) => payload.result,
//     // [modelActions.getItemsByIdSuccess]:(_, {payload}) => payload.result
// });

// const pageListResult = createReducer([], {
//     [modelActions.getAppPagesListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
//     // [modelActions.getSaitListSuccess]:() => [],
//     // [modelActions.getTranslatorSuccess]:() => [],
// });



const getProductFromLucene = createReducer({}, {
    [modelActions.getProductFromLuceneSuccess]:(_, {payload}) => payload.result,
    [userActions.getProductsSuccess]:()=>{}
});


const err_code = createReducer(0, {
    [modelActions.removeManualMeasureRequest]:() => -1,

    [modelActions.removeManualMeasureSuccess]:(_, {payload}) => payload.err_code,

})
const err_t = createReducer('', {
    // [modelActions.updatePageSuccess]:(_, {payload}) => payload.err_t,
    // [modelActions.getItemsListSuccess]:(_, {payload}) => payload.err_t,
    // [modelActions.getItemsByIdSuccess]:(_, {payload}) => payload.err_t
})

const loading = createReducer(false, {
    // [modelActions.getAppPagesListRequest]:() => true,
    // [modelActions.getAppPagesListSuccess]:() => false,
    // [modelActions.getAppPagesListError]:() => false,

})

const loader = createReducer(false, {
    [modelActions.loader]:(_, {payload}) => payload
})

export default combineReducers({
    err_code,
    err_t,
    loading,
    getProductFromLucene,
    loader,
})