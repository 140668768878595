// import { combineReducers, createStore } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import phonebookReducer from './../redux/phonebook/phonebook-reducer'
import { authReducers } from './../redux/auth';
import { modelReducers } from './../redux/model';
import { jsonReducers } from './../redux/json';
import { langReducers } from './../redux/lang';
import { userReducers } from './../redux/user';
import { configReducers } from './../redux/config';
import { userPrivateReducers } from './../redux/userPrivate';
import logger from 'redux-logger'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// import {Map} from 'immutable';
// import {
//   Models as PlannerModels,
//   reducer as PlannerReducer,
//   ReactPlanner,
//   Plugins as PlannerPlugins,
// } from 'react-planner';

// import {Catalog} from 'react-planner';

// import {BoxGeometry, MeshBasicMaterial, Mesh, BoxHelper} from 'three';

// const rootPhonebookReducer = combineReducers({
//     phonebook:phonebookReducer
// })
// const store = createStore(rootPhonebookReducer, composeWithDevTools());

const middleware = [...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }), logger]

const persistConfig = {
    key: 'phonebook',
    storage,
    blacklist:['filter']
  }


const authPersistConfig = {
  key:'auth',
  storage,
  whitelist:['token', 'fullAdminName', 'rate', 'yearsAge18']
}

const jsonPersistConfig = {
  key:'json',
  storage,
  whitelist:[]
}

const langPersistConfig = {
  key:'lang',
  storage,
  whitelist:['LANG']
}

const modelPersistConfig = {
  key:'model',
  storage,
  whitelist:['getWidgetConvertationList', 'getItemGroupTypes', 'getWidgetTypes'],
  blacklist:['convertPrice']
}


const userPersistConfig = {
  key:'user',
  storage,
  whitelist:[]
}

const configPersistConfig = {
  key:'config',
  storage,
  whitelist:[]
}

const userPrivatePersistConfig = {
  key:'userPrivate',
  storage,
  whitelist:['orderId', 'getCart']
}

   

const store = configureStore({
    // reducer:{
    //     phonebook:phonebookReducer
    // },
    reducer:{
      // model:modelReducers,
      model:persistReducer(modelPersistConfig, modelReducers),
      auth:persistReducer(authPersistConfig, authReducers),
      json:persistReducer(jsonPersistConfig, jsonReducers),
      lang:persistReducer(langPersistConfig, langReducers),
      user:persistReducer(userPersistConfig, userReducers),
      config:persistReducer(configPersistConfig, configReducers),
      userPrivate:persistReducer(userPrivatePersistConfig, userPrivateReducers)
    },
    middleware,
    devTools:process.env.NODE_ENV === 'development'
})

const persistor = persistStore(store)

export default {store, persistor};
