import Sidebar from "../Sidebar";
import {Link} from "react-router-dom";
const Dashboard = () => {
    return (
        <div className="container">
            <div className="text-center py-5">
                <h1>My Account</h1>
            </div>
            <div className="row mobile-view">
                <Sidebar/>
                <div className="py-3 px-5 col-md-9">
                    <p>
                        Hello <strong>Mike</strong> (not <strong>Mike</strong>?
                       <Link to="/logout" className="color-from-theme"> Log out</Link>
                    </p>
                    <p>
                        From your account dashboard you can view your recent orders, manage your shipping and billing
                        addresses, and edit your password and account details.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Dashboard