import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements} from "@stripe/react-stripe-js";
import CheckoutForm from '../Checkout/CheckoutForm'

const promise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
// const promise = loadStripe("pk_test_51JDp99IGDOo9yI9XWjbZvyYtmDbfqzdKiQStFXyABy1TyZXVzvxVq04rAYlwRoUa6D6WQAh7ixl10rRzdZztwXld00AEG37RjJ");

const PaymentTest = () => {

    return (
        <>
            <Elements stripe={promise}>
                <CheckoutForm />
            </Elements>
        </>
     
    ) 
}

export default PaymentTest