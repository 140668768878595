import {createAction} from '@reduxjs/toolkit';



const getCatalogsListMenuRequest = createAction('model/getCatalogsListMenuRequest')
const getCatalogsListMenuSuccess = createAction('model/getCatalogsListMenuSuccess')
const getCatalogsListMenuError = createAction('model/getCatalogsListMenuError')

const getCatalogsListRequest = createAction('model/getCatalogsListRequest')
const getCatalogsListSuccess = createAction('model/getCatalogsListSuccess')
const getCatalogsListError = createAction('model/getCatalogsListError')

const currencyListRequest = createAction('model/currencyListRequest')
const currencyListSuccess = createAction('model/currencyListSuccess')
const currencyListError = createAction('model/currencyListError')

const getProductsByGroupRequest = createAction('model/getProductsByGroupRequest')
const getProductsByGroupSuccess = createAction('model/getProductsByGroupSuccess')
const getProductsByGroupError = createAction('model/getProductsByGroupError')

const getProductsRequest = createAction('model/getProductsRequest')
const getProductsSuccess = createAction('model/getProductsSuccess')
const getProductsError = createAction('model/getProductsError')

const getProductByIdRequest = createAction('model/getProductByIdRequest')
const getProductByIdSuccess = createAction('model/getProductByIdSuccess')
const getProductByIdError = createAction('model/getProductByIdError')

const getWidgetRecordsRequest = createAction('model/getWidgetRecordsRequest')
const getWidgetRecordsSuccess = createAction('model/getWidgetRecordsSuccess')
const getWidgetRecordsError = createAction('model/getWidgetRecordsError')

const getProductByPricingParametersRequest = createAction('model/getProductByPricingParametersRequest')
const getProductByPricingParametersSuccess = createAction('model/getProductByPricingParametersSuccess')
const getProductByPricingParametersError = createAction('model/getProductByPricingParametersError')

const getFeedbackListRequest = createAction('model/getFeedbackListRequest')
const getFeedbackListSuccess = createAction('model/getFeedbackListSuccess')
const getFeedbackListError = createAction('model/getFeedbackListError')

export default{

    legalInfoByIdRequest:createAction('model/legalInfoByIdRequest'),
    legalInfoByIdSuccess:createAction('model/legalInfoByIdSuccess'),
    legalInfoByIdError:createAction('model/legalInfoByIdError'),

    initWebSaitRequest:createAction('model/initWebSaitRequest'),
    initWebSaitSuccess:createAction('model/initWebSaitSuccess'),
    initWebSaitError:createAction('model/initWebSaitError'),

    getFeedbackListRequest,
    getFeedbackListSuccess,
    getFeedbackListError,

    getProductByPricingParametersRequest,
    getProductByPricingParametersSuccess,
    getProductByPricingParametersError,
    
    getWidgetRecordsRequest,
    getWidgetRecordsSuccess,
    getWidgetRecordsError,

    getProductByIdRequest,
    getProductByIdSuccess,
    getProductByIdError,

    getProductsRequest,
    getProductsSuccess,
    getProductsError,

    getProductsByGroupRequest,
    getProductsByGroupSuccess,
    getProductsByGroupError,

    currencyListRequest,
    currencyListSuccess,
    currencyListError,

    getCatalogsListRequest,
    getCatalogsListSuccess,
    getCatalogsListError,

    getCatalogsListMenuRequest,
    getCatalogsListMenuSuccess,
    getCatalogsListMenuError,
    
}