import Sidebar from "../Sidebar";
import {useEffect, useRef} from "react";
import {useState} from "react";
import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
const intial_address = {
    Firstname: "",
    Lastname:"",
    Country:"",
    Email:"",
    City:"",
    State:"",
    Address:"",
    ZipCode:"",
    Phone:""
}
const BillAddress = () => {
    const [, forceUpdate] = useState()
    const saveAddress = () => {
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }
    }
    const [address, saveaddress] = useState(intial_address)
    const handleAddress = ({target: {name, value}}) => {

        saveaddress(state => ({...state, [name]: value}))
    }
    const simpleValidator = useRef(new SimpleReactValidator())
    return (
        <div className="container-fluid">

            <div className="container">
                <div className="text-center py-5">
                    <h1>Addresses</h1>
                </div>
                <div className="row">
                    <Sidebar/>
                    <div className="px-5 col-md-9">
                        <div>
                            <div className="">
                                <div className="border-theme  pb-2 mb-4">
                                    <h4>Billling address</h4>
                                </div>
                                <div className="d-flex display-block">
                                    <div className="pr-3 w-50">
                                        <span>First name *</span><br></br>
                                        <input
                                            type="text"
                                            onChange={handleAddress}
                                            className="w-100 form-control px-4 checkout-input-small"
                                            name="Firstname"
                                            placeholder="admin"
                                            value={address.Firstname}
                                            onClick={()=>simpleValidator.current.showMessageFor('Firstname')} />
                                        <div className="text-danger">
                                            {simpleValidator.current.message('Firstname', address.Firstname, 'required|alpha')}
                                        </div>
                                    </div>
                                    <div className=" w-50">
                                        <span>Last name *</span><br></br>
                                        <input
                                            type="text"
                                            onChange={handleAddress}
                                            className="w-100 form-control px-4 checkout-input-small"
                                            name="Lastname"
                                            placeholder="admin"
                                            value={address.Lastname}
                                            onClick={()=>simpleValidator.current.showMessageFor('Lastname')} />
                                        <div className="text-danger">
                                            {simpleValidator.current.message('Lastname', address.Lastname, 'required|alpha')}
                                        </div>
                                    </div>
                                </div>
                                <div className="py-2">
                                    <span>Country / Region *</span><br></br>
                                    <select className="mdb-select md-form w-100 px-4 select-custom-styling"
                                            searchable="Search here..">
                                        <option value="" className="text-success" disabled selected>United Statas (US)
                                        </option>
                                        <option value="1">USA</option>
                                        <option value="2">Germany</option>
                                        <option value="3">France</option>
                                        <option value="3">Poland</option>
                                        <option value="3">Japan</option>
                                    </select>
                                </div>
                                <div className="py-2">
                                    <span>Street address *</span><br></br>
                                    <input
                                        type="text"
                                        onChange={handleAddress}
                                        className="form-control px-4 checkout-input-large mb-3 px-4"
                                        name="Address"
                                        value={address.Address}
                                        placeholder="Street"
                                        onClick={()=>simpleValidator.current.showMessageFor('Address')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('Address', address.Address, 'required|alpha_num_dash_space')}
                                    </div>
                                        <input type="text"
                                               className="placeholder-color-theme form-control px-4 checkout-input-large px-4"
                                               placeholder="Apartment, suite, unit,etc. (optional)"/>
                                </div>
                                <div className="py-2">
                                    <span>Town / City *</span><br></br>
                                    <input
                                        type="text"
                                        onChange={handleAddress}
                                        placeholder="City"
                                        className="form-control px-4 checkout-input-large"
                                        name="City"
                                        value={address.City}
                                        onClick={()=>simpleValidator.current.showMessageFor('City')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('City', address.City, 'required|alpha_num_dash_space')}
                                    </div>
                                </div>
                                <div className="py-2">
                                    <span>State *</span><br></br>

                                    <select className="mdb-select md-form w-100 px-4 select-custom-styling"
                                            searchable="Search here..">
                                        <option value="" disabled selected>California</option>
                                        <option value="1">USA</option>
                                        <option value="2">Germany</option>
                                        <option value="3">France</option>
                                        <option value="3">Poland</option>
                                        <option value="3">Japan</option>
                                    </select>
                                </div>
                                <div className="py-2">
                                    <span>ZIP *</span><br></br>
                                    <input
                                        type="number"
                                        onChange={handleAddress}
                                        className="form-control px-4 checkout-input-large"
                                        name="ZipCode"
                                        placeholder="11111"
                                        value={address.ZipCode}
                                        onClick={()=>simpleValidator.current.showMessageFor('ZipCode')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('ZipCode', address.ZipCode, 'required|numeric|min:0|max:05')}
                                    </div>
                                </div>
                                <div className="py-2">
                                    <span>Phone *</span><br></br>
                                    <input
                                        type="number"
                                        onChange={handleAddress}
                                        className="form-control px-4 checkout-input-large"
                                        name="Phone"
                                        placeholder="Phonenumber"
                                        value={address.Phone}
                                        onClick={()=>simpleValidator.current.showMessageFor('phonenumber')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('phonenumber', address.Phone, 'required|phone')}
                                    </div>
                                </div>
                                <div className="py-2">
                                    <span>Email address *</span><br></br>
                                    <input
                                        type="email"
                                        className="form-control px-4 checkout-input-large"
                                        name="Email"
                                        onChange={handleAddress}
                                        placeholder="Enter Email Address"
                                        value={address.Email}
                                        onBlur={() => simpleValidator.current.showMessageFor('email')}/>
                                    <div className="text-danger">
                                        {simpleValidator.current.message('email', address.Email, 'required|email')}
                                    </div>
                                </div>
                                <div className="py-2">
                                    <input type="submit" value="SAVE ADDRESS"
                                           className="login-buttons font-weight-bold" onClick={() => saveAddress()}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default BillAddress