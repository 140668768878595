
import {Route, Switch } from 'react-router-dom';

import store from './redux/store';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import './assets/css/bootstrap.min.css'
import  './assets/css/stylesheet.css'

import Layouts from './Layouts';
import NotFound from './views/NotFound/NotFound';

import Page from './views/Page'
import Home from './views/Home';
import Login from './views/Login';
import Forgot from './views/Forgot';
import Account from './views/Account';
import Address from './views/Address';
import Dashboard from './views/Dashboard';
import Contact from './views/Contact';
import Cart from './views/Cart';
import Checkout from './views/Checkout';
import Logout from './views/Logout';
import BillAddress from './views/BillAddress';
import Order from './views/Order';
import Products from './views/Products';
import WritePreview from './views/WritePreview';
import ProtectedRoute from './views/Protected';
import ProductView from './views/ProductView/ProductView';
import Thanks from './views/Thanks';
import PaymentTest from './views/PaymentTest/PaymentTest';


function App() {



  return(
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <Switch>


          {/* <ProtectedRoute exact path='/' render={props => (<Layouts><Home {...props} /></Layouts>)} /> */}
          <Route exact path='/' render={props => (<Layouts><Home {...props} /></Layouts>)} />

          <Route exact path='/page/:id'>
            <Layouts><Page /></Layouts>
          </Route>
          <Route exact path='/page/:id/:token'>
            <Layouts><Page /></Layouts>
          </Route>
          Routes With Out Protection
          <Route  path='/login' render={props => (<Layouts><Login {...props} /></Layouts>)} />
          <Route  path='/forgot' render={props => (<Layouts><Forgot {...props} /></Layouts>)} />


          <Route  path='/write-preview' render={props => (<Layouts redirectAuth={true}><WritePreview {...props} /></Layouts>)} />
          <Route  path='/account' render={props => (<Layouts redirectAuth={true}><Account {...props} /></Layouts>)} />

          {/* <ProtectedRoute exact path="/account" render={props => (<Layouts><Account {...props} /></Layouts>)}  /> */}
          <Route  path='/logout' render={props => (<Layouts><Logout {...props} /></Layouts>)} />
          <Route  path='/billing' render={props => (<Layouts><BillAddress {...props} /></Layouts>)} />
          <Route  path='/order' render={props => (<Layouts><Order {...props} /></Layouts>)} />

          {/* <ProtectedRoute exact path="/cart" render={props => (<Layouts><Cart {...props} /></Layouts>)}  /> */}
          <Route  path='/cart' render={props => (<Layouts redirectAuth={true}><Cart {...props} /></Layouts>)} />

          <Route  path='/contact' render={props => (<Layouts><Contact {...props} /></Layouts>)} />
          <Route  path='/checkout' render={props => (<Layouts><Checkout {...props} /></Layouts>)} />
          <Route  path='/dashboard' render={props => (<Layouts><Dashboard {...props} /></Layouts>)} />
          <Route  path='/address' render={props => (<Layouts><Address {...props} /></Layouts>)} />
          {/* <Route  path='/products/' render={props => (<Layouts><Products {...props} /></Layouts>)} /> */}
          <Route  path='/products' render={props => (<Layouts><Products {...props} /></Layouts>)} />
          <Route  path='/products/:recordId' render={props => (<Layouts><Products {...props} /></Layouts>)} />
          <Route  path='/product/:id' render={props => (<Layouts><ProductView {...props} /></Layouts>)}/>
          <Route  path='/thanks' render={props => (<Layouts><Thanks {...props} /></Layouts>)}/>
          <Route  path='/payment-test' render={props => (<Layouts><PaymentTest {...props} /></Layouts>)}/>

          <Route path="*"><NotFound/></Route>

        </Switch>
      </PersistGate>
    </Provider>
  )
}

export default App;
