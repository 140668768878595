import logo from '../../assets/images/cart-image-1.png';
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import userPrivateOperations from '../../redux/userPrivate/user-private-operations';
import CompontenCart from './CompontenCart';
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'

import Slider from "react-slick";
import { Base64 } from 'js-base64';
import Button from '../../components/Button/Button';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
};

const Cart = ({
    rate,
    errCode,
    onGetCart,
    getCart,
}) => {

    const history = useHistory()

    const [total, setTotal] = useState(0)
    const [totalTokens, setTotalTokens] = useState(0)

    const [discount , setDiscount ] = useState(0)

    const [totalToPay, setTotalToPay] = useState(0)
    const [loylatyBalance, setLoylatyBalance] = useState(0)
    const [loyaltyDiscount, setLoyaltyDiscount] = useState({
        value:0,
        point:0
    })
    const [pointsCashback, setPointsCashback] = useState({
        value:0,
        point:0
    })
    const [pointsCashbackPrecent, setPointsCashbackPrecent] = useState(0)

    useEffect(()=>{
        if(errCode === 1){
            onGetCart({DOMAIN})
        }
    }, [errCode])

    useEffect(()=>{
        console.log({getCart});
        if(Object.keys(getCart).length !== 0){
            const { AMOUNT, TOTAL_TO_PAY, DISCOUNT, USER_LOYALTY_DISCOUNT:{BALANCE}, LOYALTY_DISCOUNT, LOYALTY_CASHBACK, LOYALTY_CASHBACK_PERCENT } = getCart

            setTotal(AMOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate)?.PRICE)
            setTotalTokens(AMOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN')?.PRICE)

            setDiscount(DISCOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate)?.PRICE)
            setTotalToPay(TOTAL_TO_PAY?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate)?.PRICE)

            setLoylatyBalance(BALANCE)
            setLoyaltyDiscount({
                value:LOYALTY_DISCOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate)?.VALUE,
                point:LOYALTY_DISCOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN')?.VALUE
            })

            setPointsCashback({
                value:LOYALTY_CASHBACK?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate)?.VALUE,
                point:LOYALTY_CASHBACK?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN')?.VALUE
            })

            setPointsCashbackPrecent(LOYALTY_CASHBACK_PERCENT)
        }
    }, [getCart])

    const goCheckout = () => {
        history.push('/checkout')
    }

    return (
        <div className="container">
            <div className="text-center pt-5 d-flex" style={{justifyContent: 'space-between'}}>
                <h1>Cart</h1>
                <h5>Loyalty points balance: {loylatyBalance}</h5>
            </div>
            <div className="tablee">
                <table className="table border-bottom table-cart" style={{paddingBottom: 0}}>
                    <thead className="tbl-head-border">
                    <tr>
                        <th width="12%"></th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>Quantity</th>
                        <th width="220px">Loyalty</th>
                        <th className="text-right">Subtotal</th>
                        
                    </tr>
                    </thead>
                    <tbody>
                        {getCart?.PRODUCTS?.length !== 0 &&
                            getCart?.PRODUCTS?.map((product, k)=>(<CompontenCart key={k} {...product} loylatyBalance={loylatyBalance} loyaltyDiscount={loyaltyDiscount}  />))
                        }

                        {getCart?.PRODUCTS?.length === 0 && <tr><td colSpan="6" style={{textAlign: 'center', fontSize: 36}}> Cart is empty </td></tr>}
   
                    </tbody>
                </table>
            </div>

            <div className="row">
                <div className="col-md-7">
  
                </div>
                <div className="col-md-5" >
                        <div className="d-flex " style={{justifyContent: 'flex-end'}}>
                            {/* <p className=" font-weight-bolder pt-2">Total</p> */}
                            <div style={{ width: '100%'}}>
                                <div className="d-flex pb-2" style={{justifyContent:'space-between'}}>
                                    <div className=" font-weight-bolder" >Total</div>
                                    <div className="ml-2">{rate} {total === null ? 0 : Number(total.toFixed(2))}</div>
                                </div>
                                <div className="d-flex pb-2" style={{justifyContent:'space-between'}}>
                                    <div className=" font-weight-bolder" >Discount</div>
                                    <div className="ml-2">{rate} {discount === null ? 0 : Number(discount.toFixed(2))}</div>
                                </div>
                                <div className="d-flex pb-2" style={{justifyContent:'space-between'}}>
                                    <div className=" font-weight-bolder" >Loyalty discount</div>
                                    <div className="ml-2">
                                        {`(Points ${loyaltyDiscount.point === null ? 0 : Number(loyaltyDiscount.point.toFixed(2))})`} {' '}
                                        {rate} {loyaltyDiscount.value === null ? 0 : Number(loyaltyDiscount.value.toFixed(3))}</div>
                                </div>
                                <div className="d-flex pb-2" style={{justifyContent:'space-between'}}>
                                    <div className=" font-weight-bolder" >Total to pay </div>
                                    <div className="ml-2">{rate} {totalToPay === null ? 0 : Number(totalToPay.toFixed(2))}</div>
                                </div>
                                {/* <div>{rate} {total === null ? 0 : Number(total.toFixed(2))}</div> */}
                                <div className="text-right pb-2" style={{color: 'rgb(145, 86, 116)'}}>TOKENS {totalTokens === null ?  0 : Number(totalTokens.toFixed(2))}</div>
                                <div className="d-flex pb-2" style={{justifyContent:'space-between'}}>
                                    <div className=" font-weight-bolder" >Points cashback ({pointsCashbackPrecent}%)</div>
                                    <div className="ml-2">
                                    {`(Points ${pointsCashback.point === null ? 0 : Number(pointsCashback.point.toFixed(2))})`} {' '}
                                        {rate} {pointsCashback.value === null ? 0 : Number(pointsCashback.value.toFixed(2))}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="col-md-1"></div>
            </div>

            <div className="row btn-sctns">
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
                    <div className="d-flex  w-100 " style={{justifyContent: 'flex-end'}}>
                    <Button onClick={()=>goCheckout()} disabled={getCart?.PRODUCTS?.length === 0 ? true : false}>Proceed to checkout</Button>
                        
                        {/* <div className=" text-center ml-2 text-white font-weight-bolder d-flex" style={{justifyContent: 'center', alignItems: 'center'}}>
                            <Link to="/checkout" className={[getCart?.PRODUCTS?.length === 0 ? 'noactive' : ''].join(' ')}><span className="btn text-white">Proceed to checkout</span>
                            </Link>

                            <Button onClick={()=>goCheckout()} disabled={getCart?.PRODUCTS?.length === 0 ? true : false}>Proceed to checkout</Button>

                        </div> */}
                    </div>
 
                </div>
            </div>
            <div className="pt-5 pb-5">
                <div className="row pt-5 pb-5">

                    <Slider {...settings} style={{width:'100%', marginTop: 24}}>

                        {getCart?.PRODUCT_GROUPS?.map(({NAME, PRODUCTS}, k)=>{
                            return(<div key={k} className="px-2">
                                    <h2 className="cart-last-sctn-hdng-brdr pb-3">{Base64.decode(NAME)}</h2>
                                    {
                                        PRODUCTS.map(({ID_ITEM, IMAGE, NAME, PRICE, TOTAL_PRICE, PERCENT_OFF}, k)=>{
                                            const price = PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE
                                            const priceToken = PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN').PRICE

                                            const totalPrice = TOTAL_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === rate).PRICE
                                            const totalPriceToken = TOTAL_PRICE.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN').PRICE

                                            return(<div key={k} className="d-flex py-3">
                                                <div className="px-3">
                                                    <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/100`} width="80" height="80" style={{borderRadius:'100%'}} />
        
                                                </div>
                                                <div className="px-3 pt-2">
                                                    <div>
                                                        <Link to={`product/${ID_ITEM}`} className="text-success" >{Base64.decode(NAME)}</Link>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div>
                                                            <div>{rate} {Number(totalPrice.toFixed(2))}</div>
                                                            <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(totalPriceToken.toFixed(2))}</div>
                                                        </div>
                                                        {PERCENT_OFF !== 0 &&
                                                            <div className="ml-3">
                                                                <div style={{textDecoration: 'line-through', color:'#4defe7'}}>{rate} {Number(price.toFixed(2))}</div>
                                                                <div style={{color: 'rgb(145, 86, 116)'}}>TOKENS {Number(priceToken.toFixed(2))}</div>
                                                            </div>
                                                        }
                                                    </div>
        
                                                </div>
        
                                            </div>);
                                        })
                                    }
                                    
                                </div>)
                        })}

                        

                    </Slider>

                   
                </div>
            </div>
        </div>
    ) 
}



const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    orderId:state.userPrivate.orderId,
    getCart:state.userPrivate.getCart,
    // getAddProductToCart:state.userPrivate.getAddProductToCart,
})

const mapDispatchToProps = {
    onGetCart:userPrivateOperations.getCart,
}
export default connect(mapStateToProps, mapDispatchToProps)(Cart);