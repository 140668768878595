
import { Base64, toBase64 } from 'js-base64';
import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Base64Decode} from './../../modules/helper'
import { userOperations } from '../../redux/user';
import Popup from '../Popup/Popup';
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from './../../config/Url'


const infoContacts = ['FACEBOOK', 'INSTAGRAM', 'TIKTOK', 'TWITTER']
const pay = ["ETHEREUM", "BITCOIN", "TOKEN", "PAYPAL"]


const Footer = ({
    lang,
    onInitWebSait,
    initWebSait,
    onLegalInfoById,
    legalInfoById
}) => {

    const [show, setShow] = useState(false);
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [legal, setLegal] = useState([])
    const [partners, setPartner] = useState([])
    const [payment, setPayment] = useState([])

    
    const [mainInfo, setMainInfo] = useState({
        COLOR_FOOTER: "",
        COLOR_HEADER: "",
        COPYRIGHT_FOOTER: "",
        ICON_TITLE_WEB_PAGE: "",
        ID_WEB_SITE: 0,
        LOGO_HEADER: "",
        TITLE_WEB_PAGE: "",
        URL_WEB_PAGE: "",
    })

    const [contacts, setContacts] = useState({
        EMAIL_SALES: "",
        EMAIL_SUPPORT: "",
        ID_WEB_SITE: 0,
        LOGO_FACEBOOK: "",
        LOGO_INSTAGRAM: "",
        LOGO_TIKTOK: "",
        LOGO_TWITTER: "",
        PHONE_SALES: "",
        PHONE_SUPPORT: "",
        STATUS_FACEBOOK: 0,
        STATUS_INSTAGRAM: 0,
        STATUS_TIKTOK: 0,
        STATUS_TWITTER: 0,
        URL_FACEBOOK: "",
        URL_INSTAGRAM: "",
        URL_TIKTOK: "",
        URL_TWITTER: "",
        FOLLOW_US_STATUS: 0,
        FOLLOW_US_TEXT: "",
        FOLLOW_US_TEXT_CODE: 0,
        SALES_CONTACTS_STATUS: 0,
        SALES_CONTACTS_TEXT: "",
        SALES_CONTACTS_TEXT_CODE: 0,
    })

    useEffect(()=>{
        // onInitWebSait({DOMAIN})
    }, [])

    useEffect(()=>{
        if(Object.keys(initWebSait).length !== 0){
            const { MAIN_INFO, CONTACTS_INFO, LEGAL_INFO, PARTNERS_INFO, PAYMENT_INFO } = initWebSait

            setMainInfo(MAIN_INFO)
            setContacts(CONTACTS_INFO)
            setLegal(LEGAL_INFO['#result-set-1'])
            setPartner(PARTNERS_INFO['#result-set-1'])
            setPayment(PAYMENT_INFO['#result-set-1'])
        }
    }, [initWebSait])

    useEffect(()=>{
        if(Object.keys(legalInfoById).length !== 0){
            const {TITLE_ITEM, DESCRIPTION_ITEM} = legalInfoById;
            setTitle(Base64.decode(TITLE_ITEM))
            setDesc(Base64.decode(DESCRIPTION_ITEM).replace(/\r?\n/g,'<br/>'))
            setShow(true)
        }
    }, [legalInfoById])

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    return (
        <>
        <footer className="mt-5">
        <div style={{background:mainInfo.COLOR_FOOTER}}>
            <div className="container">
                <div className="row border-bottom">

                    <div className="col-md-9">

                        <div className="row">

                            {initWebSait?.LEGAL_INFO?.LEGAL_DOCUMENT_STATUS === 1 && 
                            <div className="col-md-4 footer-row">
                                <h4 className="text-white">{Base64Decode(initWebSait?.LEGAL_INFO?.LEGAL_DOCUMENT_TEXT)}</h4>
                                <ul className="list-unstyled">
                                    {
                                        legal?.map(({ID_ITEM, TITLE_ITEM}, k)=>{
                                            return(<li key={k}><a className="pointer text-white text-decoration-none" onClick={()=>{onLegalInfoById({DOMAIN, DEFAULT_COLUMN:lang, ID_ITEM});}}>{Base64Decode(TITLE_ITEM)}</a></li>)
                                        })
                                    }
                                </ul>
                            </div>
                            }
                            
                            {(contacts.FOLLOW_US_STATUS === 1 || initWebSait?.PARTNERS_INFO?.PARTNERS_MEMEBERSHIP_STATUS === 1) &&
                            <div className="col-md-3 footer-row">
                                {contacts.FOLLOW_US_STATUS === 1 && <>
                                    <h4 className="text-white pb-2">{Base64Decode(contacts.FOLLOW_US_TEXT)}</h4>
                                    <ul className="list-unstyled">
                                        <li >
                                            {
                                                infoContacts.map((v, k)=>{
                                                    if(contacts[`STATUS_${v}`] === 1 && contacts[`LOGO_${v}`] !== '')
                                                        return(<a key={k} href={`${contacts[`URL_${v}`]}`} target='_blank' className="px-2 py-2">
                                                                    <img src={`${WEB_PICTURES_FILE_RESIZER}${contacts[`LOGO_${v}`]}/20`} width="20px" height="20px" />
                                                                </a>)
                                                })
                                            }
                            
                                        </li>
                                    </ul>
                                </>}

                                
                                
                                {initWebSait?.PARTNERS_INFO?.PARTNERS_MEMEBERSHIP_STATUS === 1 && <>
                                    <h5 className="text-white pb-2">{Base64Decode(initWebSait?.PARTNERS_INFO?.PARTNERS_MEMEBERSHIP_TEXT)}</h5>
                                    <ul className="list-unstyled">
                                    

                                        {
                                            partners?.map(({ICON_PARTNER, URL_PARTNER}, k)=>{
                                                if(ICON_PARTNER !== '')
                                                return(<li key={k}>
                                                    <p className="text-white text-decoration-none">
                                                        <a href={`${URL_PARTNER}`} target='_blank' >
                                                            <img  src={`${WEB_PICTURES_FILE_RESIZER}${ICON_PARTNER}/200`} height="35" alt={'noImg'} />
                                                        </a>
                                                    </p>
                                                </li>)
                                            })
                                        }
                                    </ul>
                                </>}

                            </div>

                            }

                            
                            {contacts.SALES_CONTACTS_STATUS === 1 &&
                            <div className="col-md-5 footer-row">
                                
                                    <div >
                                        <h4 className="text-white ">{Base64Decode(contacts.SALES_CONTACTS_TEXT)}</h4>
                                        <ul className="list-unstyled">
                                        {/* SALES_CONTACTS_STATUS
                                        SALES_CONTACTS_TEXT
                                        SALES_EMAIL_STATUS
                                        SALES_EMAIL_TEXT
                                        SALES_PHONE_STATUS
                                        SALES_PHONE_TEXT
                                        SUPPORT_EMAIL_STATUS
                                        SUPPORT_EMAIL_TEXT
                                        SUPPORT_PHONE_STATUS
                                        SUPPORT_PHONE_TEXT */}
                                            {contacts.SALES_EMAIL_STATUS === 1 && <div className="d-flex"><span className="text-white mr-2">{Base64Decode(contacts.SALES_EMAIL_TEXT)}</span> <a className="text-white" href={`mailto:${contacts.EMAIL_SALES}`}>{contacts.EMAIL_SALES}</a></div>}
                                            {contacts.SALES_PHONE_STATUS === 1 && <div className="d-flex"><span className="text-white mr-2">{Base64Decode(contacts.SALES_PHONE_TEXT)}</span> <a className="text-white" href={`tel:${contacts.PHONE_SALES}`} >{contacts.PHONE_SALES}</a></div>}
                                            {contacts.SUPPORT_EMAIL_STATUS === 1 && <div className="d-flex"><span className="text-white mr-2">{Base64Decode(contacts.SUPPORT_EMAIL_TEXT)}</span> <a className="text-white" href={`mailto:${contacts.EMAIL_SUPPORT}`}>{contacts.EMAIL_SUPPORT}</a></div>}
                                            {contacts.SUPPORT_PHONE_STATUS === 1 && <div className="d-flex"> <span className="text-white mr-2">{Base64Decode(contacts.SUPPORT_PHONE_TEXT)}</span> <a className="text-white" href={`tel:${contacts.PHONE_SUPPORT}`} >{contacts.PHONE_SUPPORT}</a></div>}
                                        </ul>
                                    </div>
                                
                            </div>}
                    </div>
                    </div>

                    <div className="col-md-3 text-white text-center">

                        {initWebSait?.MAIN_INFO?.SUBSCRIBE_TITLE_STATUS === 1 && initWebSait?.SUBSCRIPTION_DISPLAY_TYPE === 0 && <>
                        <div className="footer-circles " style={{marginTop: -95}}>
                            <svg width="474" height="466" viewBox="0 0 474 466" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M368.994 410.565C369.295 425.926 357.64 437.88 342.201 437.999C327.903 438.119 315.948 426.882 315.047 412.537C314.145 398.073 326.461 384.744 341.3 384.027C356.619 383.369 368.694 394.905 368.994 410.565Z" fill="#5782A2"/>
                                <path d="M83.7036 130.648C83.2717 145.609 70.0077 157.577 54.646 156.978C39.2227 156.32 27.069 143.275 28.0561 128.374C28.9815 114.132 41.3819 102.881 56.0033 103.001C71.4267 103.121 84.1354 115.747 83.7036 130.648Z" fill="#915674"/>
                                <path d="M83.7141 336.028C83.6524 351.215 70.9465 363.294 55.2803 362.995C39.4289 362.696 27.4634 350.378 28.0186 334.952C28.5737 320.363 40.2926 309.241 55.4037 309.002C71.1934 308.823 83.7757 320.841 83.7141 336.028Z" fill="#CA9E71"/>
                                <path d="M160.855 411.124C160.611 426.795 148.346 438.412 132.36 437.989C117.227 437.565 105.939 426.069 106 411.185C106.061 395.876 119.058 383.473 134.435 384.017C149.079 384.501 161.038 396.845 160.855 411.124Z" fill="#8DA56C"/>
                                <path d="M369.857 54.7341C369.918 70.3814 357.735 82.2371 341.817 81.9964C327.491 81.7557 315.186 69.3583 315.002 55.1554C314.818 39.9896 327.859 27.532 343.348 28.0135C358.347 28.495 369.796 40.0498 369.857 54.7341Z" fill="#8DA56C"/>
                                <path d="M27.5441 206C42.9512 206.06 55.0828 218.298 54.8402 233.416C54.5975 248.414 41.8593 260.352 26.5128 259.992C12.5008 259.692 0.308548 247.454 0.00525817 233.416C-0.298032 218.778 12.5615 206 27.5441 206Z" fill="#B96B83"/>
                                <path d="M473.835 233.498C473.409 249.014 461.055 260.577 445.475 259.978C431.477 259.498 419.123 247.038 419.001 233.259C418.879 218.042 431.173 205.94 446.692 206C462.455 206.12 474.201 218.042 473.835 233.498Z" fill="#BA6C84"/>
                                <path d="M418.52 362.995C402.907 362.696 390.577 350.192 391.011 335.175C391.445 320.099 404.518 308.313 420.069 309.031C434.691 309.689 446.525 321.595 446.711 335.774C446.959 350.491 433.7 363.294 418.52 362.995Z" fill="#915674"/>
                                <path d="M132.516 81.998C118.231 81.8179 106.246 69.7472 106.003 55.3345C105.761 40.021 118.352 27.59 133.726 28.0104C148.677 28.4307 159.815 39.6606 159.996 54.5538C160.239 70.0475 148.132 82.1782 132.516 81.998Z" fill="#5481A1"/>
                                <path d="M418.463 156.99C402.618 156.51 390.581 144.216 391.011 129.163C391.502 114.109 404.399 102.535 420.121 103.014C434.124 103.434 446.713 116.089 446.713 129.882C446.836 144.396 433.264 157.41 418.463 156.99Z" fill="#C99D70"/>
                                <path d="M263.857 26.9672C263.857 43.1415 253.038 53.332 236.506 53.9837C221.311 54.5761 208.244 38.935 209.034 26.1377C209.885 12.2148 223.256 -0.404782 236.385 0.00994464C252.856 0.543165 263.857 10.8521 263.857 26.9672Z" fill="#76ABC4"/>
                                <path d="M237.875 412.092C253.792 412.211 264.465 422.386 264.712 437.677C264.897 454.039 253.854 465.82 238.06 465.998C223.13 466.177 208.817 452.908 209.002 438.926C209.125 427.443 220.909 410.605 237.875 412.092Z" fill="#76ABC4"/>
                            </svg>
                        </div>
                        <div className="footer-input-sctn">
                            <div>
                                <h3>{ Base64Decode(initWebSait?.MAIN_INFO?.SUBSCRIBE_TITLE_TEXT)}</h3>
                            </div>
                            <div>
                                <p>{Base64Decode(initWebSait?.MAIN_INFO?.SUBSCRIBE_SUBTITLE_TEXT)} </p>
                            </div>
                            <div className="pb-2">
                                <input className="footer-input text-white bg-transparent" type="email"
                                       placeholder="enter your email" autoComplete="off" />
                            </div>
                            <div>
                                <input className="footer-btn text-dark" type="submit" value="SUBSCRIBE" />
                            </div>
                        </div>
                        </>}

                        {initWebSait?.MAIN_INFO?.SUBSCRIBE_TITLE_STATUS === 1 && initWebSait?.SUBSCRIPTION_DISPLAY_TYPE === 1 && 
                            <div className="footer-subscript px-4 py-4 mt-4" style={{backgroundColor:initWebSait?.SUBSCRIPTION_DISPLAY_TYPE_BG_COLOR}}>
                              
                                    <div>
                                        <h3>{ Base64Decode(initWebSait?.MAIN_INFO?.SUBSCRIBE_TITLE_TEXT)}</h3>
                                    </div>
                                    <div>
                                        <p>{Base64Decode(initWebSait?.MAIN_INFO?.SUBSCRIBE_SUBTITLE_TEXT)} </p>
                                    </div>
                                    <div className="pb-2">
                                        <input className="footer-input text-white bg-transparent" type="email" placeholder="ENTER YOUR EMAIL" autoComplete="off" />
                                    </div>
                                    <div>
                                        <input className="footer-btn text-dark" type="submit" value="SUBSCRIBE" />
                                    </div>
                               
                            </div>
                        }


                    </div>
                </div>
                <div >
                    <div className="row pt-2">
                        <div className="col-md-3 text-white">
                            {initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_STATUS === 1 && <h4>{Base64Decode(initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_TEXT)}</h4>}
                        </div>
                        <div className="col-md-6">
                            {initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_STATUS === 1 && payment?.map(({STATUS, TYPE, ICON_NAME, ICON_1, ICON_2}, k)=>{
                               
                                if(STATUS === 1 && TYPE !== 1)
                                    return(<img key={k} src={`${WEB_PICTURES_FILE_RESIZER}${ICON_NAME}/150`} height='20' style={{marginRight:12}} />)
                                
                                if(STATUS === 1 && TYPE === 1)
                                    return(
                                    <span key={k} >
                                        <img  src={`${WEB_PICTURES_FILE_RESIZER}${ICON_1}/150`} height='20' style={{marginRight:12}} />
                                        <img  src={`${WEB_PICTURES_FILE_RESIZER}${ICON_2}/150`} height='20' style={{marginRight:12}} />
                                    </span>)
                           
                            })}
                  
                        </div>
                        <div className="col-md-3">
                            <div className="text-center text-white">
                               {mainInfo.COPYRIGHT_FOOTER}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </footer>

    {show && <Popup title={title} width={'1000px'} closePopup={()=>setShow(false)}>
            <p style={{margin: '8px 20px'}}>{renderHTML(desc)}</p>
        </Popup>}
    </>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    initWebSait:state.user.initWebSait,
    legalInfoById:state.user.legalInfoById,
})

const mapDispatchToProps = {
    onInitWebSait:userOperations.initWebSait,
    onLegalInfoById:userOperations.legalInfoById,
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
