import {useEffect, useRef} from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {authActions, authSelectors} from "../../redux/auth";
import {userOperations} from "../../redux/user";
import {Link} from "react-router-dom";
import {useDispatch} from 'react-redux'
import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
const INITIAL_LOGIN = {
    Login_Email: "",
    Login_Password: ""
}

const INITIAL_REGISTER = {
    FIRST_NAME: "",
    LAST_NAME: "",
    NICK_NAME: "",
    GENDER: 0,
    BIRTHDAY: "",
    EMAIL: '',
    PHONE: "",
    PHONE_COUNTRY_CODE: "",
    COUNTRY_CODE: "",
    PASSWD: '111111',
}

const Login = ({
                   getIsAuthenticated,
                   onUserRegistration,
                   onUserAutorization,
                   history,
                   errCode,
                   resetLoginErr
               }) => {
    const dispatch = useDispatch()
    const [login, setLogin] = useState(INITIAL_LOGIN)
    const [register, setRegister] = useState(INITIAL_REGISTER)

    const [rel, setRel] = useState(-1)

    useEffect(() => {
        console.log({getIsAuthenticated});
        if (getIsAuthenticated) {
            setLogin(INITIAL_LOGIN)
            setRegister(INITIAL_REGISTER)
            setRel(-1)
            // history.goBack()
            // history.push(`/`)
        }
    }, [getIsAuthenticated])

    const handleLogin = ({target: {name, value}}) => {
        resetLoginErr()
        setLogin(state => ({...state, [name]: value}))
    }

    const handleRegister = ({target: {name, value}}) => {
        resetLoginErr()
        setRegister(state => ({...state, [name]: value}))


    }
    const [, forceUpdate] = useState()
    const sendLogin = async () => {
        setRel(1)
        resetLoginErr()
        onUserAutorization(login)
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }
        const headers = {
            'Authorization': 'Bearer ',
            'content-type': 'application/json'
        };
        // axios({
        //     method: 'POST',
        //     url: 'https://whellli.com:8444/api/public/user/wpa',
        //     data: {
        //         "name": "USER_AUTORIZATION_BY_EMAIL",
        //         "parameters": {"EMAIL": login.Login_Email, "PASSWORD": login.Login_Password}
        //     },
        //
        // })
        //     .then(res => {
        //         console.log(res);
        //         console.log(res.config.data);
        //         let access_token = res.data.token.access_token;
        //         dispatch(authActions.setToken(access_token))
        //         alertify.success('Logged In Successfully');
        //         history.push(`/`)
        //
        //     }).catch((err) => {
        //     alertify.error('Logged in Failed');
        //     console.log(err)
        // })

    }


    const sendRegister = () => {
        setRel(2)
        resetLoginErr()
        onUserRegistration(register);
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
            // history.push('/login')
        }
        // axios({
        //     method: 'POST',
        //     url: 'https://whellli.com:8444/api/public/user/wpa',
        //     data: {
        //         "name": "USER_REGISTRATION_ACCOUNT",
        //         "parameters": {
        //             "FIRST_NAME": "User",
        //             "LAST_NAME": "User",
        //             "NICK_NAME": "User",
        //             "GENDER": 0,
        //             "BIRTHDAY": "1980.01.01",
        //             "EMAIL": "test_user@test.com",
        //             "PHONE": "00000000",
        //             "PHONE_COUNTRY_CODE": "1",
        //             "COUNTRY_CODE": "CA",
        //             "PASSWD": "111111"
        //         }
        //     },
        //
        // }).then(res => {
        //     console.log(res);
        // }).catch((err) => {
        //     console.log(err)
        // })


    }
    const simpleValidator = useRef(new SimpleReactValidator())

    return (

        <div className="container">

            <div className="d-flex display-block">
                <div className="w-50 login-sctns full-with">
                    <div className="pb-3">
                        <h2 className="text-btm-border">Login</h2>
                    </div>
                    <div className="pb-5">
                        <p>Welcome back! Sign in to your account.</p>
                    </div>
                    <div>
                        <label>Username or email address*</label>
                    </div>
                    <div className="pb-3">
                        {/*<input type="email" className="input-rounded-fields form-control" name="EMAIL" value={login.EMAIL} onChange={handleLogin}/>*/}
                        <input
                            type="email"
                            onChange={handleLogin}
                            className="input-rounded-fields form-control"
                            name="Login_Email"
                            value={login.Login_Email}
                            onBlur={() => simpleValidator.current.showMessageFor('Email')}/>
                        <div className="text-danger">
                            {simpleValidator.current.message('Email', login.Login_Email, 'required|email')}
                        </div>
                    </div>
                    <div>
                        <label>Password*</label>
                    </div>
                    <div className="pb-3">
                        {/*<input type="password" className="input-rounded-fields form-control" name="PASSWORD" value={login.PASSWORD} onChange={handleLogin}/>*/}
                        <input
                            type="password"
                            onChange={handleLogin}
                            className="input-rounded-fields form-control"
                            name="Login_Password"
                            value={login.Login_Password}
                            onBlur={() => simpleValidator.current.showMessageFor('password')}/>
                        <div className="text-danger">
                            {simpleValidator.current.message('password', login.Login_Password, 'required|alpha_num')}
                        </div>
                    </div>
                    <div className="pb-4">
                        <input type="checkbox" className="px-2"/>
                        <span className="pl-2">Remember me</span>
                    </div>
                    {errCode > 1 && rel === 1 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>Error code: {errCode}</div>
                        <br/></>}
                    <div className="pb-3">
                        <input className="login-buttons" type="submit" value="LOG IN" onClick={() => sendLogin()}/>
                    </div>
                    <div>
                        <Link to="/forgot"> Lost your passsword?</Link>
                    </div>
                </div>
                <div className="w-50 login-sctns full-with">
                    <div className="pb-3">
                        <h2 className="text-btm-border">Register</h2>
                    </div>
                    <div className="pb-3">
                        <p>Create new account today to reap the benefits of a personalized shopping experience.</p>
                    </div>
                    <div>
                        <label>Email address*</label>
                    </div>

                    <div className="pb-3">
                        {/*<Input type="email" className="input-rounded-fields form-control" name="EMAIL" validations={[required, email]} value={register.EMAIL} onChange={handleRegister} />*/}
                        <input
                            type="email"
                            onChange={handleRegister}
                            className="input-rounded-fields form-control"
                            name="EMAIL"
                            value={register.EMAIL}
                            onFocus={() => simpleValidator.current.showMessageFor('email')}/>
                        <div className="text-danger">
                            {simpleValidator.current.message('email', register.EMAIL, 'required|email')}
                        </div>
                    </div>
                    <div className="pb-2">
                        <p>A password will be sent to your email address.</p>
                    </div>
                    {errCode !== 10 && rel === 2 && <>
                        <div id="app" className="control">
                            <div className="control has-icons-left">

                            </div>
                            <div className="control has-icons-left">
                                <label>Firstname</label>
                                <input
                                    type="text"
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="FIRST_NAME"
                                    value={register.FIRST_NAME}
                                    onClick={() => simpleValidator.current.showMessageFor('firstname')}/>
                                <div className="text-danger">
                                    {simpleValidator.current.message('firstname', register.FIRST_NAME, 'required|alpha')}
                                </div>
                            </div>
                            <div className="control has-icons-left">
                                <label>Lastname</label>
                                <input
                                    type="text"
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="LAST_NAME"
                                    value={register.LAST_NAME}
                                    onClick={() => simpleValidator.current.showMessageFor('lastname')}/>
                                <div className="text-danger">
                                    {simpleValidator.current.message('lastname', register.LAST_NAME, 'required|alpha')}
                                </div>
                            </div>
                            {/*<div className="control has-icons-left">*/}
                            {/*    <label>Nickname</label>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        onChange={handleRegister}*/}
                            {/*        className="input-rounded-fields form-control"*/}
                            {/*        name="FIRST_NAME" q*/}
                            {/*        value={register.NICK_NAME}*/}
                            {/*        onClick={() => simpleValidator.current.showMessageFor('nickname')}/>*/}
                            {/*    <div className="text-danger">*/}
                            {/*        {simpleValidator.current.message('nickname', register.NICK_NAME, 'required|alpha')}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="control has-icons-left">*/}
                            {/*    <label>Birthday</label>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        onChange={handleRegister}*/}
                            {/*        className="input-rounded-fields form-control"*/}
                            {/*        name="BIRTHDAY"*/}
                            {/*        value={register.BIRTHDAY}*/}
                            {/*        onClick={() => simpleValidator.current.showMessageFor('birthdday')}/>*/}
                            {/*    <div className="text-danger">*/}
                            {/*        {simpleValidator.current.message('birthdday', register.BIRTHDAY, 'required|date')}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="control has-icons-left">
                                <label>Phonenumber</label><br/>
                                <div className="d-flex">
                                    <select className=" md-form pl-2  selecting-custom-styling"
                                            searchable="Search here..">
                                        <option value="" className="text-success" selected>+91
                                        </option>
                                        <option value="1">+92</option>
                                        <option value="2">123</option>
                                        <option value="3">2323</option>
                                        <option value="3">3456</option>
                                        <option value="3">111</option>
                                    </select>
                                    <div>
                                        <input
                                            type="text"
                                            onChange={handleRegister}
                                            className="adjust-width input-rounded-fields form-control mx-2 float-right"
                                            name="PHONE"
                                            value={register.PHONE}
                                            onClick={() => simpleValidator.current.showMessageFor('phonenumber')}/>
                                    </div>
                                </div>
                                <div className="text-danger">
                                    {simpleValidator.current.message('phonenumber', register.PHONE, 'required|phone')}
                                </div>
                            </div>

                            {/*<div className="control has-icons-left">*/}
                            {/*    <label>Countrycode</label>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        onChange={handleRegister}*/}
                            {/*        className="input-rounded-fields form-control"*/}
                            {/*        name="COUNTRY_CODE"*/}
                            {/*        value={register.COUNTRY_CODE}*/}
                            {/*        onClick={() => simpleValidator.current.showMessageFor('countrycode')}/>*/}
                            {/*    <div className="text-danger">*/}
                            {/*        {simpleValidator.current.message('countrycode', register.COUNTRY_CODE, 'required|numeric')}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="control has-icons-left">
                                <label>Password</label>
                                <input
                                    type="password"
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="PASSWORD"
                                    value={register.PASSWORD}
                                    onClick={() => simpleValidator.current.showMessageFor('password')}/>
                                <div className="text-danger">
                                    {simpleValidator.current.message('password', register.PASSWORD, 'required|alpha_num')}
                                </div>
                            </div>
                            <div className="control has-icons-left">
                                <label>Confirm Password</label>
                                <input
                                    type="password"
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="PASSWORD"
                                    value={register.PASSWORD}
                                    onClick={() => simpleValidator.current.showMessageFor('password')}/>
                                <div className="text-danger">
                                    {simpleValidator.current.message('password', register.PASSWORD, 'required|alpha_num')}
                                </div>
                            </div>
                            <div className="pt-5">
                                <p>Your personal data will be used to support your experience thoughtout this website,
                                    to
                                    manage accesss
                                    to
                                    your account, and for other purposes described in our <a href="#">privacy policy</a>.
                                </p>
                            </div>
                        </div>
                    </>
                    }

                    {errCode > 1 && rel === 2 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>Error code: {errCode}</div>
                        <br/></>}
                    <div>
                        <input type="button" value="REGISTER" className="login-buttons" onClick={() => sendRegister()}/>
                    </div>
                </div>
            </div>
        </div>

    )
}
const mapStateToProps = (state) => ({
    lang: state.lang.LANG,
    errCode: state.auth.errCode,
    getIsAuthenticated: authSelectors.getIsAuthenticated(state),
})

const mapDispatchToProps = {
    onUserRegistration: userOperations.userRegistration,
    onUserAutorization: userOperations.userAutorization,
    resetLoginErr: authActions.logoutSuccess,
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
