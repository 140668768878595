import { useCallback, useEffect, useRef, useState } from "react"
import Popup from "../../components/Popup/Popup"
import {DOMAIN} from './../../config/Url'
import { connect } from "react-redux";
import { userPrivateActions, userPrivateOperations } from "../../redux/userPrivate";
import Button from "../../components/Button/Button";
import { useHistory } from "react-router-dom";

// const appId = 'sandbox-sq0idb-HRwWbCKd-mwf96l0Ckwcgw';
// const locationId = 'LNVM5KZ7Q062E'; 

const PopupSquareUp = ({
    closePopup,
    onGetUserSquareupSettings,
    getUserSquareupSettings,
    onUserTransactionSquareup,
    userTransactionSquareup,
    orderId,
    setErrorCode,
    payment
}) => {

    // const [appId, setAppId] = useState('')
    // const [locationId, setLocationId] = useState('')

    const [card, setCard] = useState();
    const [loaded, setLoaded] = useState(false);
    const [flag, setFlag] = useState(0)

    const [error, setError] = useState(false);
    const history = useHistory();

    useEffect(()=>{

        onGetUserSquareupSettings({DOMAIN})
        setError(false)

        // setLoaded(true)
        // run();
    }, [])

    useEffect(()=>{
        if(Object.keys(getUserSquareupSettings).length !== 0 && flag === 0){
            const {ID_APPLICATION, ID_LOCATION} = getUserSquareupSettings;
            run(ID_APPLICATION, ID_LOCATION);
            setFlag(1)
        }
    }, [getUserSquareupSettings])

    useEffect(()=>{
        if(userTransactionSquareup === 1){
            history.push({
                pathname: '/thanks',
                search: `?record=${payment?.ID_RECORD}`,
            });
        }
        if(userTransactionSquareup === -1) setError(true)
    }, [userTransactionSquareup])


    const run = async (appId, locationId) => {

        if(appId === '' && locationId === '') return

        const payments = window.Square.payments(appId, locationId);
        setCard(await initializeCard(payments));
    }

    const initializeCard = async (payments) => {

        console.log('initializeCard');
        const card = await payments.card();
        await card.attach('#card-container'); 
        setLoaded(true)
        return card; 
    }

    const pay = async (event) => {
          console.log(card);

        event.preventDefault();



        try {
          const result = await card.tokenize();
  
          if (result.status === 'OK') {
            
            console.log(`Payment token is ${result.token}`)

            onUserTransactionSquareup({
                DOMAIN,
                ID_ORDER:orderId,
                TOKEN:result.token
            })
          }
        } catch (e) {
          console.error(e);
        }
  
    }

    return (
        <Popup closePopup={()=>{closePopup(); setErrorCode(0)}} >

            <div className="my-4 mx-4">
                {error && <h4 className="text-center" style={{
                        display:'flex',
                        position: 'absolute',
                        zIndex: 1000,
                        left: 0,
                        right: 0,
                        top: '24%',
                        bottom: 0,
                        textAlign:'center',
                        color:'red',
                        alignItems:'center',
                        backgroundColor:'#fff'
                        }} >Something went wrong. We`ll take you back to checkout so you can try again.</h4>}
                    <h2 className="text-center" style={{
                        display:loaded ? 'none' : 'block',
                        position: 'absolute',
                        zIndex: 1,
                        left: 0,
                        right: 0,
                        top: '50%',
                        bottom: 0
                        }} >Loading...</h2>
                    <form id="payment-form" style={{opacity:loaded ? 1 : 0 }}>
                        <div id="card-container"></div>

                        <div className="text-center"><Button id="card-button" type="button" onClick={e=>pay(e)}>Pay</Button></div>
                    </form>
                    <div id="payment-status-container"></div>
            </div>
    </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    rate:state.auth.rate,
    errCode:state.userPrivate.err_code,
    orderId:state.userPrivate.orderId,
    getUserSquareupSettings:state.userPrivate.getUserSquareupSettings,
    userTransactionSquareup:state.userPrivate.userTransactionSquareup,
})

const mapDispatchToProps = {
    onGetUserSquareupSettings:userPrivateOperations.getUserSquareupSettings,
    onUserTransactionSquareup:userPrivateOperations.userTransactionSquareup,
    setErrorCode:userPrivateActions.setErrorCode,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupSquareUp);