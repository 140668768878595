import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { authActions, authSelectors } from "../../redux/auth";
import { modelOperations } from "../../redux/model";
import { userOperations } from "../../redux/user";
import Logout from "../../views/Logout";

import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from './../../config/Url'
import Favicon from 'react-favicon'
import { userPrivateOperations } from "../../redux/userPrivate";
import Popup from "../Popup/Popup";

const infoContacts = ['FACEBOOK', 'INSTAGRAM', 'TIKTOK', 'TWITTER']

const Header = ({
    lang,
    rate,
    setRate,
    setGroupName,
    getIsAuthenticated,
    groupName,

    onInitWebSait,
    initWebSait,

    onGetCart,

    onGetProducts,
    getProducts,
    errСode,
    setErrCode
}) => {

    const [show, setShow] = useState(false)
    const location = useLocation()
    const [search, setSearch] = useState('')

    const match = useRouteMatch()
    const history = useHistory();

    console.log(location);

    const [mainInfo, setMainInfo] = useState({
        COLOR_FOOTER: "",
        COLOR_HEADER: "",
        COPYRIGHT_FOOTER: "",
        ICON_TITLE_WEB_PAGE: "",
        ID_WEB_SITE: 0,
        LOGO_HEADER: "",
        TITLE_WEB_PAGE: "",
        URL_WEB_PAGE: "",
        HEADER_STYLE:0
    })

    const [contacts, setContacts] = useState({
        EMAIL_SALES: "",
        EMAIL_SUPPORT: "",
        ID_WEB_SITE: 0,
        LOGO_FACEBOOK: "",
        LOGO_INSTAGRAM: "",
        LOGO_TIKTOK: "",
        LOGO_TWITTER: "",
        PHONE_SALES: "",
        PHONE_SUPPORT: "",
        STATUS_FACEBOOK: 0,
        STATUS_INSTAGRAM: 0,
        STATUS_TIKTOK: 0,
        STATUS_TWITTER: 0,
        URL_FACEBOOK: "",
        URL_INSTAGRAM: "",
        URL_TIKTOK: "",
        URL_TWITTER: ""
    })

    useEffect(()=>{
        setErrCode(-1)
        onInitWebSait({DOMAIN})

        // onCurrencyList({DOMAIN, DEFAULT_COLUMN:lang})
        // onGetCatalogsList({DOMAIN, TYPE:0, DEFAULT_COLUMN:lang})
    }, [])

    useEffect(()=>{
        console.log({errСode});
    }, [errСode])

    useEffect(()=>{
        if(getIsAuthenticated){
            onGetCart({DOMAIN, DEFAULT_COLUMN:lang})
        }
    }, [getIsAuthenticated])

    useEffect(()=>{
        document.addEventListener('click', handle)
        return () => {
            document.removeEventListener('click', handle)
        }
    }, [])

    useEffect(()=>{
        if(Object.keys(initWebSait).length !== 0){
            const { MAIN_INFO, CONTACTS_INFO, PRODUCT_GROUPS } = initWebSait

            const {TITLE_WEB_PAGE, CURRENCY_LIST_STATUS, DEFAULT_CURRENCY_SHORT_NAME} = MAIN_INFO

            document.title = TITLE_WEB_PAGE

            setMainInfo(MAIN_INFO)
            setContacts(CONTACTS_INFO)

            setGroupName(PRODUCT_GROUPS.length === 0 ? 0 : PRODUCT_GROUPS[0]?.ID_GROUP)

            if(CURRENCY_LIST_STATUS === 0) 
                setRate(DEFAULT_CURRENCY_SHORT_NAME)
            else if(CURRENCY_LIST_STATUS === 1)
                setRate(rate === '' ? DEFAULT_CURRENCY_SHORT_NAME : rate)

        }
    }, [initWebSait])

    // useEffect(()=>{
    //     if(Object.keys(getProducts).length !== 0){
    //         if(getProducts?.content.length !== 0) history.push('/products')
    //     }
    // }, [getProducts])

    // const ID_RECORD = match?.params?.recordId
    //     if(!ID_RECORD) return;

    //     onGetProducts({
    //         DOMAIN,
    //         DEFAULT_COLUMN:lang,
    //         PAGE:0,
    //         SIZE:20,
    //         FILTER_TEXT:"",		//- optional
    //         SORT_FIELD:"price",//	- optional (price,rate)
    //         SORT_TYPE:"asc",	//	- optional (asc,desc)
    //         PROPERTIES:[{ID_RECORD:Number(ID_RECORD)}]
    //     })

    const handle = evt => {

        const {target} = evt
        if(target.closest('.rate')?.classList.contains("rate")){
            return
        }
        setShow(false)
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            if(search !== '')
                history.push(`/products/${search}`)
            else if(search == '')
                history.push(`/`)
        }
    }


    return (
        <>
        {mainInfo.ICON_TITLE_WEB_PAGE !== '' && <Favicon url={`${WEB_PICTURES_FILE_RESIZER}${mainInfo.ICON_TITLE_WEB_PAGE}/50`} />}
        {mainInfo.HEADER_STYLE === 0 && 
        <header>
        <div className="top-home-bar" style={{background:mainInfo.COLOR_HEADER}}>
            <div className="container" style={{paddingTop:3}}>
                <div className="d-flex" style={{alignItems: 'center'}} >
                    <div className="d-flex w-75 sm-section-header ">
                        <a className="d-flex" href={`mailto:${contacts.EMAIL_SALES}`}>
                            <div className="py-2 px-1">
                                
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.3334 2.00004C17.3334 1.08337 16.5834 0.333374 15.6667 0.333374H2.33335C1.41669 0.333374 0.666687 1.08337 0.666687 2.00004V12C0.666687 12.9167 1.41669 13.6667 2.33335 13.6667H15.6667C16.5834 13.6667 17.3334 12.9167 17.3334 12V2.00004ZM15.6667 2.00004L9.00002 6.16671L2.33335 2.00004H15.6667ZM15.6667 12H2.33335V3.66671L9.00002 7.83337L15.6667 3.66671V12Z" fill="white"/>
                                    </svg>
                                
                            </div>
                            <div className="text-white py-2">{contacts.EMAIL_SALES}</div>
                        </a>
                    </div>
                    <div className="d-flex w-25 sm-section-header text-right four-icons-top" style={{alignItems:'center', justifyContent: "flex-end"}}>
                        {
                            infoContacts.map((v, k)=>{
                                if(contacts[`STATUS_${v}`] === 1 && contacts[`LOGO_${v}`] !== '')
                                return(<a key={k} href={`${contacts[`URL_${v}`]}`} target='_blank' className="px-2 py-2">
                                            <img src={`${WEB_PICTURES_FILE_RESIZER}${contacts[`LOGO_${v}`]}/20`} width="20px" height="20px" />
                                        </a>)
                            })
                        }
                        {initWebSait?.MAIN_INFO?.CURRENCY_LIST_STATUS === 1 &&
                        <a href="#" className="pt-1 rate">
                            <p onClick={()=>setShow(!show)} className="d-flex text-white font-weight-bold padding-remove" style={{ position: 'relative',  top: 7}}>{rate}<i style={{marginTop:3}} className="px-2 fa padding-remove fa-chevron-down fa-xm"></i></p>
                            {show &&
                                <ul>
                                    {
                                        initWebSait?.CURRENCY_LIST?.map(({rate_short_name}, k)=>{
                                            return(<li key={k} onClick={()=>{setShow(false); setRate(rate_short_name)}}>{rate_short_name}</li>)
                                        })
                                    }
                                    {/* <li></li> */}
                                </ul>
                            }
                        </a>}
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <nav className="navbar p-0 navbar-expand-lg navbar-light bg-navbar border-bottom">
                {mainInfo.LOGO_HEADER !== '' &&
                    <Link className="navbar-brand" to="/">
                        <img src={`${WEB_PICTURES_FILE_RESIZER}${mainInfo.LOGO_HEADER}/200`} width="155px" height="57px" style={{objectFit:'contain'}} />
                    </Link>
                }
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse  padding-remove space-between " id="navbarSupportedContent">
                
                    <div style={{display: 'flex', width: '63%',justifyContent: 'center',marginLeft: 15}}>
                    <ul className="navbar-nav ">
                        {
                            initWebSait?.TOP_MENU?.map(({INFO_WIDGET, ID_WIDGET,  RECORDS_WIDGET}, k)=>{
                                if(RECORDS_WIDGET.length === 0)
                                    return(<li key={k} className="nav-item">
                                                <a className="nav-link text-dark" style={{width:'max-content'}} href="#" tabIndex="-1" aria-disabled="true" >{Base64.decode(INFO_WIDGET)}</a>
                                            </li>)
                                else

                                    return(<li key={k} className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle text-dark" href="#" id={`navbarDropdown${ID_WIDGET}`} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {Base64.decode(INFO_WIDGET)}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby={`navbarDropdown${ID_WIDGET}`}>
                                                {
                                                    RECORDS_WIDGET.map(({INFO_RECORD, ID_RECORD}, k)=>(
                                                     <Link key={k} className="dropdown-item" to={{
                                                        pathname:`/products/${ID_RECORD}`,
                                                        state:{from:location}
                                                    }}>{Base64.decode(INFO_RECORD)}</Link>))
                                                }
                                            </div>
                                        </li>)
                            })
                        }


                    </ul>
                    </div>
                    <form className="form-inline padding-remove my-2 my-lg-0">
                        <input className="form-control mr-sm-2 top-search-input" type="search" placeholder="Search..." aria-label="Search" value={search} onChange={({target:{value}})=>setSearch(value)} onKeyPress={handleKeyPress} />
                        <div className="px-3">

                            {/* {!getIsAuthenticated &&
                                {/* <Logout />
                                // <Link to="/account" >
                                //     <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                //         <path d="M10.9999 2.86671C12.5466 2.86671 13.7999 4.12004 13.7999 5.66671C13.7999 7.21337 12.5466 8.46671 10.9999 8.46671C9.45325 8.46671 8.19992 7.21337 8.19992 5.66671C8.19992 4.12004 9.45325 2.86671 10.9999 2.86671ZM10.9999 14.8667C14.9599 14.8667 19.1333 16.8134 19.1333 17.6667V19.1334H2.86659V17.6667C2.86659 16.8134 7.03992 14.8667 10.9999 14.8667ZM10.9999 0.333374C8.05325 0.333374 5.66658 2.72004 5.66658 5.66671C5.66658 8.61337 8.05325 11 10.9999 11C13.9466 11 16.3333 8.61337 16.3333 5.66671C16.3333 2.72004 13.9466 0.333374 10.9999 0.333374ZM10.9999 12.3334C7.43992 12.3334 0.333252 14.12 0.333252 17.6667V21.6667H21.6666V17.6667C21.6666 14.12 14.5599 12.3334 10.9999 12.3334Z" fill="black"/>
                                //     </svg>
                                // </Link> */}
                          
                            {/* {getIsAuthenticated && */}

                                <Link to="/account" >
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.9999 2.86671C12.5466 2.86671 13.7999 4.12004 13.7999 5.66671C13.7999 7.21337 12.5466 8.46671 10.9999 8.46671C9.45325 8.46671 8.19992 7.21337 8.19992 5.66671C8.19992 4.12004 9.45325 2.86671 10.9999 2.86671ZM10.9999 14.8667C14.9599 14.8667 19.1333 16.8134 19.1333 17.6667V19.1334H2.86659V17.6667C2.86659 16.8134 7.03992 14.8667 10.9999 14.8667ZM10.9999 0.333374C8.05325 0.333374 5.66658 2.72004 5.66658 5.66671C5.66658 8.61337 8.05325 11 10.9999 11C13.9466 11 16.3333 8.61337 16.3333 5.66671C16.3333 2.72004 13.9466 0.333374 10.9999 0.333374ZM10.9999 12.3334C7.43992 12.3334 0.333252 14.12 0.333252 17.6667V21.6667H21.6666V17.6667C21.6666 14.12 14.5599 12.3334 10.9999 12.3334Z" fill="black"/>
                                    </svg>
                                </Link>
                            {/* } */}
                           
                        </div>
                        <div className="px-3">
                            <Link to='/cart'>
                                <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.0001 5.00004H14.6667C14.6667 2.42171 12.5784 0.333374 10.0001 0.333374C7.42175 0.333374 5.33341 2.42171 5.33341 5.00004H3.00008C1.71675 5.00004 0.666748 6.05004 0.666748 7.33337V21.3334C0.666748 22.6167 1.71675 23.6667 3.00008 23.6667H17.0001C18.2834 23.6667 19.3334 22.6167 19.3334 21.3334V7.33337C19.3334 6.05004 18.2834 5.00004 17.0001 5.00004ZM10.0001 2.66671C11.2834 2.66671 12.3334 3.71671 12.3334 5.00004H7.66675C7.66675 3.71671 8.71675 2.66671 10.0001 2.66671ZM17.0001 21.3334H3.00008V7.33337H5.33341V9.66671C5.33341 10.3084 5.85841 10.8334 6.50008 10.8334C7.14175 10.8334 7.66675 10.3084 7.66675 9.66671V7.33337H12.3334V9.66671C12.3334 10.3084 12.8584 10.8334 13.5001 10.8334C14.1417 10.8334 14.6667 10.3084 14.6667 9.66671V7.33337H17.0001V21.3334Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>

                    </form>
                </div>
            </nav>
            {location.pathname === '/' &&
                <div className="text-center header-third mr-auto border-bottom py-2" >
                    {
                        initWebSait?.PRODUCT_GROUPS?.map(({ID_GROUP, NAME}, k)=>{
                            return(<a key={k} className={["px-3 text-dark text-decoration-none pointer", groupName===ID_GROUP ? 'active' : ''].join(' ')} onClick={()=>setGroupName(ID_GROUP)}>{Base64.decode(NAME)}</a>)
                        })
                    }
                    {/* <a className={["px-3 text-dark text-decoration-none pointer", groupName==='Featured' ? 'active' : ''].join(' ')} onClick={()=>setGroupName('Featured')}>FEATURED</a>
                    <a className={["px-3 text-dark text-decoration-none pointer", groupName==='On Sale' ? 'active' : ''].join(' ')} onClick={()=>setGroupName('On Sale')}>ON SALES</a>
                    <a className={["px-3 text-dark text-decoration-none pointer", groupName==='Top rating' ? 'active' : ''].join(' ')} onClick={()=>setGroupName('Top rating')}>TOP RATINGS</a> */}
                </div>
            }
        </div>
    </header>}

    {mainInfo.HEADER_STYLE === 1 && 
        <header style={{background:mainInfo.COLOR_HEADER, borderBottom: `1px solid ${mainInfo.COLOR_HEADER}`}}>
        <div className="top-home-bar" >
            <div className="container" style={{paddingTop:3}}>
                <div className="d-flex" style={{alignItems: 'center', justifyContent:'flex-end'}} >
                    <div className="d-flex sm-section-header ">
                        <a className="d-flex" href={`mailto:${contacts.EMAIL_SALES}`}>
                            <div className="py-2 px-1">
                                
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.3334 2.00004C17.3334 1.08337 16.5834 0.333374 15.6667 0.333374H2.33335C1.41669 0.333374 0.666687 1.08337 0.666687 2.00004V12C0.666687 12.9167 1.41669 13.6667 2.33335 13.6667H15.6667C16.5834 13.6667 17.3334 12.9167 17.3334 12V2.00004ZM15.6667 2.00004L9.00002 6.16671L2.33335 2.00004H15.6667ZM15.6667 12H2.33335V3.66671L9.00002 7.83337L15.6667 3.66671V12Z" fill="white"/>
                                    </svg>
                                
                            </div>
                            <div className="text-white py-2">{contacts.EMAIL_SALES}</div>
                        </a>
                    </div>
                    <div className="d-flex sm-section-header text-right ml-3" style={{alignItems:'center', justifyContent: "flex-end"}}>
                        {
                            infoContacts.map((v, k)=>{
                                if(contacts[`STATUS_${v}`] === 1 && contacts[`LOGO_${v}`] !== '')
                                return(<a key={k} href={`${contacts[`URL_${v}`]}`} target='_blank' className="px-2 py-2">
                                            <img src={`${WEB_PICTURES_FILE_RESIZER}${contacts[`LOGO_${v}`]}/20`} width="20px" height="20px" />
                                        </a>)
                            })
                        }
                        {initWebSait?.MAIN_INFO?.CURRENCY_LIST_STATUS === 1 &&
                        <a href="#" className="pt-1 rate">
                            <p onClick={()=>setShow(!show)} className="d-flex text-white font-weight-bold padding-remove" style={{ position: 'relative',  top: 7}}>{rate}<i style={{marginTop:3}} className="px-2 fa padding-remove fa-chevron-down fa-xm"></i></p>
                            {show &&
                                <ul>
                                    {
                                        initWebSait?.CURRENCY_LIST?.map(({rate_short_name}, k)=>{
                                            return(<li key={k} onClick={()=>{setShow(false); setRate(rate_short_name)}}>{rate_short_name}</li>)
                                        })
                                    }
                                    {/* <li></li> */}
                                </ul>
                            }
                        </a>}
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <nav className="navbar p-0 navbar-expand-lg navbar-light ">
                <div style={{position:'relative', width: 160}}>
                {mainInfo.LOGO_HEADER !== '' &&
                    <Link className="navbar-brand" to="/" style={{position: 'absolute', top: -70, bottom: 0}}>
                        <img src={`${WEB_PICTURES_FILE_RESIZER}${mainInfo.LOGO_HEADER}/200`} width="155px" height="120px" style={{objectFit:'contain'}} />
                    </Link>
                }
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse  padding-remove space-between mt-2 " id="navbarSupportedContent">
                
                    <div style={{display: 'flex', width: '63%',justifyContent: 'center',marginLeft: 15}}>
                    <ul className="navbar-nav ">
                        {
                            initWebSait?.TOP_MENU?.map(({INFO_WIDGET, ID_WIDGET,  RECORDS_WIDGET}, k)=>{
                                if(RECORDS_WIDGET.length === 0)
                                    return(<li key={k} className="nav-item">
                                                <a className="nav-link " style={{width:'max-content', color:'#fff'}} href="#" tabIndex="-1" aria-disabled="true" >{Base64.decode(INFO_WIDGET)}</a>
                                            </li>)
                                else

                                    return(<li key={k} className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle " style={{color:'#fff'}} href="#" id={`navbarDropdown${ID_WIDGET}`} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {Base64.decode(INFO_WIDGET)}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby={`navbarDropdown${ID_WIDGET}`}>
                                                {
                                                    RECORDS_WIDGET.map(({INFO_RECORD, ID_RECORD}, k)=>(
                                                     <Link key={k} className="dropdown-item" to={{
                                                        pathname:`/products/${ID_RECORD}`,
                                                        state:{from:location}
                                                    }}>{Base64.decode(INFO_RECORD)}</Link>))
                                                }
                                            </div>
                                        </li>)
                            })
                        }


                    </ul>
                    </div>
                    <form className="form-inline padding-remove my-2 my-lg-0">
                        <input className="form-control mr-sm-2 top-search-input" type="search" placeholder="Search..." aria-label="Search" value={search} onChange={({target:{value}})=>setSearch(value)} onKeyPress={handleKeyPress} style={{borderRadius:3, height: 'auto'}} />
                        <div className="px-2">

                                <Link to="/account" >
                                    <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.9999 2.86671C12.5466 2.86671 13.7999 4.12004 13.7999 5.66671C13.7999 7.21337 12.5466 8.46671 10.9999 8.46671C9.45325 8.46671 8.19992 7.21337 8.19992 5.66671C8.19992 4.12004 9.45325 2.86671 10.9999 2.86671ZM10.9999 14.8667C14.9599 14.8667 19.1333 16.8134 19.1333 17.6667V19.1334H2.86659V17.6667C2.86659 16.8134 7.03992 14.8667 10.9999 14.8667ZM10.9999 0.333374C8.05325 0.333374 5.66658 2.72004 5.66658 5.66671C5.66658 8.61337 8.05325 11 10.9999 11C13.9466 11 16.3333 8.61337 16.3333 5.66671C16.3333 2.72004 13.9466 0.333374 10.9999 0.333374ZM10.9999 12.3334C7.43992 12.3334 0.333252 14.12 0.333252 17.6667V21.6667H21.6666V17.6667C21.6666 14.12 14.5599 12.3334 10.9999 12.3334Z" fill="white"/>
                                    </svg>
                                </Link>
                        </div>
                        <div className="px-2">
                            <Link to='/cart'>
                                <svg width="20" height="20" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.0001 5.00004H14.6667C14.6667 2.42171 12.5784 0.333374 10.0001 0.333374C7.42175 0.333374 5.33341 2.42171 5.33341 5.00004H3.00008C1.71675 5.00004 0.666748 6.05004 0.666748 7.33337V21.3334C0.666748 22.6167 1.71675 23.6667 3.00008 23.6667H17.0001C18.2834 23.6667 19.3334 22.6167 19.3334 21.3334V7.33337C19.3334 6.05004 18.2834 5.00004 17.0001 5.00004ZM10.0001 2.66671C11.2834 2.66671 12.3334 3.71671 12.3334 5.00004H7.66675C7.66675 3.71671 8.71675 2.66671 10.0001 2.66671ZM17.0001 21.3334H3.00008V7.33337H5.33341V9.66671C5.33341 10.3084 5.85841 10.8334 6.50008 10.8334C7.14175 10.8334 7.66675 10.3084 7.66675 9.66671V7.33337H12.3334V9.66671C12.3334 10.3084 12.8584 10.8334 13.5001 10.8334C14.1417 10.8334 14.6667 10.3084 14.6667 9.66671V7.33337H17.0001V21.3334Z" fill="white"/>
                                </svg>
                            </Link>
                        </div>

                    </form>
                </div>
            </nav>
            {location.pathname === '/' ?
                <div className="text-center header-third mr-auto  py-2" style={{minHeight:40}} >
                    {
                        initWebSait?.PRODUCT_GROUPS?.map(({ID_GROUP, NAME}, k)=>{
                            return(<a key={k} className={["px-3 text-decoration-none pointer", groupName===ID_GROUP ? 'active' : ''].join(' ')} onClick={()=>setGroupName(ID_GROUP)} style={{color:'#fff'}}>{Base64.decode(NAME)}</a>)
                        })
                    }
                </div> :
                <div style={{minHeight:40}}></div>
            }
        </div>
    </header>}
            {errСode > 0 && <Popup closePopup={()=>window.location.reload()} > <h1 style={{textAlign: 'center', padding: 30, color: 'red'}}> Something went wrong. Please reload page. </h1> </Popup>}
            {/* {errСode > 0 && <Popup closePopup={()=>window.location.reload()} > <h1 style={{textAlign: 'center', padding: 30, color: 'red'}}> Something went wrong. Please reload page. </h1> </Popup>} */}
    </>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    initWebSait:state.user.initWebSait,
    getCart:state.user.getCart,
    rate:state.auth.rate,
    groupName:state.auth.groupName,
    getIsAuthenticated:authSelectors.getIsAuthenticated(state),
    getProducts:state.user.getProducts,
    errСode:state.auth.errCode,
    // errСode2:state.user.errCode,
})

const mapDispatchToProps = {
    onInitWebSait:userOperations.initWebSait,
    onGetCart:userPrivateOperations.getCart,
    setRate:authActions.rate,
    setErrCode:authActions.setErrCode,
    // onGetProducts:userOperations.getProducts,
    setGroupName:authActions.groupName,
    onGetProducts:userOperations.getProducts,
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
// export default Header