
import styles from './Loading.module.css'

const Loading = () => {
    return (
        // <h1>Loading</h1>
        <div className={styles.Loading}>
            <h1>Loading...</h1>
            
        </div>
    ) 
}

export default Loading